import classNames from 'classnames';
import React from 'react';
import { Component } from 'react';
import { openMiniBins } from '../../../helpers/board';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import styles from './box-layout.style';
import { syncMapping } from '../../Common/mini24_bins_mapping';

let nums = Array.from(Array(3).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ['A', 'B', 'C'];
let deactivatedBin = [];
var selectedBins = [];
let binsBackground = [];

export class AdminM24MainUnitLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps() {
    const { deactivatedBins } = this.props;
    if (deactivatedBins && deactivatedBins.length > 0) {
      deactivatedBins.map((item) => {
        return true;
      });
    } else {
      deactivatedBin.map((item) => {
        return true;
      });
      deactivatedBin = [];
    }
  }

  openbin = (binId) => {
    let eleBin = syncMapping(binId);
    openMiniBins(eleBin);
    selectedBins.push(binId);
    var items = document.getElementById(binId);
    let obj = {
      Id: binId,
      background: items.style.background
    };
    binsBackground.push(obj);
    items.style.background = 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    items.style.color = 'rgb(0, 0, 0)';
    this.props.Selectedbin(binId);
  };

  render() {
    const { classes, prefix } = this.props;
    return (
      <div key={prefix}>
        <div className={classNames(classes.ratingContainer)}>
          {nums1 &&
            nums1.length &&
            nums1.length > 0 &&
            nums1.map((n1) => {
              let isdisabledDoor = false;
              return (
                <Grid
                  key={n1}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.RemoveMargin}
                >
                  {nums &&
                    nums.length &&
                    nums.length > 0 &&
                    nums.map((n) => {
                      if (
                        (elem[n] === 'A' || elem[n] === 'B') &&
                        n1 >= 0 &&
                        n1 <= 5
                      ) {
                        if (elem[n] === 'A') {
                          return (
                            <Grid
                              id={elem[n] + '' + (n1 + 1)}
                              key={n}
                              item
                              xs={3}
                            />
                          );
                        } else {
                          return (
                            <Grid
                              id={elem[n] + '' + (n1 + 1)}
                              key={n}
                              item
                              xs={3}
                            />
                          );
                        }
                      } else {
                        if (
                          elem[n] === 'A' ||
                          elem[n] === 'B' ||
                          elem[n] === 'C'
                        ) {
                          return (
                            <Grid
                              className={
                                isdisabledDoor === false
                                  ? classes.kioskDoor
                                  : classes.disabledDoor
                              }
                              onClick={() =>
                                this.openbin(elem[n] + '' + (n1 + 1))
                              }
                              key={elem[n] + ' ' + (n1 + 1)}
                              item
                              xs={3}
                            >
                              <Paper
                                id={elem[n] + '' + (n1 + 1)}
                                className={classes.gridback}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: 44
                                }}
                              >
                                {elem[n] + ' ' + (n1 + 1)}
                              </Paper>
                            </Grid>
                          );
                        }
                      }
                      return '';
                    })}
                </Grid>
              );
            })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AdminM24MainUnitLayout);
