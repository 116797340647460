const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      justifyContent: 'center'
    },
    appLogoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      height: '80px',
      justifyContent: 'center'
    },
    appLogo: {
      width: '290px'
    },
    appName: {
      fontSize: '3em',
      fontWeight: '400',
      color: '#005d67'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    form: {
      maxHeight: '50vh',
      overflow: 'auto'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    }
  };
};

export default styles;
