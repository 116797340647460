import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdminPanelFormContainer from './AdminPanelFormContainer';
import AdminSideSlides from '../../AdminLayout/AdminSideSlides';
import { injectIntl, defineMessages } from 'react-intl';
import KioskIdelScreen from '../../KioskLayout/KioskIdelScreen';

const messages = defineMessages({
  WhatToDo: {
    id: 'Admin.Panel.WhatToDo',
    defineMessages: 'What are you here to do?'
  }
});

export class AdminPanelLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: this.props && this.props.intl.formatMessage(messages.WhatToDo),
    height: 300,
    open: true
  };

  componentDidMount() {
    const timer = setTimeout(() => {
      this.setState({ open: false });
    }, 3000);
    return () => clearTimeout(timer);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <>
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminPanelFormContainer />
        <KioskIdelScreen />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(injectIntl(AdminPanelLayout));
