import { connect } from 'react-redux';
import AdminLoginForm from './AdminLoginForm';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const AdminLoginFormContainer = connect(mapStateToProps)(AdminLoginForm);

export default AdminLoginFormContainer;
