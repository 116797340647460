// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminRemoveFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import ASSIGN from '../../KioskLayout/assignmentStatus';
import EVENT_TYPES from '../../Common/event-type';
// import { openBin, openMiniBins } from '../../../helpers/board';
import keyboardImg from '../../AdminLayout/keyboard.png';
import BOX_TYPE from '../../Common/box_type_enum';
import { readSettings, forceBinStatusCheck } from '../../../helpers/board';
import { writeKioskLog } from '../../Common/SyncAPIs';

const messages = defineMessages({
  Patient: {
    id: 'Admin.Stock.Patient',
    defineMessages: 'Patient'
  },
  OK: {
    id: 'Admin.Stock.OK',
    defineMessages: 'OK'
  },
  OnHold: {
    id: 'Admin.Verify.OnHold',
    defineMessages: 'This Prescription is already put on Hold.'
  },
  Code: {
    id: 'Admin.Verify.Code',
    defineMessages: 'Code'
  },
  VerifyAction: {
    id: 'Admin.Verify.VerifyAction',
    defineMessages: 'Verify'
  },
  ScanConfirmation: {
    id: 'Admin.Verify.ScanConfirmation',
    defineMessages: 'Scan confirmation'
  },
  DoItemMatch: {
    id: 'Admin.Verify.DoItemMatch',
    defineMessages: 'Do the items match?'
  },
  OnHoldWarning: {
    id: 'Admin.Verify.OnHoldWarning',
    defineMessages:
      'This code is on hold, please remove it and return it to the pharmacy.'
  },
  YesAction: {
    id: 'Admin.Verify.YesAction',
    defineMessages: 'Yes'
  },
  NoAction: {
    id: 'Admin.Verify.NoAction',
    defineMessages: 'No'
  },
  ValidStockCode: {
    id: 'Admin.Stock.ValidStockCode',
    defineMessages: 'Please enter valid stock code.'
  },
  ScanOrEnterStockCode: {
    id: 'Admin.Stock.ScanOrEnterStockCode',
    defineMessages: 'Scan or Enter your code here'
  },
  Back: {
    id: 'Admin.Stock.Back',
    defineMessages: 'Back'
  },
  RemoveAction: {
    id: 'Admin.Remove.RemoveAction',
    defineMessages: 'Remove'
  },
  RemoveConfirmation: {
    id: 'Admin.Remove.RemoveConfirmation',
    defineMessages: 'Remove confirmation'
  },
  RemovedCode: {
    id: 'Admin.Remove.RemovedCode',
    defineMessages: 'You have removed this order'
  },
  InvoiceNumber: {
    id: 'Retail.Stock.InvoiceNumber',
    defineMessages: 'Invoice Number'
  },
  ItemName: {
    id: 'Retail.Stock.ItemName',
    defineMessages: 'Item Name'
  },
  TotalItem: {
    id: 'Retail.Stock.TotalItem',
    defineMessages: 'Total Item'
  }
});

export class AdminRemoveFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patientDetails: [],
      stockCode: '',
      displayMsg: '',
      maxlength: 6,
      openOnReject: false,
      layoutName: 'default',
      SelectedInput: 'stockCode',
      puCode: '',
      showKeyboard: false,
      binIds: '',
      openDoorDialog: false,
      isStatusCheckFailed: false,
      progressMessage: '',
      boxTypeId: '',
      isFastoInProcess: false
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleNo = () => {
    this.setState({ open: false });
  };

  handleYes = () => {
    const updateStatusOfObj = {
      event_type_name: EVENT_TYPES.REMOVE_RX,
      type_id: ASSIGN.REMOVE_RX,
      box_id: this.props.box.id,
      bin_id: this.state.patientDetails[0].orders[0].bin_id
    };

    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,
      box_id: this.props.box.id,
      description:
        'Prescription was removed with stock code ' +
        this.state.patientDetails[0].orders[0].stockCode
    };

    fetch(process.env.REACT_APP_BASEURL + 'apis/i-ecom/status', {
      method: 'put',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
            .jwtToken
      },
      body: JSON.stringify(updateStatusOfObj)
    }).then((response) => {
      if (this.props.box && this.props.box.is_kiosk_log) {
        writeKioskLog(audit_obj);
      }
      try {
        return response.json();
      } catch (error) {
        return null;
      }
    });

    document.querySelector('#' + this.state.SelectedInput).value = '';
    this.setState({
      patientDetails: [],
      stockCode: '',
      displayMsg: '',
      open: false
    });
    setTimeout(() => {
      this.textInput.current.focus();
    }, 650);
    this.keyboard.clearInput();
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  change = (event) => {
    const { boxTypeId } = this.state;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
      let isHWCommuniocationEnabled = fileData
        ? fileData.isHWCommuniocationEnabled
        : true;
      if (isHWCommuniocationEnabled) {
        forceBinStatusCheck();
      }
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    const stockCode = event.target.value;
    if (this.props && this.props.box) {
      let customer;
      customer = this.props.box.customers.filter(
        (x) =>
          x &&
          x.orders &&
          x.orders.filter(
            (y) =>
              y &&
              y.stock_code.toString().toLowerCase() ===
                stockCode.toString().toLowerCase() &&
              (y.type_id === ASSIGN.STOCKED || y.type_id === ASSIGN.HOLD)
          ).length > 0
      )[0];

      let filteredArray = [];

      if (customer) {
        const order = customer.orders.filter(
          (x) =>
            x &&
            x.stock_code.toString().toLowerCase() ===
              stockCode.toString().toLowerCase() &&
            (x.type_id === ASSIGN.STOCKED || x.type_id === ASSIGN.HOLD)
        );
        customer.orders = order;
        filteredArray = [customer];
        if (order.length > 0) {
          this.setState({ showKeyboard: false });
        }
        if (boxTypeId === BOX_TYPE.FASTLANE) {
          this.setState(
            {
              patientDetails: filteredArray,
              stockCode: stockCode,
              displayMsg:
                this.props &&
                this.props.intl.formatMessage(messages.ValidStockCode)
            },
            () => {
              if (stockCode.length === 6 && [true, 'true'].includes(mode)) {
                this.handleProcessClick();
              }
            }
          );
        } else {
          this.setState({
            patientDetails: filteredArray,
            stockCode: stockCode,
            displayMsg:
              this.props &&
              this.props.intl.formatMessage(messages.ValidStockCode)
          });
        }
      } else {
        this.setState({
          patientDetails: [],
          pickupCode: '',
          displayMsg:
            this.props && this.props.intl.formatMessage(messages.ValidStockCode)
        });
      }
    }
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  renderPatientName() {
    const patient = this.state && this.state.patientDetails[0];
    return patient.email;
  }

  renderCount() {
    const patient = this.state && this.state.patientDetails[0];
    const lengthCount = patient && patient.orders.length;
    return lengthCount;
  }
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }

  render() {
    const { classes, handleBack } = this.props;
    const { patientDetails, stockCode, displayMsg } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div className={classNames(classes.container)}>
        <MuiThemeProvider theme={theme}>
          <TextField
            style={{ textAlign: 'center' }}
            id="stockCode"
            name="stockCode"
            className={classes.textField}
            defaultValue=""
            margin="normal"
            variant="outlined"
            placeholder={
              this.props &&
              this.props.intl.formatMessage(messages.ScanOrEnterStockCode)
            }
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            onChange={(e) => this.change(e)}
            autoFocus
            inputRef={this.textInput}
            onFocus={this.Focus.bind('stockCode')}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.change(ev);
              }
            }}
          />
        </MuiThemeProvider>
        {this.state && this.state.patientDetails.length > 0 ? (
          <div>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableBody>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    {this.props &&
                      this.props.intl.formatMessage(messages.Patient)}
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {this.renderPatientName()}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    {this.props && this.props.intl.formatMessage(messages.Code)}
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {stockCode.toUpperCase()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableHead className={classNames(classes.TableHead)}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.CodeClass,
                      classes.cellBorder
                    )}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(messages.InvoiceNumber)}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(messages.ItemName)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientDetails &&
                  patientDetails[0].orders.map((item) => {
                    return (
                      <TableRow
                        className={classes.PatientInfoTableRow}
                        key={item.invoice_id}
                      >
                        <TableCell className={classNames(classes.fontSizes)}>
                          {item.invoice_id}
                        </TableCell>
                        <TableCell className={classNames(classes.fontSizes)}>
                          {item.item}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classNames(classes.Instructiontitle)}>
            {displayMsg && displayMsg}
          </div>
        )}
        {this.state && this.state.patientDetails.length > 0 && (
          <Table className={classNames(classes.PatientInfoTable)}>
            <TableBody>
              <TableRow className={classes.PatientInfoTableRow}>
                <TableCell
                  colSpan={5}
                  className={classNames(
                    classes.PatientInfoTableEmptyCell,
                    classes.cellBorderInfo
                  )}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.TotalItem)}
                  ({this.renderCount()})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            {this.props && this.props.intl.formatMessage(messages.Back)}
          </Button>
          <img
            src={keyboardImg}
            className={classNames(classes.keyboardImg)}
            alt=""
            onClick={this.keyboardHandler}
          />
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            {this.props && this.props.intl.formatMessage(messages.RemoveAction)}
          </Button>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.RemoveConfirmation)}
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                {this.props &&
                  this.props.intl.formatMessage(messages.RemovedCode)}{' '}
                {stockCode.toUpperCase()}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleYes}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.YesAction)}
                </Button>
                <Button
                  onClick={this.handleNo}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.NoAction)}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminRemoveFormControls)
);
