import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Card, Grid, withStyles } from '@material-ui/core';
import {
  RetailVerifyCode,
  RetailConfirmOrder,
  RetailOpenBin
} from './components';
import Footer from './components/RetailUI/footer';
import Header from './components/RetailUI/header';
import SecureBadgeLoginForm from './components/RetailUI/SecureBadgeLogin/SecureBadgeLoginForm';
import OktaLogin from './components/RetailUI/OktaLogin/OktaLoginForm';
import AdminLogin from './components/RetailUI/AdminLogin/AdminLoginFormContainer';
import AdminPanel from './components/RetailUI/AdminPanel/AdminPanelLayout';
import AdminStock from './components/RetailUI/AdminPanelStock/AdminPanelStockLayout';
import AdminRemove from './components/RetailUI/AdminRemove/AdminRemoveLayout';
import AdminKioskVerify from './components/RetailUI/AdminVerify/AdminKioskVerifyLayout';
import AdminSettings from './components/RetailUI/AdminSetting/AdminSettingLayout';
import AdminMaintenance from './components/RetailUI/AdminMaintenance/AdminMaintenanceLayout';
import KioskFeedback from './components/RetailUI/KioskFeedback/KioskFeedbackMain';

const styles = () => ({
  root__container: {
    width: '100vw',
    backgroundColor: '#fff',
    height: '99vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  header__footer__container: {
    height: '6%',
    textAlign: 'center',
    alignItems: 'center'
  },
  main__container: {
    height: '80%'
  },
  main__card: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    boxShadow: 'none'
  }
});

const RetailUI = ({ classes, box, history, match }) => {
  return (
    <div className={classes.root__container}>
      <Header box={box} />
      <Grid
        container
        className={classes.main__container}
        justifyContent="center"
      >
        <Grid item xs={10} style={{ height: '100%' }}>
          <Card className={classes.main__card}>
            <Route
              path={`${match.path}/verification`}
              component={RetailVerifyCode}
            />
            <Route
              path={`${match.path}/confirm/order`}
              component={RetailConfirmOrder}
            />
            <Route path={`${match.path}/open-bin`} component={RetailOpenBin} />
            <Route
              path={`${match.path}/SecureBadgeLogin`}
              component={SecureBadgeLoginForm}
            />
            <Route path={`${match.path}/implicit`} component={OktaLogin} />
            <Route path={`${match.path}/AdminLogin`} component={AdminLogin} />
            <Route path={`${match.path}/AdminPanel`} component={AdminPanel} />
            <Route path={`${match.path}/AdminStock`} component={AdminStock} />
            <Route path={`${match.path}/AdminRemove`} component={AdminRemove} />
            <Route
              path={`${match.path}/AdminKioskVerify`}
              component={AdminKioskVerify}
            />
            <Route
              path={`${match.path}/AdminSettings`}
              component={AdminSettings}
            />
            <Route
              path={`${match.path}/AdminMaintenance`}
              component={AdminMaintenance}
            />
            <Route path={`${match.path}/feedback`} component={KioskFeedback} />
          </Card>
        </Grid>
      </Grid>
      <Footer box={box} history={history} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(withStyles(styles)(RetailUI));
