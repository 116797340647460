// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminMaintancenceFormControls.styles';
import Image1 from './AdminPanelImages/1.png';
import Deactivate from './AdminPanelImages/deactivate.png';
import TestCommunication from './AdminPanelImages/test_communication.png';
import { Button } from '@material-ui/core';
import { injectIntl, defineMessages } from 'react-intl';
import { openBin, openMiniBins } from '../../../helpers/board';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { readSettings } from '../../../helpers/board';
import BinMaintenance from './AdminPanelImages/repairing-service.svg';
import ActivateBin from './AdminPanelImages/icon.svg';
import Boxlayout from './box-layout';
import BOX_TYPES from '../../Common/box_type_enum';
import { syncMapping } from '../../Common/mini22_bins_mapping';
import box_type_enum from '../../Common/box_type_enum';

const messages = defineMessages({
  SureToDeactivate: {
    id: 'Admin.Maintenance.SureToDeactivate',
    defineMessages: 'Are you sure want to deactivate '
  },
  SureToActivate: {
    id: 'Admin.Maintenance.SureToActivate',
    defineMessages: 'Are you sure want to activate '
  },
  SelectDeActivatedBin: {
    id: 'Admin.Maintenance.SelectDeActivatedBin',
    defineMessages: 'Please select the deactivated bin to continue.'
  },
  SelectBinToDeActivate: {
    id: 'Admin.Maintenance.SelectBinToDeActivate',
    defineMessages: 'Please select the bin to deactivate.'
  },
  TestCommunication: {
    id: 'Admin.Maintenance.TestCommunication',
    defineMessages: 'Test Communication'
  },
  OpenAllDoors: {
    id: 'Admin.Maintenance.OpenAllDoors',
    defineMessages: 'Open All Doors'
  },
  BinMaintenance: {
    id: 'Admin.Maintenance.BinMaintenance',
    defineMessages: 'Bin Maintenance'
  },
  DeactivateBin: {
    id: 'Admin.Maintenance.DeactivateBin',
    defineMessages: 'Deactivate Bin'
  },
  ActivateBin: {
    id: 'Admin.Maintenance.ActivateBin',
    defineMessages: 'Activate Bin'
  },
  Bin: {
    id: 'Admin.Maintenance.Bin',
    defineMessages: 'bin?'
  },
  YesAction: {
    id: 'Admin.Verify.YesAction',
    defineMessages: 'Yes'
  },
  NoAction: {
    id: 'Admin.Verify.NoAction',
    defineMessages: 'No'
  },
  OK: {
    id: 'Admin.Stock.OK',
    defineMessages: 'OK'
  },
  Back: {
    id: 'Admin.Stock.Back',
    defineMessages: 'Back'
  }
});

let nums = Array.from(Array(5).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ['A', 'B', 'C', 'D', 'E'];
var selectedBins = [];
let j = 0;
let selbins = [];
let binbackground = [];
let nochangeInbinback;
export class AdminMaintancenceFormControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boxid: '',
      popupShow: false,
      disablebins: '',
      selectedUnitName: '',
      selectedBinId: '',
      deactivatedBins: [],
      expansionUnits: [
        {
          id: -1,
          order: 0,
          pos: 'M',
          selectedUnitIndex: 0,
          box_position: 0
        }
      ],
      selBinsCheck: false
    };
    selectedBins = [];
  }

  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  componentDidMount() {
    const { box } = this.props;
    if (box && box.type_id !== box_type_enum.FASTLANE) {
      window._this = this;
      this.bindExpansions();
      this.getSelectedUnitName(0);
      this.getBinsArray();
    }
  }

  disableBins = (type) => {
    if (this.props.box !== null && this.props.box !== undefined) {
      const boxesid = this.props.box.id;
      this.setState({ boxid: boxesid });
      let bins = this.props.box.disabled_bins;
      if (bins !== null && bins !== undefined) {
        bins = bins.replace("'", '');
        selbins = bins.split(',');
        selbins.pop();
        this.setState({ deactivatedBins: selbins });

        let binColor = '';
        let textColor = '';
        if (type === 0) {
          binColor =
            'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)';
          textColor = 'rgb(255, 255, 255)';
        }
        if (type === 1) {
          binColor =
            'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
          textColor = 'rgb(0, 0, 0)';
        }
        for (let element of selbins) {
          let items = document.getElementById(element);
          if (items) {
            items.style.background = binColor;
            items.style.color = textColor;
          }
        }
      }
    }
  };

  bindExpansions = () => {
    let order = 0;
    let UnitLayout = [];
    let UnitIndex = 0;
    let expansionDetails =
      this.props && this.props.box && this.props.box.box_expansions;
    if (expansionDetails && expansionDetails.length > 0) {
      //Add Left side Unit
      UnitLayout = [];
      if (expansionDetails.findIndex((x) => x.direction === 1) > -1) {
        expansionDetails &&
          expansionDetails.map((unit, index) => {
            let boxlist = [];
            if (unit.direction === 1) {
              boxlist = unit;
              boxlist.order = order + 1;
              boxlist.selectedUnitIndex = Math.abs(unit.box_position);
              boxlist.pos = 'L';
              boxlist.box_position = unit.box_position;
              order = order + 1;
              UnitLayout.push(boxlist);
              UnitIndex = UnitIndex + 1;
            }
            return true;
          });
      }
      if (UnitLayout.length > 0) {
        UnitLayout.reverse();
      }
      order = order + 1;
      UnitIndex = UnitIndex + 1;
      const expansionsUnits = {
        id: -1,
        order: order,
        pos: 'M',
        selectedUnitIndex: 0,
        box_position: 0
      };
      if (
        this.props &&
        this.props.box &&
        this.props.box !== null &&
        this.props.box !== undefined
      ) {
        if (this.props && this.props.box.type_id === BOX_TYPES.MAIN_46) {
          UnitLayout.push(expansionsUnits);
        } else if (this.props && this.props.box.type_id === BOX_TYPES.M_22) {
          expansionsUnits.pos = 'M22';
          UnitLayout.push(expansionsUnits);
        } else if (this.props && this.props.box.type_id === BOX_TYPES.M_24) {
          expansionsUnits.pos = 'M24';
          UnitLayout.push(expansionsUnits);
        } else {
          UnitLayout.push(expansionsUnits);
        }
      }

      //Add Right side Unit
      if (expansionDetails.findIndex((x) => x.direction === 2) > -1) {
        expansionDetails &&
          expansionDetails.map((unit, index) => {
            let boxlist = [];
            if (unit.direction === 2) {
              boxlist = unit;
              boxlist.order = order + 1;
              boxlist.selectedUnitIndex = unit.box_position;
              boxlist.pos = 'R';
              boxlist.box_position = unit.box_position;
              order = order + 1;
              UnitLayout.push(boxlist);
              UnitIndex = UnitIndex + 1;
            }
            return true;
          });
      }
    } else {
      order = order + 1;
      const expansionsUnits = {
        id: -1,
        order: order,
        pos: 'M',
        selectedUnitIndex: 0,
        box_position: 0
      };
      if (
        this.props &&
        this.props.box &&
        this.props.box !== null &&
        this.props.box !== undefined
      ) {
        if (this.props && this.props.box.type_id === BOX_TYPES.MAIN_46) {
          UnitLayout.push(expansionsUnits);
        } else if (this.props && this.props.box.type_id === BOX_TYPES.M_22) {
          expansionsUnits.pos = 'M22';
          UnitLayout.push(expansionsUnits);
        } else if (this.props && this.props.box.type_id === BOX_TYPES.M_24) {
          expansionsUnits.pos = 'M24';
          UnitLayout.push(expansionsUnits);
        } else {
          UnitLayout.push(expansionsUnits);
        }
      }
    }
    this.setState({ expansionUnits: UnitLayout });
  };

  closeApp = () => {
    window.close();
  };

  openBins = (nums1, nums, elem, pos, boxtypeid) => {
    let Ids = [];
    if (pos === 'M') {
      nums1.forEach((n1) => {
        nums.forEach((n) => {
          if ((elem[n] === 'B' || elem[n] === 'C') && n1 >= 0 && n1 <= 6) {
          } else {
            if (elem[n] === 'A') {
              Ids.push(elem[n] + '' + (n1 + 1));
            } else if (elem[n] === 'B' && n1 >= 7 && n1 <= 11) {
              Ids.push(elem[n] + '' + (n1 + 1));
            } else if (elem[n] === 'C' && n1 >= 7 && n1 <= 11) {
              Ids.push(elem[n] + '' + (n1 + 1));
            } else {
              Ids.push(elem[n] + '' + (n1 + 1));
            }
          }
        });
      });
    } else {
      nums = Array.from(Array(3).keys());
      nums1 = Array.from(Array(12).keys());
      elem = ['A', 'B', 'C'];
      nums1.forEach((n1) => {
        nums.forEach((n) => {
          if ((elem[n] === 'A' || elem[n] === 'B') && n1 >= 0 && n1 <= 6) {
          } else {
            if (elem[n] === 'A' && n1 >= 7 && n1 <= 11) {
              Ids.push(elem[n] + '' + (n1 + 1));
            } else if (elem[n] === 'B' && n1 >= 7 && n1 <= 11) {
              Ids.push(elem[n] + '' + (n1 + 1));
            } else {
              Ids.push(elem[n] + '' + (n1 + 1));
            }
          }
        });
      });
    }

    const filteredArray = window._this.sortArray(Ids);
    for (let index = 0; index < filteredArray.length; index++) {
      let element = filteredArray[index];
      console.log('Queuing ' + element);
      setTimeout(() => {
        if (boxtypeid === BOX_TYPES.M_22) {
          let eleBin = syncMapping(element);
          openMiniBins(eleBin);
        } else {
          openBin(element);
        }
        var items = document.getElementById(element);
        if (items) {
          items.style.background =
            'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
        }
      }, index * 1000 + 1000);
    }
  };

  openAllBin = () => {
    const expansionUnits =
      this.state &&
      this.state.expansionUnits.filter(
        (x) => x.pos === 'M' || x.pos === 'M22' || x.pos === 'M24'
      );
    const pos = expansionUnits && expansionUnits[0].pos;
    let boxTypeID = this.props && this.props.box && this.props.box.type_id;
    this.openBins(nums1, nums, elem, pos, boxTypeID);
  };

  sortArray(Ids) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;

    function sortAlphaNum(a, b) {
      var aA = a.replace(reA, '');
      var bA = b.replace(reA, '');
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ''), 10);
        var bN = parseInt(b.replace(reN, ''), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    }
    return Ids.sort(sortAlphaNum);
  }
  activatebin = () => {
    nochangeInbinback = true;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }

    const bins = [];
    let activatedbins = [];
    const id = this.props.box.id;
    let varname = '';
    activatedbins = selbins.filter((item) => {
      return selectedBins.indexOf(item) === -1;
    });
    for (let i = 0; i < selectedBins.length; i++) {
      let items = document.getElementById(selectedBins[i]);
      items.style.background =
        'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
      items.style.color = 'rgb(0, 0, 0)';
      bins.push(selectedBins[i]);
    }
    for (let i = 0; i < activatedbins.length; i++) {
      if (i !== activatedbins.length - 1) {
        varname += activatedbins[i] + ',';
      } else {
        varname += activatedbins[i] + ',';
      }
    }
    let obj = {
      disabled_bins: varname,
      id: id
    };
    if (mode === true || mode === 'true') {
      fetch(process.env.REACT_APP_BASEURL + 'boxs', {
        method: 'put',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken
        },
        body: JSON.stringify(obj)
      }).then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      });
    }
    this.setState({ popupShow: false });
    selbins = activatedbins;
    selectedBins = [];
  };

  deactivatebin = () => {
    nochangeInbinback = true;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }

    const bins = [];
    const id = this.props.box.id;
    let varname = '';
    for (let i = 0; i < selbins.length; i++) {
      selectedBins.push(selbins[i]);
    }
    const selectedBinIDs = [...new Set(selectedBins)];
    const binColor =
      'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)';
    const textColor = 'rgb(255, 255, 255)';
    for (var i = 0; i < selectedBinIDs.length; i++) {
      var items = document.getElementById(selectedBinIDs[i]);
      items.style.background = binColor;
      items.style.color = textColor;
      bins.push(selectedBinIDs[i]);
      if (i !== selectedBinIDs.length - 1) {
        varname += selectedBinIDs[i] + ',';
      } else {
        varname += selectedBinIDs[i] + ',';
      }
    }
    let obj = {
      disabled_bins: varname,
      id: id
    };
    if ((mode === true || mode === 'true') && varname !== '') {
      fetch(process.env.REACT_APP_BASEURL + 'boxs', {
        method: 'put',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken
        },
        body: JSON.stringify(obj)
      }).then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      });
    }
    this.setState({ popupShow: false });
    selbins = selectedBins;
    selectedBins = [];
  };

  openbin = (binId) => {
    openBin(binId);
    selectedBins.push(binId);
    var items = document.getElementById(binId);
    let obj = {
      Id: binId,
      background: items.style.background
    };
    binbackground.push(obj);
    items.style.background = 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
  };

  deactivateBinPopup = () => {
    this.setState({ selBinsCheck: false });
    if (
      [...new Set(selectedBins)].filter((ele) => !selbins.includes(ele))
        .length > 0
    ) {
      this.setState({ popupShow: true, bintoactivate: false });
    } else {
      this.setState({ alertpopupShow: true, nobintoactivate: false });
    }
  };

  activateBinPopup = () => {
    this.setState({ selBinsCheck: true });
    if (
      [...new Set(selectedBins)].filter((ele) => selbins.includes(ele)).length >
      0
    ) {
      this.setState({ popupShow: true, bintoactivate: true });
    } else {
      this.setState({ alertpopupShow: true, nobintoactivate: true });
    }
  };

  binMaintenance = () => {
    this.setState({ binmaintenance: true });
    this.disableBins(0);
    const binColor =
      'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
    const textColor = 'rgb(0, 0, 0)';
    [...new Set(selectedBins)]
      .filter((ele) => !selbins.includes(ele))
      .forEach((sElem) => {
        let items = document.getElementById(sElem);
        if (items) {
          items.style.background = binColor;
          items.style.color = textColor;
        }
      });
    selectedBins = [];
  };

  handleBack = () => {
    if (nochangeInbinback !== true) {
      for (let i = 0; i < binbackground.length; i++) {
        var items = document.getElementById(binbackground[i].Id);
        items.style.background = binbackground[i].background;
      }
    }
    this.setState({ binmaintenance: false, selBinsCheck: false });
    this.disableBins(1);
    const binColor =
      'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
    const textColor = 'rgb(0, 0, 0)';
    for (let element of selectedBins) {
      let items = document.getElementById(element);
      if (items) {
        items.style.background = binColor;
        items.style.color = textColor;
      }
    }
    selectedBins = [];
  };

  closePopup = () => {
    for (let i = 0; i < binbackground.length; i++) {
      var items = document.getElementById(binbackground[i].Id);
      items.style.background = binbackground[i].background;
    }
    this.setState({ popupShow: false, alertpopupShow: false });
  };

  getSelectedUnitName = (index) => {
    if (index === 1) {
      this.setState({ selectedUnitName: 'L60 (Locker Addon with 60 bins)' });
    } else if (index === 2) {
      this.setState({ selectedUnitName: 'HD36 (Locker Addon with 36 bins)' });
    } else if (index === 3) {
      this.setState({ selectedUnitName: 'Cool (Temp Controlled with 8 bins)' });
    } else {
      this.setState({ selectedUnitName: 'Locker (Main Unit with 46 bins)' });
    }
  };

  getSelectedBin = (binIds, position) => {
    selectedBins.push(binIds);
    this.setState({ selectedBinId: binIds, box_position: position });
  };

  getBinsArray = async () => {
    let response = [];
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      let isHWCommuniocationEnabled = fileData
        ? fileData.isHWCommuniocationEnabled
        : true;
      if (isHWCommuniocationEnabled === true) {
        const paymentBaseURL = process.env.REACT_APP_BASE_APP_URL;
        await fetch(`${paymentBaseURL}all-bins`)
          .then((response) => {
            try {
              return response.text();
            } catch (error) {
              return null;
            }
          })
          .then((binsObject) => {
            response = JSON.parse(binsObject);
          });
      }
    }
    return response;
  };

  render() {
    const { classes, handleBack, box } = this.props;
    const { selBinsCheck } = this.state;
    if (j === 0 && box !== null && box !== undefined) {
      this.setState({ boxid: box.id });
      j++;
      this.binId = box;
    }

    let SelElem = [...new Set(selectedBins)]
      .filter((ele) =>
        selBinsCheck ? selbins.includes(ele) : !selbins.includes(ele)
      )
      .sort((a, b) => a - b)
      .map((sElem, index) => {
        if (index === 0) {
          return <span key={index}>{sElem}</span>;
        } else {
          return <span key={index}>{', ' + sElem}</span>;
        }
      });
    return (
      <div>
        {box && box.type_id && box.type_id !== box_type_enum.FASTLANE ? (
          <div className={classNames(classes.container)}>
            <Dialog
              maxWidth={'lg'}
              fullWidth={true}
              open={this.state.popupShow || false}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{
                style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
              }}
            >
              {!this.state.bintoactivate ? (
                <div className={classNames(classes.dialogBackground)}>
                  <DialogContent
                    className={classNames(classes.dialogContentColor)}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(
                        messages.SureToDeactivate
                      )}{' '}
                    {SelElem}{' '}
                    {this.props && this.props.intl.formatMessage(messages.Bin)}
                  </DialogContent>
                  <DialogActions className={classNames(classes.alignButton)}>
                    <Button
                      onClick={this.closePopup}
                      className={classNames(classes.buttonColor)}
                    >
                      {' '}
                      {this.props &&
                        this.props.intl.formatMessage(messages.NoAction)}
                    </Button>
                    <Button
                      onClick={this.deactivatebin}
                      className={classNames(classes.buttonColor)}
                    >
                      {' '}
                      {this.props &&
                        this.props.intl.formatMessage(messages.YesAction)}
                    </Button>
                  </DialogActions>
                </div>
              ) : (
                <div className={classNames(classes.dialogBackground)}>
                  <DialogContent
                    className={classNames(classes.dialogContentColor)}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(
                        messages.SureToActivate
                      )}{' '}
                    {SelElem}{' '}
                    {this.props && this.props.intl.formatMessage(messages.Bin)}
                  </DialogContent>
                  <DialogActions className={classNames(classes.alignButton)}>
                    <Button
                      onClick={this.closePopup}
                      className={classNames(classes.buttonColor)}
                    >
                      {' '}
                      {this.props &&
                        this.props.intl.formatMessage(messages.NoAction)}
                    </Button>
                    <Button
                      onClick={this.activatebin}
                      className={classNames(classes.buttonColor)}
                    >
                      {' '}
                      {this.props &&
                        this.props.intl.formatMessage(messages.YesAction)}
                    </Button>
                  </DialogActions>
                </div>
              )}
            </Dialog>
            <Dialog
              maxWidth={'lg'}
              fullWidth={true}
              open={this.state.alertpopupShow || false}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{
                style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
              }}
            >
              <div className={classNames(classes.dialogBackground)}>
                {this.state.nobintoactivate ? (
                  <DialogContent
                    className={classNames(classes.dialogContentColor)}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(
                        messages.SelectDeActivatedBin
                      )}
                  </DialogContent>
                ) : (
                  <DialogContent
                    className={classNames(classes.dialogContentColor)}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(
                        messages.SelectBinToDeActivate
                      )}
                  </DialogContent>
                )}
                <DialogActions className={classNames(classes.alignButton)}>
                  <Button
                    onClick={this.closePopup}
                    className={classNames(classes.buttonColor)}
                  >
                    {' '}
                    {this.props && this.props.intl.formatMessage(messages.OK)}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>

            {/*  =========================   For Rear Stock Screen   ========================= */}
            <div className={classNames(classes.navContainer)}>
              <div className={classNames(classes.navItem)}>
                <div
                  className={classNames(classes.navItemTop)}
                  onClick={this.openAllBin}
                >
                  <img
                    src={Image1}
                    alt="Stock"
                    className={classNames(classes.navItemTopImg)}
                  />
                </div>
                <p className={classNames(classes.navItemText)}>
                  Open All Doors
                </p>
              </div>

              <div className={classNames(classes.navItem)}>
                <div
                  className={classNames(classes.navItemTop)}
                  onClick={this.deactivateBinPopup}
                >
                  <img
                    src={Deactivate}
                    alt="Deactivate"
                    className={classNames(classes.navItemTopImg)}
                  />
                </div>
                <p className={classNames(classes.navItemText)}>
                  De-Activate Bin
                </p>
              </div>

              <div className={classNames(classes.navItem)}>
                <div className={classNames(classes.navItemTop)}>
                  <img
                    src={TestCommunication}
                    alt="TestCommunication"
                    className={classNames(classes.navItemTopImg)}
                  />
                </div>
                <p className={classNames(classes.navItemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.TestCommunication)}
                </p>
              </div>
            </div>

            {/*  =========================   For Kiosk Screen   ========================= */}
            {!this.state.binmaintenance ? (
              <div className={classNames(classes.flexContainer)}>
                <div
                  onClick={this.openAllBin}
                  className={classNames(classes.flexItem)}
                >
                  <img
                    className={classNames(classes.imgProperty)}
                    src={Image1}
                    alt="Stock"
                  />
                </div>
                <div
                  onClick={this.binMaintenance}
                  className={classNames(classes.flexItem)}
                >
                  <img
                    className={classNames(classes.imgProperty)}
                    src={BinMaintenance}
                    alt="Remove"
                  />
                </div>
                <div className={classNames(classes.flexItem)}>
                  <img
                    className={classNames(classes.imgProperty)}
                    src={TestCommunication}
                    alt="Verify"
                  />
                </div>
              </div>
            ) : (
              <div className={classNames(classes.flexContainer)}>
                <div
                  onClick={this.activateBinPopup}
                  className={classNames(classes.flexItem)}
                >
                  <img
                    className={classNames(classes.imgProperty)}
                    src={ActivateBin}
                    alt="Stock"
                  />
                </div>
                <div
                  onClick={this.deactivateBinPopup}
                  className={classNames(classes.flexItem)}
                >
                  <img
                    className={classNames(classes.imgProperty)}
                    src={Deactivate}
                    alt="Remove"
                  />
                </div>
              </div>
            )}

            {!this.state.binmaintenance ? (
              <div className={classNames(classes.flexContainer)}>
                <div className={classNames(classes.flexTemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.OpenAllDoors)}
                </div>
                <div className={classNames(classes.flexTemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.BinMaintenance)}
                </div>
                <div className={classNames(classes.flexTemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.TestCommunication)}
                </div>
              </div>
            ) : (
              <div className={classNames(classes.flexContainer)}>
                <div className={classNames(classes.flexTemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.ActivateBin)}
                </div>
                <div className={classNames(classes.flexTemText)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.DeactivateBin)}
                </div>
              </div>
            )}

            <div className={classNames(classes.ratingContainer)}>
              <Boxlayout
                BinStatus={this.state.BinStatus}
                deactivatedBins={this.state.deactivatedBins}
                selectedBinId={this.state.selectedBinId}
                expansionUnits={this.state && this.state.expansionUnits}
                getSelectedBin={this.getSelectedBin}
                getSelectedUnitName={this.getSelectedUnitName}
                currentTabIndex={
                  this.state &&
                  this.state.expansionUnits.findIndex(
                    (x) => x.pos === 'M' || x.pos === 'M22' || x.pos === 'M24'
                  )
                }
                boxes={this.props.box}
              />
            </div>
            <div className={classNames(classes.buttonContainer)}>
              {!this.state.binmaintenance ? (
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classNames(classes.primaryButton, classes.backBtn)}
                  onClick={handleBack}
                >
                  {this.props && this.props.intl.formatMessage(messages.Back)}
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classNames(classes.primaryButton, classes.backBtn)}
                  onClick={this.handleBack}
                >
                  {this.props && this.props.intl.formatMessage(messages.Back)}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminMaintancenceFormControls)
);
