const styles = (theme) => {
  return {
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'grid'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center',
      margin: '15px'
    },
    ratingContainer: {
      fontWeight: '500',
      color: '#488581',
      display: 'grid',
      width: '570px'
    },
    linkLabel: {
      color: '#b8c3c3'
    },
    gridback: {
      background:
        'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
      padding: '12px 0px',
      color: '#000000',
      textAlign: 'center'
    },
    gridBlueback: {
      background:
        'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(37, 34, 239) 90%)',
      padding: '12px 0px',
      color: '#fff',
      textAlign: 'center'
    },
    gridOrangeback: {
      background:
        'linear-gradient(155deg, rgb(206, 144, 123) 0%, rgb(255, 207, 166) 90%)',
      padding: '12px 0px',
      color: '#000',
      textAlign: 'center'
    },
    gridRedback: {
      background:
        'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)',
      padding: '12px 0px',
      color: '#fff',
      textAlign: 'center'
    },
    gridSkyblueback: {
      background:
        'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(4, 144, 243) 90%)',
      padding: '12px 0px',
      color: '#fff',
      textAlign: 'center'
    },
    activeBin: {
      background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)',
      padding: '12px 0px',
      color: '#000',
      textAlign: 'center',
      pointerEvents: 'none !important'
    },
    RemoveMargin: {
      margin: '0!important'
    },
    imageProperty: {
      height: '500px'
    },
    linkContent: {
      textDecoration: 'none'
    },
    kioskDoor: {
      cursor: 'pointer'
    },
    disabledDoor: {
      pointerEvents: 'none'
    },
    boxLayout: {
      padding: '63px'
    },
    deactiveBin: {
      cursor: 'default',
      pointerEvents: 'none !important'
    },
    '@media (max-width: 640px)': {
      container: {
        gridTemplateColumns: '320px'
      }
    },
    '@media only screen and (min-width: 1024px) and (max-width:1440px) and (orientation:landscape)':
      {
        container: {
          minWidth: '640px',
          minHeight: '300px',
          position: 'relative',
          gridGap: '1rem',
          gridTemplateColumns: '100%'
        },
        spacer: {
          height: '21vh'
        },
        primaryButton: {
          marginTop: '1em',
          background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
          color: '#fff'
        },
        ratingContainer: {
          fontWeight: '500',
          color: '#488581',
          display: 'grid',
          width: '400px',
          marginTop: '160px'
        },
        backButton: {
          marginTop: '1em',
          background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
          color: '#fff',
          width: '0%'
        },
        gridback: {
          background:
            'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
          padding: '9px 0px',
          color: '#000000',
          textAlign: 'center'
        },
        gridBlueback: {
          background:
            'linear-gradient(155deg, rgb(0, 61, 255) 0%, rgb(135, 163, 232) 90%)',
          padding: '12px 0px',
          color: '#fff',
          textAlign: 'center'
        },
        gridRedback: {
          background:
            'linear-gradient(155deg, rgb(245, 0, 0) 0%, rgb(253, 195, 195) 90%)',
          padding: '12px 0px',
          color: '#fff',
          textAlign: 'center'
        },
        activeBin: {
          background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)',
          padding: '12px 0px',
          color: '#000',
          textAlign: 'center'
        },

        RemoveMargin: {
          margin: '0!important'
        },
        imageProperty: {
          height: '500px'
        },
        linkContent: {
          textDecoration: 'none'
        },
        kioskDoor: {
          cursor: 'pointer'
        },
        deactiveBin: {
          cursor: 'default',
          pointerEvents: 'none !important'
        },
        '@media (max-width: 640px)': {
          container: {
            gridTemplateColumns: '320px'
          }
        },
        '@media only screen and (min-width: 1024px) and (max-width:1440px) and (orientation:landscape)':
          {
            container: {
              minWidth: '640px',
              minHeight: '300px',
              position: 'relative',
              gridGap: '1rem',
              gridTemplateColumns: '100%'
            },
            spacer: {
              height: '21vh'
            },
            primaryButton: {
              marginTop: '1em',
              background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
              color: '#fff'
            },
            ratingContainer: {
              fontWeight: '500',
              color: '#488581',
              display: 'grid',
              width: '400px'
            },
            backButton: {
              marginTop: '1em',
              background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
              color: '#fff',
              width: '0%'
            },
            gridback: {
              background:
                'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
              padding: '9px 0px',
              color: '#000000',
              textAlign: 'center'
            },
            imageProperty: {}
          },
        '@media only screen and (min-width:1280px) and (max-width:1366px) and (orientation:landscape)':
          {
            container: {
              minWidth: '640px',
              minHeight: '200px',
              position: 'relative',
              gridGap: '1rem',
              gridTemplateColumns: '100%'
            },
            spacer: {
              height: '21vh'
            },
            primaryButton: {
              marginTop: '1em',
              background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
              color: '#fff'
            },
            ratingContainer: {
              fontWeight: '500',
              color: '#488581',
              display: 'grid',
              width: '300px'
            },
            backButton: {
              marginTop: '1em',
              background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
              color: '#fff',
              width: '0%'
            },
            gridback: {
              background:
                'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)',
              padding: '9px 0px',
              color: '#000000',
              textAlign: 'center'
            },
            imageProperty: {}
          }
      }
  };
};

export default styles;
