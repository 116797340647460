import { connect } from 'react-redux';
import AdminSettingForm from './AdminSettingForm';

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return {
    ...kiosk
  };
};

const AdminSettingFormContainer = connect(mapStateToProps)(AdminSettingForm);

export default AdminSettingFormContainer;
