import { connect } from 'react-redux';
import AdminRemoveForm from './AdminRemoveForm';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const AdminRemoveContainer = connect(mapStateToProps)(AdminRemoveForm);

export default AdminRemoveContainer;
