const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      gridGap: '1em',
      alignItems: 'center'
    },
    primaryButton: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
      width: '20%',
      fontSize: '20px'
    },
    disabledPrimaryButton: {
      marginTop: '1em'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: '#005d67',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #005d67',
      cursor: 'pointer'
    },
    codeGrid: {
      display: 'grid',
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    RemoveIcon: {
      width: '60px',
      padding: '0'
    },
    PatientClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    CodeClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    PrescriptionClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    TableHead: {
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    fontSizes: {
      fontSize: '1em',
      padding: '0'
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      width: '82%',
      bottom: '10%',
      left: '9%'
    },
    cellBorderInfo: {
      borderBottom: '1px solid #fff'
    },
    dialogBackground: {
      background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
    },
    dialogContentColor: {
      color: '#387371',
      textAlign: 'center',
      fontSize: '1.5em'
    },
    dialogTitleColor: {
      color: '#387371',
      textAlign: 'left',
      fontSize: '1.2em'
    },
    alignButton: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonColor: {
      color: '#387371',
      fontSize: '1.5em'
    },
    messageInfo: {
      textAlign: 'center'
    },
    Instructiontitle: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center'
    },
    simpleKeyboard: {
      width: '100%',
      position: 'absolute',
      left: '0%',
      right: '0%',
      zIndex: 999,
      bottom: '2%',
      opacity: '0',
      visibility: 'hidden',
      transition: 'all 0.3s ease'
    },
    simpleKeyboardShow: {
      width: '70%',
      position: 'absolute',
      left: '14%',
      right: '0%',
      zIndex: 999,
      bottom: '20%',
      opacity: '1',
      visibility: 'visible',
      transition: 'all 0.3s ease'
    },
    PatientInfoTable: {
      border: 'none'
    },
    PatientInfoTableRow: {
      height: '30px'
    },
    PatientInfoTableEmptyCell: {
      width: '70px',
      padding: '0px 0px 0px 0px',
      border: 'none'
    },
    PatientInfoTableFilledCell: {
      fontSize: '1rem',
      fontWeight: '500',
      width: '90px',
      padding: '0px 0px 0px 0px',
      border: 'none'
    },
    cellBorder: {
      border: 'none'
    },
    flexItem: {
      cursor: 'pointer',
      padding: '10px 0px'
    },
    flexItemText: {
      display: 'flex',
      width: '30vh',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '15px',
      cursor: 'pointer',
      textAlign: 'center',
      padding: '10px 0px'
    },
    flexItemToggle: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '15px',
      cursor: 'pointer',
      textAlign: 'center'
    },
    textBoxItem: {
      borderRadius: '15px',
      cursor: 'pointer',
      width: '20vh'
    },
    imgProperty: {
      height: 50,
      width: 50
    },
    signOutButton: {
      float: 'right',
      marginRight: '-10px',
      marginLeft: '25px',
      color: '#115E67'
    },
    iOSSwitchBase: {
      '&$iOSChecked': {
        color: theme.palette.common.white,
        '& + $iOSBar': {
          backgroundColor: '#52d869'
        }
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp
      })
    },
    iOSChecked: {
      transform: 'translateX(15px)',
      '& + $iOSBar': {
        opacity: 1,
        border: 'none'
      }
    },
    iOSBar: {
      borderRadius: 13,
      width: 42,
      height: 26,
      marginTop: -13,
      marginLeft: -21,
      border: 'solid 1px',
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    iOSIcon: {
      width: 24,
      height: 24
    },
    iOSIconChecked: {
      boxShadow: theme.shadows[1]
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC'
    },
    expansingDetails: {
      display: 'flex !important',
      padding: '8px 24px 24px !important',
      justifyContent: 'flex-start !important',
      alignItems: 'center !important'
    },
    heading: {
      fontSize: '0.9rem',
      fontWeight: '500'
    },
    labelHeading: {
      fontSize: '0.9rem',
      fontWeight: '500'
    },
    table: {
      width: '100%'
    },
    tableRow: {
      borderBottom: '1px solid black'
    },
    tableIcon: {
      color: '#000'
    },
    tableCell: {
      padding: '4px 16px 4px 24px !important'
    },
    tableCell1: {
      padding: '4px 16px 4px 24px !important',
      width: '36vw'
    },
    tableCell2: {
      padding: '4px 16px 4px 24px !important',
      width: '8vw'
    },
    expandedTable: {
      overflow: 'auto',
      maxHeight: '200px'
    }
  };
};

export default styles;
