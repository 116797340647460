const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center !important'
    },
    formContainer: {
      display: 'grid',
      gridGap: '1em',
      alignItems: 'center'
    },
    appLogoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100px'
    },
    appLogo: {
      width: '140px'
    },
    appName: {
      fontSize: '2em',
      margin: '9px'
    },
    title: {
      fontSize: '1.5em',
      color: '#2F5597',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'grid',
      marginTop: '50px'
    },
    form: {
      width: '100%'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    primaryButtonNewpassword: {
      marginTop: '1em',
      color: '#fff',
      fontSize: '20px',
      width: '100%',
      background: ' #2F5597',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: ' #2F5597'
      }
    },
    backButton: {
      marginTop: '1em',
      fontSize: '18px',
      width: '40%',
      height: '40px',
      lineHeight: '20px',
      background: '#fff',
      color: '#aaa',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#aaa'
      }
    },
    formLinkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    codeGrid: {
      display: 'grid',
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '1em'
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      position: 'absolute',
      width: '80%',
      bottom: '10%',
      left: '12%'
    },
    invalid: {
      color: 'red'
    },
    valid: {
      color: 'green'
    },
    disabledPrimaryButtonNewpassword: {
      width: '100%',
      marginTop: '1em',
      fontSize: '20px'
    },
    Instructiontitle: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center',
      margin: '10px',
      marginBottom: '15px'
    },
    leftFormContent: {
      width: '50%'
    },
    simpleKeyboard: {
      width: '70%',
      position: 'absolute',
      left: '17%',
      right: '0%',
      zIndex: 999,
      bottom: '18vh'
    },
    primaryButton: {
      marginTop: '1em',
      fontSize: '18px',
      width: '40%',
      height: '40px',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)'
    },
    disabledPrimaryButton: {
      fontSize: '18px',
      width: '40%',
      height: '40px',
      marginTop: '1em'
    },
    formFooter: {
      flexDirection: 'row',
      position: 'absolute',
      bottom: '12px',
      left: '0',
      right: '0',
      width: '700px',
      margin: '0 auto'
    }
  };
};

export default styles;
