// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './AdminPanelForm.styles';
import AdminPanelFormControls from './AdminPanelFormControls';
import { injectIntl } from 'react-intl';

export class AdminPanelForm extends Component {
  closeApp() {
    window.close();
  }

  render() {
    const { classes, box } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminPanelFormControls closeApp={this.closeApp} box={box} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelForm)
);
