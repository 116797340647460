// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import styles from './AdminSideSlides.styles';

export class AdminSideSlides extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      language: ''
    };
  }

  componentDidMount() {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'en') {
      this.setState({ language: 'en' });
    } else {
      this.setState({ language: 'es' });
    }
  }

  render() {
    const { classes, pageTitle, subTitle } = this.props;
    return (
      <div className={classNames(classes.myContent)}>
        <div
          className={classNames(
            this.state && this.state.language === 'en'
              ? classes.subtitle
              : classes.subtitleEs
          )}
        >
          {pageTitle}
          <p style={{ color: 'black', margin: '3px', fontSize: '24px' }}>
            {subTitle}
          </p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminSideSlides)
);
