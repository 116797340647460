import { connect } from 'react-redux';
import AdminPanelForm from './AdminPanelForm';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const AdminPanelFormContainer = connect(mapStateToProps)(AdminPanelForm);

export default AdminPanelFormContainer;
