import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './box-layout.style';
import SwipeableViews from 'react-swipeable-views';
import Pagination from '../../Common/Pagination';
import MainUnitLayout from './Mainunit-layout';
import CoolLayout from './Cool-layout';
import Hd36Layout from './HD36-layout.js';
import L60Layout from './l60-layout.js';
import M22Layout from './Main22unit-layout';
import M24Layout from './Main24unit-layout';
import BOX_TYPES from '../../Common/box_type_enum';

let deactivatedBin = [];
let selectedTabIndex = 0;
let currentTabIndex = 0;
let IsTabSelect = false;

export class boxLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUnit: this.props.currentTabIndex
    };
  }
  handleChangeIndex = (index) => {
    this.setState({
      selectedUnit: index
    });
    selectedTabIndex = index;
    IsTabSelect = true;
    this.props.getSelectedUnitName(index);
  };

  componentDidMount() {
    IsTabSelect = false;
    this.setState({
      selectedUnit: this.props.currentTabIndex
    });
    currentTabIndex = this.props.currentTabIndex;
  }
  componentWillReceiveProps() {
    currentTabIndex =
      IsTabSelect === false ? this.props.currentTabIndex : selectedTabIndex;
    this.setState({
      selectedUnit: currentTabIndex
    });
    setTimeout(() => {
      currentTabIndex =
        IsTabSelect === false ? this.props.currentTabIndex : selectedTabIndex;
      this.setState({
        selectedUnit: currentTabIndex
      });
    }, 1000);
    const { deactivatedBins } = this.props;
    if (deactivatedBins && deactivatedBins.length > 0) {
      deactivatedBins.map((item) => {
        return true;
      });
    } else {
      deactivatedBin.map((item) => {
        return true;
      });
      deactivatedBin = [];
    }
  }
  Selectedbin = (binId, position) => {
    this.props.getSelectedBin(binId, position);
  };

  getExpansionUnitsByPos = (position) => {
    const { expansionUnits } = this.props;
    return expansionUnits.filter((x) => x.pos === position);
  };

  getExpansionsCounts = () => {
    let boxLeftExpansionObjectArray = [];
    let boxRightExpansionObjectArray = [];
    let RightAndLeftExpansions = {};
    const { expansionUnits } = this.props;
    for (let unit = 0; unit < expansionUnits.length; unit++) {
      if (expansionUnits[unit].box_position < 0) {
        let expansionObj = {
          expansionId: expansionUnits[unit].id,
          box_position: Number(expansionUnits[unit].box_position),
          bin_column_count: expansionUnits[unit].bin_column_count
        };
        if (
          expansionObj &&
          expansionObj.expansionId &&
          expansionObj.box_position !== 0
        ) {
          boxLeftExpansionObjectArray.push(expansionObj);
        }
      } else {
        let expansionObj = {
          expansionId: expansionUnits[unit].id,
          box_position: Number(expansionUnits[unit].box_position),
          bin_column_count: expansionUnits[unit].bin_column_count
        };
        if (
          expansionObj &&
          expansionObj.expansionId &&
          expansionObj.box_position !== 0
        ) {
          boxRightExpansionObjectArray.push(expansionObj);
        }
      }
    }
    RightAndLeftExpansions.leftExpansions =
      boxLeftExpansionObjectArray.reverse();
    RightAndLeftExpansions.rightExpansions = boxRightExpansionObjectArray;
    return RightAndLeftExpansions;
  };

  render() {
    const { selectedUnit } = this.state;
    const { expansionUnits, boxes } = this.props;
    const Expansions = this.getExpansionsCounts();
    const boxTypeId =
      boxes && boxes !== null && boxes !== undefined && boxes.type_id;
    return (
      <div key="boxInnerLayout" style={{ width: 580 }}>
        <SwipeableViews
          index={selectedUnit}
          onChangeIndex={this.handleChangeIndex}
        >
          {expansionUnits &&
            expansionUnits !== undefined &&
            expansionUnits.length > 0 &&
            expansionUnits.map((item, index) => {
              let getExpansionUnits = this.getExpansionUnitsByPos(item.pos);
              if (item.pos === 'L') {
                if (item && item.expansion_id === BOX_TYPES.L_60) {
                  return (
                    <L60Layout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                } else if (item && item.expansion_id === BOX_TYPES.HD_36) {
                  return (
                    <Hd36Layout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                } else if (item && item.expansion_id === BOX_TYPES.COOL_8) {
                  return (
                    <CoolLayout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                }
              }
              if (item.pos === 'M') {
                return (
                  <MainUnitLayout
                    key={index}
                    expansion={item}
                    prefix={item.pos}
                    box_position={item.box_position}
                    Selectedbin={this.Selectedbin}
                    BinStatus={this.props.BinStatus}
                    deactivatedBins={this.props.deactivatedBins}
                    PatientID={this.props.PatientID}
                    ADAData={this.props.ADAData}
                  />
                );
              } else if (item.pos === 'M22') {
                return (
                  <M22Layout
                    key={index}
                    expansion={item}
                    prefix={item.pos}
                    box_position={item.box_position}
                    Selectedbin={this.Selectedbin}
                    BinStatus={this.props.BinStatus}
                    deactivatedBins={this.props.deactivatedBins}
                    PatientID={this.props.PatientID}
                    ADAData={this.props.ADAData}
                  />
                );
              } else if (item.pos === 'M24') {
                return (
                  <M24Layout
                    key={index}
                    expansion={item}
                    prefix={item.pos}
                    box_position={item.box_position}
                    Selectedbin={this.Selectedbin}
                    BinStatus={this.props.BinStatus}
                    deactivatedBins={this.props.deactivatedBins}
                    PatientID={this.props.PatientID}
                    ADAData={this.props.ADAData}
                  />
                );
              }
              if (item.pos === 'R') {
                if (item && item.expansion_id === BOX_TYPES.L_60) {
                  return (
                    <L60Layout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                } else if (item && item.expansion_id === BOX_TYPES.HD_36) {
                  return (
                    <Hd36Layout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                } else if (item && item.expansion_id === BOX_TYPES.COOL_8) {
                  return (
                    <CoolLayout
                      key={index}
                      expansion={item}
                      prefix={item.pos + item.selectedUnitIndex}
                      box_position={item.box_position}
                      Selectedbin={this.Selectedbin}
                      BinStatus={this.props.BinStatus}
                      getPrescriptionDetails={this.props.getPrescriptionDetails}
                      previousExpansion={
                        getExpansionUnits[
                          (index + getExpansionUnits.length - 1) %
                            getExpansionUnits.length
                        ]
                      }
                      ExpansionsCounts={Expansions}
                      boxTypeId={boxTypeId}
                    />
                  );
                }
              }
              return true;
            })}
        </SwipeableViews>
        <Pagination
          dots={
            expansionUnits &&
            expansionUnits !== undefined &&
            expansionUnits.length > 0
              ? expansionUnits.length === 1
                ? 0
                : expansionUnits && expansionUnits.length
              : 0
          }
          index={selectedUnit}
          onChangeIndex={this.handleChangeIndex}
        />
      </div>
    );
  }
}

export default withStyles(styles)(boxLayout);
