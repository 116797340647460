import axios from 'axios';
import axiosRetry from 'axios-retry';
const { WritePickupInfo, readSettings } = require('../../helpers/board');
const { DiffSecondsToMinutes } = require('./seconds_to_minutes');
const Cryptr = require('cryptr');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);
const QueueCryptr = require('cryptr');
const _queueCipher = process.env.REACT_APP_QUEUE_CIPHER_KEY;
const _queueCryptr = new QueueCryptr(_queueCipher);
axiosRetry(axios, { retries: 3 });
axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 3000;
  }
});

export const UpdateStatusOfRx = (payload) => {
  let log = {
    message: `Kiosk Door Layout Screen : Update Status of Rx API called with payload : ${JSON.stringify(
      payload
    )}`
  };
  writePickupLogsToFile(log);
  try {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    axios({
      method: payload.type,
      withCredentials: false,
      url: payload.url,
      data: JSON.stringify(payload.body.updateStatusOfRxObj),
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then((RxObj) => {
        let log = {
          message: `Kiosk Door Layout Screen : Getting response from update status of Rx : ${JSON.stringify(
            RxObj
          )}`
        };
        writePickupLogsToFile(log);
        updatePickupSession();
      })
      .catch((error) => {
        let logError = {
          message: `Kiosk Door Layout Screen : Error from update status of Rx : ${error} - ${JSON.stringify(
            error
          )}`
        };
        writePickupLogsToFile(logError);
        if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
          UpdateStatusOfRx(payload);
        } else {
          const encryptedQueue = localStorage.getItem('queue') || '[]';
          let queueArray;
          if (encryptedQueue !== '[]') {
            const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
            queueArray = JSON.parse(decryptedQueue);
          } else {
            queueArray = JSON.parse(encryptedQueue);
          }
          let updateStatusOfRxObj = {
            url: process.env.REACT_APP_BASEURL + 'updateStatusOfRx',
            type: 'POST',
            name: 'updateStatusOfRx',
            body: { payload }
          };
          queueArray.push({ payload: updateStatusOfRxObj });
          const encryptedQueyeObject = _queueCryptr.encrypt(
            JSON.stringify(queueArray)
          );
          localStorage.setItem('queue', encryptedQueyeObject);
        }
      });
  } catch (Exception) {
    let log = {
      message: `Kiosk Door Layout Screen : Getting error from update status of Rx : ${JSON.stringify(
        Exception
      )}`
    };
    writePickupLogsToFile(log);
    if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      UpdateStatusOfRx(payload);
    } else {
      const encryptedQueue = localStorage.getItem('queue') || '[]';
      let queueArray;
      if (encryptedQueue !== '[]') {
        const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
        queueArray = JSON.parse(decryptedQueue);
      } else {
        queueArray = JSON.parse(encryptedQueue);
      }
      let updateStatusOfRxObj = {
        url: process.env.REACT_APP_BASEURL + 'updateStatusOfRx',
        type: 'POST',
        name: 'updateStatusOfRx',
        body: { payload }
      };
      queueArray.push({ payload: updateStatusOfRxObj });
      const encryptedQueyeObject = _queueCryptr.encrypt(
        JSON.stringify(queueArray)
      );
      localStorage.setItem('queue', encryptedQueyeObject);
    }
  }
};

export const sendMail = (payload) => {
  let pickupConfig = payload.body.pickupConfig;
  let tempConfigObj = { pickupConfig };
  let log = {
    message: `Kiosk Door Layout Screen : Send an email API called with payload : ${JSON.stringify(
      payload
    )}`
  };
  writePickupLogsToFile(log);
  try {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(payload.url, {
      method: payload.type,
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(tempConfigObj)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then((emailObj) => {
        let log = {
          message: `Kiosk Door Layout Screen : Getting response from Send an email API : ${JSON.stringify(
            emailObj
          )}`
        };
        writePickupLogsToFile(log);
      })
      .catch((error) => {
        let log = {
          message: `Kiosk Door Layout Screen : Getting error from Send an email API : ${JSON.stringify(
            error
          )}`
        };
        writePickupLogsToFile(log);
        if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
          sendMail(payload);
        } else {
          let senddata = {
            url: process.env.REACT_APP_BASEURL + 'sendEmail',
            type: 'POST',
            name: 'sendEmail',
            body: { payload }
          };
          const encryptedQueue = localStorage.getItem('queue') || '[]';
          let queueArray;
          if (encryptedQueue !== '[]') {
            const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
            queueArray = JSON.parse(decryptedQueue);
          } else {
            queueArray = JSON.parse(encryptedQueue);
          }
          queueArray.push({ payload: senddata });
          const encryptedQueyeObject = _queueCryptr.encrypt(
            JSON.stringify(queueArray)
          );
          localStorage.setItem('queue', encryptedQueyeObject);
        }
      });
  } catch (Exception) {
    let log = {
      message: `Kiosk Door Layout Screen : Getting error from Send an email API : ${JSON.stringify(
        Exception
      )}`
    };
    writePickupLogsToFile(log);
    if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      sendMail(payload);
    } else {
      let senddata = {
        url: process.env.REACT_APP_BASEURL + 'sendEmail',
        type: 'POST',
        name: 'sendEmail',
        body: { payload }
      };
      const encryptedQueue = localStorage.getItem('queue') || '[]';
      let queueArray;
      if (encryptedQueue !== '[]') {
        const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
        queueArray = JSON.parse(decryptedQueue);
      } else {
        queueArray = JSON.parse(encryptedQueue);
      }
      queueArray.push({ payload: senddata });
      const encryptedQueyeObject = _queueCryptr.encrypt(
        JSON.stringify(queueArray)
      );
      localStorage.setItem('queue', encryptedQueyeObject);
    }
  }
};

export const syncPatientLifBoatWithCould = async (localPatientObject) => {
  let base64Picture = null;
  let base64Signature = null;
  let orderIds = [];
  orderIds.push({
    id: localPatientObject.OrderID
  });
  const token = localStorage.getItem('authTokens');
  const actualToken = atob(token);
  if (
    localPatientObject.picture_url !== null &&
    localPatientObject.picture_url !== '' &&
    localPatientObject.picture_url !== undefined
  ) {
    base64Picture = localPatientObject.picture_url.replace(
      /^data:image\/\w+;base64,/,
      ''
    );
  }
  if (
    localPatientObject.signature_url !== null &&
    localPatientObject.signature_url !== '' &&
    localPatientObject.signature_url !== undefined
  ) {
    base64Signature = localPatientObject.signature_url.replace(
      /^data:image\/\w+;base64,/,
      ''
    );
  }
  let data = new FormData();
  data.append('customer_id', localPatientObject.CustomerID);
  data.append('order_id', localPatientObject.OrderID);
  data.append('picture_url', base64Picture);
  data.append('signature_url', base64Signature);
  data.append('first_name', localPatientObject.FirstName);
  data.append('middle_name', null);
  data.append('last_name', localPatientObject.LastName);
  data.append(
    'dob',
    localPatientObject.dateOfBirth || localPatientObject.DateOfBirth
  );
  data.append('box_id', localPatientObject.BoxID);
  data.append('account_id', localPatientObject.AccountID);
  data.append('orderIds', JSON.stringify(orderIds));
  data.append('is_consult_requested', localPatientObject.isConsultRequested);
  try {
    fetch(process.env.REACT_APP_BASEURL + 'lifeboat/save/patient/details', {
      method: 'POST',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken
      },
      body: data
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(() => {})
      .catch((error) => {
        console.log('syncPatientLifBoatWithCould failed: ', { error });
        if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
          syncPatientLifBoatWithCould(data);
        } else {
          let syncLifeboatObject = {
            url:
              process.env.REACT_APP_BASEURL + 'lifeboat/save/patient/details',
            type: 'POST',
            name: 'synclifeboat_kiosk_Confirm_Pikup',
            body: data
          };
          const encryptedQueue = localStorage.getItem('queue') || '[]';
          let queueArray;
          if (encryptedQueue !== '[]') {
            const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
            queueArray = JSON.parse(decryptedQueue);
          } else {
            queueArray = JSON.parse(encryptedQueue);
          }
          queueArray.push({ payload: syncLifeboatObject });
          const encryptedQueyeObject = _queueCryptr.encrypt(
            JSON.stringify(queueArray)
          );
          localStorage.setItem('queue', encryptedQueyeObject);
        }
      });
  } catch (Exception) {
    console.log(
      `Error while saving DL Capture events : ${JSON.stringify(Exception)}`
    );
    if ([true, 'true'].includes(localStorage.getItem('isKioskOnline'))) {
      syncPatientLifBoatWithCould(data);
    } else {
      let syncLifeboatObject = {
        url: process.env.REACT_APP_BASEURL + 'lifeboat/save/patient/details',
        type: 'POST',
        name: 'synclifeboat_kiosk_Confirm_Pikup',
        body: data
      };
      const encryptedQueue = localStorage.getItem('queue') || '[]';
      let queueArray;
      if (encryptedQueue !== '[]') {
        const decryptedQueue = _queueCryptr.decrypt(encryptedQueue);
        queueArray = JSON.parse(decryptedQueue);
      } else {
        queueArray = JSON.parse(encryptedQueue);
      }
      queueArray.push({ payload: syncLifeboatObject });
      const encryptedQueyeObject = _queueCryptr.encrypt(
        JSON.stringify(queueArray)
      );
      localStorage.setItem('queue', encryptedQueyeObject);
    }
  }
};

export const writePickupLogsToFile = (log) => {
  if (window && window.electron) {
    const fileData = JSON.parse(readSettings());
    let EnableLifeboat = fileData ? fileData.EnableLifeboat : false;
    if (EnableLifeboat) {
      let logObject = {
        timestamp: new Date(),
        message: log.message
      };
      WritePickupInfo(logObject);
    }
  }
};

export const sendAlarmMessageForOutOfSession = () => {
  let date = new Date().toLocaleDateString();
  let time = new Date().toLocaleTimeString();
  let _doorOpenDate = `${date} ${time}`;
  const fileData = JSON.parse(readSettings());
  let boxId = fileData ? fileData.boxId : '';
  let obj = {
    boxId: boxId,
    doorOpenDate: _doorOpenDate
  };
  try {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'session/alarm', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(() => {});
  } catch (Exception) {
    console.log(`Error while sending alarm : ${JSON.stringify(Exception)}`);
  }
};

export const updatePickupSession = () => {
  const token = localStorage.getItem('authTokens');
  const actualToken = atob(token);
  const _checkIfSessionIsActiveOrNot = localStorage.getItem('ActiveSession');
  if (_checkIfSessionIsActiveOrNot) {
    let decryptedSessionObject = _cryptr.decrypt(_checkIfSessionIsActiveOrNot);
    const parsedDecryptedObject = JSON.parse(decryptedSessionObject);
    const session_id = parsedDecryptedObject._id;
    const start_date = parsedDecryptedObject._start_date;
    const diffSecondsDuration = DiffSecondsToMinutes(
      new Date(start_date),
      new Date()
    );
    const obj = {
      _id: session_id,
      _session_active: false,
      _end_date: new Date(),
      _duration: diffSecondsDuration
    };
    parsedDecryptedObject._session_active = false;
    parsedDecryptedObject._end_date = new Date();
    fetch(process.env.REACT_APP_BASEURL + 'sessionhistory', {
      method: 'PUT',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then((res) => {
        localStorage.removeItem('last_bin_id');
        let log = {
          message: `Kiosk Date Of Birth Screen : Session has been ended with details ${JSON.stringify(
            res
          )}`
        };
        writePickupLogsToFile(log);
      })
      .catch(() => {});
  }
};

export const writeKioskLog = (audit_obj) => {
  const token = localStorage.getItem('authTokens');
  const actualToken = atob(token);

  const res = fetch(process.env.REACT_APP_BASEURL + 'auditreport', {
    method: 'POST',
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + actualToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(audit_obj)
  }).then((response) => {
    try {
      return response.json();
    } catch (error) {
      return null;
    }
  });
  return res;
};
