import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminPanelStockFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
// import { openBin, openMiniBins } from '../../../helpers/board';
import AWSconfig from '../../../api/aws/awsConfig';
import AWS from 'aws-sdk';
import ASSIGN from '../../KioskLayout/assignmentStatus';
import EVENT_TYPES from '../../Common/event-type';
import keyboardImg from '../../AdminLayout/keyboard.png';
import { readSettings, forceBinStatusCheck } from '../../../helpers/board';
import io from 'socket.io-client';
import { writeKioskLog } from '../../Common/SyncAPIs';

const messages = defineMessages({
  ValidStockCode: {
    id: 'Admin.Stock.ValidStockCode',
    defineMessages: 'Please enter valid stock code.'
  },
  ScanOrEnterStockCode: {
    id: 'Admin.Stock.ScanOrEnterStockCode',
    defineMessages: 'Scan or Enter your code here'
  },
  Patient: {
    id: 'Admin.Stock.Patient',
    defineMessages: 'Patient'
  },
  Back: {
    id: 'Admin.Stock.Back',
    defineMessages: 'Back'
  },
  Load: {
    id: 'Admin.Stock.Load',
    defineMessages: 'Load'
  },
  StockConfirmation: {
    id: 'Admin.Stock.StockConfirmation',
    defineMessages: 'Stock Confirmation'
  },
  LoadedCode: {
    id: 'Admin.Stock.LoadedCode',
    defineMessages: 'You have loaded code '
  },
  OK: {
    id: 'Admin.Stock.OK',
    defineMessages: 'OK'
  },
  PleaseCloseDoor: {
    id: 'Admin.Stock.PleaseCloseDoor',
    defineMessages: 'Please close door '
  },
  BeforeContinue: {
    id: 'Admin.Stock.BeforeContinue',
    defineMessages: 'before continuing.'
  },
  DoorConfirmation: {
    id: 'Admin.Stock.DoorConfirmation',
    defineMessages: 'Door Confirmation'
  },
  statusCheckmessage: {
    id: 'Admin.Stock.statusCheckmessage',
    defineMessages: 'Safety checks in progress...'
  },
  StatusCheck_Stock: {
    id: 'Admin.Stock.StatusCheck_Stock',
    defineMessages:
      'This order is no longer available for stocking. Please return it to the pharmacy.'
  },
  InvoiceNumber: {
    id: 'Retail.Stock.InvoiceNumber',
    defineMessages: 'Invoice Number'
  },
  ItemName: {
    id: 'Retail.Stock.ItemName',
    defineMessages: 'Item Name'
  },
  TotalItem: {
    id: 'Retail.Stock.TotalItem',
    defineMessages: 'Total Item'
  }
});

// AWS Credentials
AWS.config.update({
  accessKeyId: AWSconfig.accessKeyId,
  secretAccessKey: AWSconfig.secretAccessKey,
  region: AWSconfig.region
});

export class AdminPanelFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patientDetails: [],
      pickupCode: '',
      displayMsg: '',
      maxlength: 6,
      layoutName: 'default',
      SelectedInput: 'pickupCode',
      puCode: '',
      showKeyboard: false,
      binIds: '',
      openDoorDialog: false,
      isStatusCheckFailed: false,
      progressMessage: '',
      boxTypeId: '',
      isFastoInProcess: false
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }

  initSocket = async () => {
    const token = JSON.parse(sessionStorage.getItem('user')).signInUserSession
      .idToken.jwtToken;
    if (!this.socket && token) {
      /**
       ** Socket Initialization **
       */

      this.socket = io(
        `${process.env.REACT_APP_VIDEO_SOCKET_BASEURL}/console-socket`,
        {
          transports: ['websocket'],
          auth: {
            token: `Bearer ${token}`
          },
          secure: true
        }
      );
      console.log(
        '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@     Socket init from AdminPanelStock.js      @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@'
      );
      /**
       ** Internal Socket Event **
       */

      this.socket &&
        this.socket.on('connect', () => {
          console.log('Socket connection established from PORTAL(ReConnect)');
        });
      this.socket &&
        this.socket.on('connect_error', (error) => {
          console.log('Connect Error: ', error);
          if (
            error.message === 'TokenExpiredError' ||
            error.message === 'JsonWebTokenError' ||
            error.message === 'NoTokenFound'
          ) {
            console.log('Token issue!');
          }
        });
      this.socket &&
        this.socket.on('connect_failed', (error) => {
          console.log('Connect Failed: ', error);
        });
      this.socket &&
        this.socket.on('error', (error) => {
          console.log('Error: ', error);
        });
      this.socket &&
        this.socket.on('disconnect', (reason) => {
          console.log(
            `Socket Disconnected From Portal and Reason is: ${reason}`
          );
        });
    }
  };

  async componentDidMount() {
    const _boxTypeId = this.props && this.props.box && this.props.box.type_id;
    this.setState({ boxTypeId: _boxTypeId });
    !this.socket && (await this.initSocket());
  }

  componentWillUnmount() {
    this.socket && this.socket.disconnect();
    this.socket = false;
  }

  change = (event) => {
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
      let isHWCommuniocationEnabled = fileData
        ? fileData.isHWCommuniocationEnabled
        : true;
      if (isHWCommuniocationEnabled) {
        forceBinStatusCheck();
      }
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    const pickupcode = event.target.value;
    if (this.props && this.props.box) {
      let customer;
      if (mode === false || mode === null) {
        if (this.props && this.props.box && this.props.box.customers) {
          customer = this.props.box.customers.filter(
            (x) =>
              x &&
              x.orders &&
              x.orders.filter(
                (y) =>
                  y &&
                  y.stock_code.toString().toLowerCase() ===
                    pickupcode.toString().toLowerCase()
              ).length > 0
          )[0];
        }
      } else {
        if (this.props && this.props.box && this.props.box.customers) {
          customer = this.props.box.customers.filter(
            (x) =>
              x &&
              x.orders &&
              x.orders.filter(
                (y) =>
                  y &&
                  y.stock_code.toString().toLowerCase() ===
                    pickupcode.toString().toLowerCase() &&
                  y.type_id === ASSIGN.ASSIGNED //same Stock code bug after pickedup.  y.type_id !== ASSIGN.STOCKED
              ).length > 0
          )[0];
        }
      }

      let filteredArray = [];
      let order;
      if (customer) {
        if (mode === false || mode === null) {
          order = customer.orders.filter(
            (x) =>
              x &&
              x.stock_code.toString().toLowerCase() ===
                pickupcode.toString().toLowerCase()
          );
        } else {
          order = customer.orders.filter(
            (x) =>
              x &&
              x.stock_code.toString().toLowerCase() ===
                pickupcode.toString().toLowerCase() &&
              x.type_id !== ASSIGN.STOCKED
          );
        }
        customer.orders = order;
        filteredArray = [customer];
        if (order.length > 0) {
          this.setState({ showKeyboard: false });
        }
        this.setState({ puCode: order[0].pucode.toString().toUpperCase() });
      }
      this.setState({
        patientDetails: filteredArray,
        pickupCode: pickupcode,
        displayMsg:
          this.props && this.props.intl.formatMessage(messages.ValidStockCode)
      });
    } else {
      this.setState({
        patientDetails: [],
        pickupCode: '',
        displayMsg:
          this.props && this.props.intl.formatMessage(messages.ValidStockCode)
      });
    }
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  handleClose = () => {
    if (this.props && this.props.box && this.props.box.customers) {
      document.querySelector('#' + this.state.SelectedInput).value = '';
      this.setState({ open: false, patientDetails: [], displayMsg: '' });
      setTimeout(() => {
        this.textInput.current.focus();
      }, 650);

      this.keyboard.clearInput();
    }
  };

  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  renderPatientName() {
    const patient = this.state && this.state.patientDetails[0];
    return patient.email;
  }

  renderCount() {
    const patient = this.state && this.state.patientDetails[0];
    const lengthCount = patient && patient.orders.length;
    return lengthCount;
  }
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }

  handleLoad = async () => {
    const updateStatusOfObj = {
      event_type_name: EVENT_TYPES.STOCKED,
      type_id: ASSIGN.STOCKED,
      box_id: this.props.box.id,
      bin_id: this.state.patientDetails[0].orders[0].bin_id
    };

    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,
      box_id: this.props.box.id,
      description:
        'Prescription was stocked with stock code ' +
        this.state.patientDetails[0].orders[0].stock_code
    };

    this.setState({ open: true });
    fetch(process.env.REACT_APP_BASEURL + 'apis/i-ecom/status', {
      method: 'put',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
            .jwtToken
      },
      body: JSON.stringify(updateStatusOfObj)
    }).then((response) => {
      if (this.props.box && this.props.box.is_kiosk_log) {
        writeKioskLog(audit_obj);
      }
      try {
        return response.json();
      } catch (error) {
        return null;
      }
    });
  };

  render() {
    const { classes, handleBack } = this.props;
    const { patientDetails, pickupCode, displayMsg } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div>
        <div className={classNames(classes.container)}>
          <MuiThemeProvider theme={theme}>
            <TextField
              style={{ textAlign: 'center' }}
              id="pickupCode"
              name="pickupCode"
              className={classes.textField}
              defaultValue=""
              margin="normal"
              variant="outlined"
              placeholder={
                this.props &&
                this.props.intl.formatMessage(messages.ValidStockCode)
              }
              inputProps={{ maxLength: 6 }}
              autoComplete="off"
              onChange={(e) => this.change(e)}
              autoFocus
              inputRef={this.textInput}
              onFocus={this.Focus.bind('pickupCode')}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  this.change(ev);
                }
              }}
            />
          </MuiThemeProvider>
          {this.state && this.state.patientDetails.length > 0 ? (
            <div>
              <Table className={classNames(classes.PatientInfoTable)}>
                <TableBody>
                  <TableRow className={classes.PatientInfoTableRow}>
                    <TableCell
                      className={classes.PatientInfoTableFilledCell}
                      style={{ width: '18%' }}
                    >
                      {this.props &&
                        this.props.intl.formatMessage(messages.Patient)}
                    </TableCell>
                    <TableCell
                      className={classes.cellBorder}
                      style={{ width: '42%' }}
                    >
                      {this.renderPatientName()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className={classNames(classes.PatientInfoTable)}>
                <TableHead className={classNames(classes.TableHead)}>
                  <TableRow>
                    <TableCell
                      className={classNames(
                        classes.CodeClass,
                        classes.cellBorder
                      )}
                    >
                      {this.props &&
                        this.props.intl.formatMessage(messages.InvoiceNumber)}
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.PrescriptionClass,
                        classes.cellBorder
                      )}
                    >
                      {this.props &&
                        this.props.intl.formatMessage(messages.ItemName)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientDetails &&
                    patientDetails[0].orders.map((item) => {
                      return (
                        <TableRow
                          className={classes.PatientInfoTableRow}
                          key={item.invoice_id}
                        >
                          <TableCell className={classNames(classes.fontSizes)}>
                            {item.invoice_id}
                          </TableCell>
                          <TableCell className={classNames(classes.fontSizes)}>
                            {item.item}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className={classNames(classes.Instructiontitle)}>
              {displayMsg && displayMsg}
            </div>
          )}
          {this.state && this.state.patientDetails.length > 0 && (
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableBody>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell
                    colSpan={5}
                    className={classNames(
                      classes.PatientInfoTableEmptyCell,
                      classes.cellBorderInfo
                    )}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(messages.TotalItem)}
                    ({this.renderCount()})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>

        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            {this.props && this.props.intl.formatMessage(messages.Back)}
          </Button>
          <img
            src={keyboardImg}
            className={classNames(classes.keyboardImg)}
            alt=""
            onClick={this.keyboardHandler}
          />
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={this.handleLoad}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            {this.props && this.props.intl.formatMessage(messages.Load)}
          </Button>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.StockConfirmation)}
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                {this.props &&
                  this.props.intl.formatMessage(messages.LoadedCode)}{' '}
                {pickupCode.toUpperCase()}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleClose}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props && this.props.intl.formatMessage(messages.OK)}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelFormControls)
);
