const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      gridGap: '1em',
      alignItems: 'center'
    },
    primaryButton: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
      width: '20%',
      fontSize: '20px',
      color: '#fff'
    },
    beforeStockBinClass: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #ffeb00 30%, #afb127 90%)',
      width: '20%',
      fontSize: '20px',
      color: '#000'
    },
    disabledPrimaryButton: {
      marginTop: '1em',
      width: '20%',
      fontSize: '20px'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: '#005d67',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #005d67',
      cursor: 'pointer'
    },
    codeGrid: {
      display: 'grid',
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    RemoveIcon: {
      width: '60px',
      padding: '0'
    },
    PatientClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    CodeClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    PrescriptionClass: {
      width: '150px',
      color: '#50A684',
      fontSize: '1.2em',
      padding: '0'
    },
    TableHead: {
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    fontSizes: {
      fontSize: '1em',
      padding: '0'
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'absolute',
      width: '82%',
      bottom: '10%',
      left: '9%'
    },
    cellBorderInfo: {
      borderBottom: '1px solid #fff'
    },
    dialogBackground: {
      background: 'transperent'
    },
    dialogContentColor: {
      color: '#77818D',
      textAlign: 'center',
      fontSize: '1.9em',
      fontWeight: '500'
    },
    dialogTitleColor: {
      color: '#77818D',
      textAlign: 'left',
      fontSize: '1.5em'
    },
    alignButton: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    buttonColor: {
      color: 'black',
      fontSize: '1.9em'
    },
    messageInfo: {
      textAlign: 'center'
    },
    Instructiontitle: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center'
    },
    simpleKeyboard: {
      width: '100%',
      position: 'absolute',
      left: '0%',
      right: '0%',
      zIndex: 999,
      bottom: '2%',
      opacity: '0',
      visibility: 'hidden',
      transition: 'all 0.3s ease'
    },
    simpleKeyboardShow: {
      width: '70%',
      position: 'absolute',
      left: '14%',
      right: '0%',
      zIndex: 999,
      bottom: '20%',
      opacity: '1',
      visibility: 'visible',
      transition: 'all 0.3s ease'
    },
    PatientInfoTable: {
      border: 'none'
    },
    PatientInfoTableRow: {
      height: '30px'
    },
    PatientInfoTableEmptyCell: {
      width: '70px',
      padding: '0px 0px 0px 0px',
      border: 'none'
    },
    PatientInfoTableFilledCell: {
      fontSize: '1rem',
      fontWeight: '500',
      width: '90px',
      padding: '0px 0px 0px 0px',
      border: 'none'
    },
    cellBorder: {
      border: 'none'
    },
    classLoad: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #417ec3 30%, #6db1ff 90%)',
      width: '20%',
      fontSize: '20px',
      color: '#fff'
    },
    classLoading: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #3b9d44 30%, #54c45e 90%)',
      width: '20%',
      fontSize: '20px',
      color: '#fff'
    },
    classLoaded: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #fc9432 30%, #f79e49 90%)',
      width: '20%',
      fontSize: '20px',
      color: '#fff'
    },
    keyboardImg: {
      display: 'block',
      height: '55px',
      cursor: 'pointer',
      marginTop: '1em'
    }
  };
};

export default styles;
