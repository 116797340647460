// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import { readSettings } from '../../../helpers/board';
import { Auth } from 'aws-amplify';
import styles from './AdminLoginForm.styles';
import { Formik } from 'formik';
import { ConfigureAmplify } from '../../../helpers/amplify';
import AdminLoginFormControls from './AdminLoginFormControls';
import AdminLoginForgotPassword from './AdminLoginForgotPassword';
import * as Yup from 'yup';
import { injectIntl, defineMessages } from 'react-intl';
import uuidGenerator from '../../Common/uuidGenerator';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('iLocalBox');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);

const messages = defineMessages({
  EnterEmail: {
    id: 'Admin.Login.EnterEmail',
    defaultMessage: 'Enter your email address'
  },
  EnterValidEmail: {
    id: 'Admin.Login.EnterValidEmail',
    defaultMessage: 'Enter a valid email address'
  },
  EmailRequired: {
    id: 'Admin.Login.EmailRequired',
    defaultMessage: 'Email address is required'
  },
  EnterPassword: {
    id: 'Admin.Login.EnterPassword',
    defaultMessage: 'Enter your password'
  },
  ConfirmEnterPassword: {
    id: 'Admin.Login.EnterPassword',
    defaultMessage: 'Enter your password'
  },
  PasswordEightChar: {
    id: 'Admin.Login.PasswordEightChar',
    defaultMessage: 'Password must contain at least 8 characters'
  },
  PasswordOneUpper: {
    id: 'Admin.Login.PasswordOneUpper',
    defaultMessage: 'Password must contain at least one uppercase character'
  },
  PasswordOneLower: {
    id: 'Admin.Login.PasswordOneLower',
    defaultMessage: 'Password must contain at least one lowercase character'
  },
  PasswordOneNumber: {
    id: 'Admin.Login.PasswordOneNumber',
    defaultMessage: 'Password must contain at least one numeric character'
  },
  PasswordRequired: {
    id: 'Admin.Login.PasswordRequired',
    defaultMessage: 'Password is required'
  },
  UserNotExist: {
    id: 'Admin.Login.UserNotExist',
    defaultMessage: 'User does not exist.'
  },
  InvalidUName: {
    id: 'Admin.Login.InvalidUName',
    defaultMessage: 'Incorrect username or password.'
  },
  LoginToContinue: {
    id: 'Admin.Login.LoginToContinue',
    defaultMessage: 'Please login to continue'
  },
  PasswordMustMatch: {
    id: 'Patient.Login.PasswordMustMatch',
    defaultMessage: 'Passwords must match'
  },
  ForgotPasswordEmailRequired: {
    id: 'Admin.Login.ForgotPasswordEmailRequired',
    defaultMessage:
      'Please enter your email address associated with iLocalbox and click forgot password.'
  },
  ForgotPasswordSmsMessage: {
    id: 'Admin.Login.ForgotPasswordSmsMessage',
    defaultMessage: 'A verification code is sent to your phone number'
  },
  ForgotPasswordEmailMessage: {
    id: 'Admin.Login.ForgotPasswordEmailMessage',
    defaultMessage: 'Please check your email for the verification code.'
  },
  ForgotPasswordSubmit: {
    id: 'Admin.Login.ForgotPasswordSubmit',
    defaultMessage: 'Password reset successfully, Please login to continue.'
  },
  ValidVerficationCode: {
    id: 'Admin.Login.ValidVerficationCode',
    defaultMessage: 'Please enter valid verification code.'
  },
  VerificationCode: {
    id: 'Admin.Login.VerificationCode',
    defaultMessage: 'Please enter verification code.'
  },
  PasswordnotMatch: {
    id: 'Admin.Login.PasswordnotMatch',
    defaultMessage: 'Passwords do not match.'
  },
  SignIn: {
    id: 'Admin.Login.SignIn',
    defaultMessage: 'Sign In'
  },
  Passwordalreadyexists: {
    id: 'Admin.Login.Passwordalreadyexists',
    defaultMessage: 'Password already exists.'
  }
});

let USERNAME;
export class AdminLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruction:
        this.props && this.props.intl.formatMessage(messages.LoginToContinue),
      usernameError: false,
      passwordError: false,
      nameError: false,
      user: null,
      NEW_PASSWORD_REQUIRED: false,
      forgotPasswordSMSFlow: false,
      userid: '',
      isProcessing: false
    };
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    loginHandler: PropTypes.func,
    user: PropTypes.object,
    error: PropTypes.object,
    NEW_PASSWORD_REQUIRED: PropTypes.bool,
    PASSWORD_RESET_REQUIRED: PropTypes.bool,
    onForgotPasswordRequest: PropTypes.func,
    onContactSupportRequest: PropTypes.func,
    FORGOT_PASSWORD_REQUEST: PropTypes.bool,
    username: PropTypes.string,
    onGetCode: PropTypes.func,
    onForgotPasswordSubmit: PropTypes.func,
    onRequestSignIn: PropTypes.func
  };
  componentDidMount() {
    ConfigureAmplify();
  }
  componentWillMount() {
    localStorage.removeItem('permissionUser');
    localStorage.removeItem('permissions');
    localStorage.removeItem('VidyoCore::SelectedMicrophone');
    localStorage.removeItem('VidyoCore::SelectedSpeaker');
    localStorage.removeItem('VidyoCore::EndpointID');
    localStorage.removeItem('VidyoCore::SelectedCamera');
    localStorage.removeItem('currentcode');
    const _checkIfSessionIsActiveOrNot = localStorage.getItem('ActiveSession');
    if (_checkIfSessionIsActiveOrNot) {
      let decryptedSessionObject = _cryptr.decrypt(
        _checkIfSessionIsActiveOrNot
      );
      const parsedDecryptedObject = JSON.parse(decryptedSessionObject);
      parsedDecryptedObject._session_active = false;
      parsedDecryptedObject._end_date = new Date();
      console.log(
        `Reset existing session: ${JSON.stringify(parsedDecryptedObject)}`
      );
      const encryptedSessionObject = _cryptr.encrypt(
        JSON.stringify(parsedDecryptedObject)
      );
      localStorage.setItem('ActiveSession', encryptedSessionObject);
    } else {
      let session = {
        _id: uuidGenerator(),
        _name: 'Reset Active Session',
        _session_active: false,
        _start_date: new Date(),
        _end_date: null
      };
      console.log(`Initiate new Session: ${JSON.stringify(session)}`);
      const encryptedSessionObject = _cryptr.encrypt(JSON.stringify(session));
      localStorage.setItem('ActiveSession', encryptedSessionObject);
    }
    let n = sessionStorage.length;
    while (n--) {
      let key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
  }

  signIn = (formData) => {
    let box_id = '';
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      box_id = fileData ? fileData.boxId : false;
    } else {
      box_id = localStorage.getItem('boxId');
    }

    let objSignInUser = {
      username: formData.username,
      box_id: box_id
    };
    this.setState({ isProcessing: true });

    fetch(process.env.REACT_APP_BASEURL + 'authUser', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(objSignInUser)
    })
      .then((response) => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then((objUser) => {
        let objUserDetais = JSON.parse(objUser);
        if (objUserDetais && objUserDetais.isValidUser === true) {
          Auth.signIn(formData.username, formData.password)
            .then((user) => {
              let session = {
                _id: uuidGenerator(),
                _name: 'Kiosk Admin Login',
                _session_active: true,
                _start_date: new Date(),
                _end_date: null
              };
              console.log(
                `Kiosk Admin Login Screen : Session has been started with details ${JSON.stringify(
                  session
                )}`
              );
              const encryptedSessionObject = _cryptr.encrypt(
                JSON.stringify(session)
              );
              localStorage.setItem('ActiveSession', encryptedSessionObject);
              sessionStorage.setItem(
                'jwtToken',
                JSON.stringify(user.signInUserSession.idToken.jwtToken)
              );
              //Get Permissions for Login user
              sessionStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('startDate', session._start_date);
              fetch(process.env.REACT_APP_BASEURL + 'sessionHistory', {
                method: 'POST',
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization:
                    'Bearer ' +
                    JSON.parse(sessionStorage.getItem('user')).signInUserSession
                      .idToken.jwtToken
                },
                body: JSON.stringify(session)
              });
              fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
                method: 'GET',
                withCredentials: true,
                headers: {
                  Authorization:
                    'Bearer ' +
                    JSON.parse(sessionStorage.getItem('user')).signInUserSession
                      .idToken.jwtToken,
                  'Content-Type': 'application/json'
                }
              })
                .then((response) => response.json())
                .then((Permissons) => {
                  if (Permissons.permission) {
                    const encryptedString = cryptr.encrypt(
                      JSON.stringify(Permissons.permission)
                    );
                    localStorage.setItem('permissions', encryptedString);
                  }
                  if (Permissons.superAdmin) {
                    localStorage.setItem(
                      'permissionUser',
                      Permissons.superAdmin
                    );
                  }
                  this.setState({ isProcessing: false });
                  this.props.history.push('/retail/AdminPanel');
                  this.props.history.push('/retail/AdminPanel');
                });
            })
            .catch((err) => {
              switch (err.code) {
                case 'UserNotFoundException':
                  this.setState({
                    instruction:
                      this.props &&
                      this.props.intl.formatMessage(messages.UserNotExist),
                    usernameError: true,
                    isProcessing: false
                  });
                  break;
                case 'NotAuthorizedException':
                default:
                  this.setState({
                    instruction:
                      this.props &&
                      this.props.intl.formatMessage(messages.InvalidUName),
                    usernameError: true,
                    passwordError: true,
                    isProcessing: false
                  });
              }
            });
        } else {
          this.setState({
            instruction:
              this.props &&
              this.props.intl.formatMessage(messages.InvalidUName),
            usernameError: true,
            passwordError: true,
            isProcessing: false
          });
        }
      });
  };

  forgotPassword = (e) => {
    if (!USERNAME) {
      this.setState({
        instruction:
          this.props &&
          this.props.intl.formatMessage(messages.ForgotPasswordEmailRequired),
        usernameError: true,
        passwordError: false
      });
    } else {
      Auth.forgotPassword(USERNAME)
        .then((data) => {
          if (data.CodeDeliveryDetails.DeliveryMedium === 'SMS') {
            const message = `A verification code is sent to your phone number ${data.CodeDeliveryDetails.Destination}`;
            this.setState({
              instruction: message,
              usernameError: false,
              passwordError: false,
              NEW_PASSWORD_REQUIRED: true,
              forgotPasswordSMSFlow: true
            });
          } else {
            const message =
              this.props &&
              this.props.intl.formatMessage(
                messages.ForgotPasswordEmailMessage
              );
            this.setState({
              instruction: message,
              usernameError: false,
              passwordError: false,
              NEW_PASSWORD_REQUIRED: true,
              forgotPasswordSMSFlow: true
            });
          }
          let userObj = {
            username: USERNAME
          };
          fetch(process.env.REACT_APP_BASEURL + 'getresetpwdatefromuser', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userObj)
          })
            .then((response) => {
              try {
                return response.text();
              } catch (error) {
                return null;
              }
            })
            .then((uData) => {
              let Data = JSON.parse(uData);
              this.setState({ userid: Data[0].id });
            });
        })
        .catch((err) =>
          this.setState({
            instruction: err.message,
            usernameError: false,
            passwordError: false
          })
        );
    }
  };
  forgotPasswordSubmit = (formdata) => {
    if (
      USERNAME &&
      formdata.smsCode &&
      formdata.newpassword &&
      formdata.confirmpassword &&
      formdata.newpassword === formdata.confirmpassword
    ) {
      let sha256 = require('js-sha256');
      let pw_hash = sha256(`${formdata.newpassword} ${this.state.userid}`);
      fetch(
        process.env.REACT_APP_BASEURL +
          'getLastPasswords/' +
          this.state.userid +
          '/' +
          pw_hash
      )
        .then((response) => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then((passwordmatch) => {
          let is_password_match = JSON.parse(passwordmatch);
          if (
            is_password_match.res === true ||
            is_password_match.res === 'true'
          ) {
            this.setState({
              instruction:
                this.props &&
                this.props.intl.formatMessage(messages.Passwordalreadyexists),
              nameError: false,
              newpasswordError: true,
              confirmpasswordError: true
            });
          } else {
            Auth.forgotPasswordSubmit(
              USERNAME,
              formdata.smsCode,
              formdata.newpassword
            )
              .then((data) => {
                this.setState({
                  instruction:
                    this.props &&
                    this.props.intl.formatMessage(
                      messages.ForgotPasswordSubmit
                    ),
                  forgotPasswordSMSFlow: false,
                  NEW_PASSWORD_REQUIRED: false
                });
                let pw_obj = {
                  userid: this.state.userid,
                  password: pw_hash
                };
                const token = localStorage.getItem('authTokens');
                const actualToken = atob(token);
                fetch(process.env.REACT_APP_BASEURL + 'savepassword', {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + actualToken
                  },
                  body: JSON.stringify(pw_obj)
                })
                  .then((response) => {
                    try {
                      return response.text();
                    } catch (error) {
                      return null;
                    }
                  })
                  .then((uData) => {
                    console.log(uData);
                  });
              })
              .catch((err) => {
                console.error(err);
                this.setState({
                  instruction:
                    this.props &&
                    this.props.intl.formatMessage(messages.ValidVerficationCode)
                });
              });
          }
        });
    } else if (!formdata.smsCode) {
      this.setState({
        smsCodeError: true,
        instruction:
          this.props && this.props.intl.formatMessage(messages.VerificationCode)
      });
    } else if (!formdata.newpassword) {
      this.setState({ resetNewPasswordError: true });
    } else if (!formdata.confirmpassword) {
      this.setState({ resetConfirmPasswordError: true });
    } else if (formdata.newpassword !== formdata.confirmpassword) {
      this.setState({
        instruction:
          this.props &&
          this.props.intl.formatMessage(messages.PasswordnotMatch),
        nameError: false,
        newpasswordError: true,
        confirmpasswordError: true
      });
    }
  };
  binduser = (data) => {
    USERNAME = data;
  };
  handleback = () => {
    setTimeout(() => {
      this.props.history.push('/retail/SecureBadgeLogin');
      this.props.history.push('/retail/SecureBadgeLogin');
    }, 30);
  };

  handleCancelEvent = () => {
    this.props.history.push('/');
    this.props.history.push('/');
  };

  render() {
    const { classes, intl, onForgotPasswordRequest, onContactSupportRequest } =
      this.props;
    const { isProcessing } = this.state;
    const values = { username: '', password: '' };

    const validationSchema = Yup.object({
      username: Yup.string(intl.formatMessage(messages.EnterEmail))
        .email(intl.formatMessage(messages.EnterValidEmail))
        .required(intl.formatMessage(messages.EmailRequired)),
      password: Yup.string(intl.formatMessage(messages.EnterPassword))
        .min(8, intl.formatMessage(messages.PasswordEightChar))
        .matches(/[A-Z]/, intl.formatMessage(messages.PasswordOneUpper), true)
        .matches(/[a-z]/, intl.formatMessage(messages.PasswordOneLower), true)
        .matches(/[0-9]/, intl.formatMessage(messages.PasswordOneNumber), true)
        .required(intl.formatMessage(messages.PasswordRequired))
    });

    const newPasswordValidationSchema = Yup.object({
      newpassword: Yup.string(intl.formatMessage(messages.EnterPassword))
        .min(8, intl.formatMessage(messages.PasswordEightChar))
        .matches(/[A-Z]/, intl.formatMessage(messages.PasswordOneUpper), true)
        .matches(/[a-z]/, intl.formatMessage(messages.PasswordOneLower), true)
        .matches(/[0-9]/, intl.formatMessage(messages.PasswordOneNumber), true)
        .required(intl.formatMessage(messages.PasswordRequired)),
      confirmpassword: Yup.string(
        intl.formatMessage(messages.ConfirmEnterPassword)
      )
        .min(8, intl.formatMessage(messages.PasswordEightChar))
        .matches(/[A-Z]/, intl.formatMessage(messages.PasswordOneUpper), true)
        .matches(/[a-z]/, intl.formatMessage(messages.PasswordOneLower), true)
        .matches(/[0-9]/, intl.formatMessage(messages.PasswordOneNumber), true)
        .oneOf(
          [Yup.ref('newpassword'), null],
          intl.formatMessage(messages.PasswordMustMatch)
        )
        .required(intl.formatMessage(messages.PasswordRequired))
    });

    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.appLogoContainer)}>
          <img
            className={classNames(classes.appLogo)}
            alt="app-logo"
            src="/images/brands/Steps/Welcome-Line.png"
          />
        </div>
        <div>
          <span className={classNames(classes.appName)}>
            {this.props && this.props.intl.formatMessage(messages.SignIn)}
          </span>
          &nbsp;
          <span className={classNames(classes.Instructiontitle)}>
            {this.state && this.state.instruction}
          </span>
        </div>
        <div className={classNames(classes.leftFormContent)}>
          <div className={classNames(classes.form)}>
            {this.state && !this.state.NEW_PASSWORD_REQUIRED && (
              <Formik
                render={(props) => (
                  <AdminLoginFormControls
                    {...props}
                    handleforgotPassword={this.forgotPassword}
                    handleBack={this.handleback}
                    handleusername={this.binduser}
                    onForgotPasswordRequest={onForgotPasswordRequest}
                    onContactSupportRequest={onContactSupportRequest}
                    isProcessing={isProcessing}
                  />
                )}
                validationSchema={validationSchema}
                initialValues={values}
                onSubmit={(formData) => {
                  this.signIn(formData);
                }}
              />
            )}
            {this.state && this.state.forgotPasswordSMSFlow && (
              <Formik
                render={(props) => (
                  <AdminLoginForgotPassword
                    {...props}
                    onForgotPasswordRequest={onForgotPasswordRequest}
                    onContactSupportRequest={onContactSupportRequest}
                    onCancelClick={this.handleCancelEvent}
                  />
                )}
                validationSchema={newPasswordValidationSchema}
                initialValues={values}
                onSubmit={(formData) => {
                  this.forgotPasswordSubmit(formData);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminLoginForm))
);
