const styles = (theme) => {
  return {
    container: {
      display: 'grid',
      gridGap: '1em',
      justifyContent: 'center',
      alignItems: 'center'
    },
    flexContainer: {
      display: 'none'
    },
    navContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '590px',
      flexWrap: 'wrap',
      justifyContent: 'space-around'
    },
    navItem: {
      maxWidth: '150px',
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    navItemTop: {
      background: '#4ba686',
      borderRadius: '10px',
      padding: '25px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    navItemTopImg: {
      width: '100%'
    },
    navItemText: {
      width: '100%',
      color: '#4ba686',
      fontSize: '17px',
      fontWeight: 'bolder',
      display: 'flex',
      justifyContent: 'center'
    },
    flexItem: {
      display: 'flex',
      width: '20vh',
      height: '17vh',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#4ba686',
      borderRadius: '15px',
      cursor: 'pointer',
      textAlign: 'center'
    },
    imgProperty: {
      height: 150,
      width: 150
    },
    flexTemText: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20vh',
      color: '#4ba686',
      fontSize: '2em',
      fontWeight: 500
    },
    primaryButton: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
      fontSize: '2em'
    },
    disabledPrimaryButton: {
      marginTop: '1em',
      fontSize: '2em'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: '#005d67',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #005d67',
      cursor: 'pointer'
    },
    codeGrid: {
      display: 'grid',
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    }
  };
};

export default styles;
