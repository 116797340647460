// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminSettingFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { withRouter } from 'react-router';
import {
  writeSettings,
  readSettings,
  closeApplication
} from '../../../helpers/board';
import Steps from '../../Common/show_hide_steps';
import { setStepTitle } from '../../Common/skipSteps';
import packageJson from '../../../../package.json';
import { writeKioskLog } from '../../Common/SyncAPIs';

const messages = defineMessages({
  BoxManagement: {
    id: 'Admin.Settings.BoxManagement',
    defineMessages: 'Box Management'
  },
  EnterBoxId: {
    id: 'Admin.Settings.EnterBoxId',
    defineMessages: 'Enter box id'
  },
  BoxStatus: {
    id: 'Admin.Settings.BoxStatus',
    defineMessages: 'Box Status'
  },
  OFF: {
    id: 'Admin.Settings.OFF',
    defineMessages: 'OFF'
  },
  ON: {
    id: 'Admin.Settings.ON',
    defineMessages: 'ON'
  },
  RearWallScreen: {
    id: 'Admin.Settings.RearWallScreen',
    defineMessages: 'Rear Wall Screen'
  },
  DataPollingSetting: {
    id: 'Admin.Settings.DataPollingSetting',
    defineMessages: 'Data Polling Setting'
  },
  Type: {
    id: 'Admin.Settings.Type',
    defineMessages: 'Type'
  },
  Order: {
    id: 'Admin.Settings.Order',
    defineMessages: 'Order'
  },
  Connected: {
    id: 'Admin.Settings.Connected',
    defineMessages: 'Connected'
  },
  WorkFlowToggle: {
    id: 'Admin.Settings.WorkFlowToggle',
    defineMessages: 'Workflow toggle'
  },
  POS: {
    id: 'Admin.Settings.POS',
    defineMessages: 'POS'
  },
  Consult: {
    id: 'Admin.Settings.Consult',
    defineMessages: 'Consult'
  },
  DLCapture: {
    id: 'Admin.Settings.DLCapture',
    defineMessages: 'DL Capture'
  },
  Survey: {
    id: 'Admin.Settings.Survey',
    defineMessages: 'Survey'
  },
  Back: {
    id: 'Admin.Settings.Back',
    defineMessages: 'Back'
  },
  NoExpansionConfigured: {
    id: 'Admin.Settings.NoExpansionConfigured',
    defineMessages: 'No expansion units are configured'
  },
  AddOnUnits: {
    id: 'Admin.Settings.AddOnUnits',
    defineMessages: 'Addon Units'
  },
  ExitApplication: {
    id: 'Admin.Settings.Exit',
    defineMessages: 'Exit'
  },
  NO: {
    id: 'Admin.Settings.NO',
    defineMessages: 'NO'
  },
  YES: {
    id: 'Admin.Settings.YES',
    defineMessages: 'YES'
  }
});

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 'auto'
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})((props) => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);
let count = 0,
  tempBoxId = '';

let FileObje = {
  boxId: '',
  dynamicDataSource: false,
  rearStock: false,
  IsActive: true,
  pickupType: '1',
  steps: [
    {
      step: 0,
      name: 'Touch to Start',
      value: true,
      prev: '',
      next: '',
      btnClick: 0,
      stepTitle: 0
    },
    {
      step: 1,
      name: 'Pick up Code Enter',
      value: true,
      prev: '/',
      next: '/KioskVerify',
      btnClick: 0,
      stepTitle: 1
    },
    {
      step: 2,
      name: 'DOB verification',
      value: true,
      prev: '/KioskPickupCode',
      next: '/KioskConfirmPickup',
      btnClick: 0,
      stepTitle: 2
    },
    {
      step: 3,
      name: 'Confirm Pick up items',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 3
    },
    {
      step: 4,
      name: 'Consult',
      value: true,
      prev: '/KioskConfirmPickup',
      next: '/KioskSignature',
      btnClick: 0,
      stepTitle: 4
    },
    {
      step: 5,
      name: 'Signature',
      value: true,
      prev: '/KioskConsult',
      next: '/KioskConfirmUser',
      btnClick: 0,
      stepTitle: 5
    },
    {
      step: 6,
      name: 'DL Capture',
      value: true,
      prev: '/KioskSignature',
      next: '/KioskCollectMedicines',
      btnClick: 0,
      stepTitle: 6
    },
    {
      step: 7,
      name: 'Collect your items',
      value: true,
      prev: '',
      next: '/KioskFeedback',
      btnClick: 0,
      stepTitle: 7
    },
    {
      step: 8,
      name: 'Survey',
      value: true,
      prev: '',
      next: '/',
      btnClick: 0,
      stepTitle: 8
    },
    {
      step: 9,
      name: 'Confirm Pickup Payment',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 9
    },
    {
      step: 10,
      name: 'View Current Rx',
      value: true,
      prev: '/KioskPatientViewRx',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 0
    }
  ],
  posWaitDuration: 3000,
  IsNDCVideoAllow: false,
  /*
    Added By : Belani Jaimin
    Description: Send payment request to ingenico device
    Note: The default URL is base url of kiosk-wrapper code configuration.
  */
  paymentBaseUrl: '',
  isHWCommuniocationEnabled: true,
  EnableLifeboat: false
};
export class AdminPanelFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    let rearStockFlag;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      rearStockFlag = fileData ? fileData.rearStock : false;
    } else {
      rearStockFlag =
        localStorage.getItem('rearStock') &&
        (localStorage.getItem('rearStock') === 'true' ||
          localStorage.getItem('rearStock') === true)
          ? true
          : false;
    }
    this.state = {
      boxId: '',
      showKeyboard: false,
      isEnabled: false,
      displayMsg: '',
      maxlength: 15,
      layoutName: 'default',
      SelectedInput: 'boxid',
      isPollingEnabled: false,
      IsActive: true,
      expanded: 'panel1',
      selectedAddonUnit: '',
      expansionUnits: [],
      rearStock: rearStockFlag,
      showConsult: true,
      showDLcapture: true,
      showSurvey: true,
      showPOS: true,
      isVucaVideosEnabled: false,
      EnableLifeboat: false
    };
    this.textInput = React.createRef();
    this.handleRearStockStatus = this.handleRearStockStatus.bind(this);
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }
  handlePanelChange = (panel) => (expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };
  componentWillMount() {
    let sourceEnabled,
      boxId,
      videoEnabled,
      posBaseURL,
      isHWCommuniocationEnabled,
      EnableLifeboat;
    let steps = [];
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      sourceEnabled = fileData ? fileData.dynamicDataSource : false;
      videoEnabled = fileData ? fileData.IsNDCVideoAllow : false;
      boxId = fileData ? fileData.boxId : '';
      FileObje.steps =
        fileData && fileData.steps ? fileData.steps : FileObje.steps;
      posBaseURL = fileData ? fileData.paymentBaseUrl : '';
      isHWCommuniocationEnabled = fileData
        ? fileData.isHWCommuniocationEnabled
        : true;
      EnableLifeboat = fileData ? fileData.EnableLifeboat : true;
    } else {
      sourceEnabled = JSON.parse(localStorage.getItem('dynamicDataSource'));
      videoEnabled = JSON.parse(localStorage.getItem('IsNDCVideoAllow'));
      boxId = localStorage.getItem('boxId');
      steps = localStorage.getItem('steps');
      if (steps !== null) {
        steps = JSON.parse(steps);
        FileObje.steps = steps;
      }
      posBaseURL = localStorage.getItem('paymentBaseUrl');
    }
    this.setState({
      isPollingEnabled: sourceEnabled,
      boxId: boxId,
      isVucaVideosEnabled: videoEnabled,
      paymentBaseUrl: posBaseURL,
      isHWCommuniocationEnabled: isHWCommuniocationEnabled,
      EnableLifeboat: EnableLifeboat
    });
    this.setTogglebutton();
    this.renderExpansion();
  }
  setTogglebutton = () => {
    let showConsult = true,
      showDLcapture = true,
      showSurvey = true,
      showPOS = true;
    if (FileObje.steps !== null && FileObje.steps.length > 0) {
      FileObje.steps.filter((item) => {
        switch (item.step) {
          case Steps.step4:
            showConsult = item.value;
            break;
          case Steps.step6:
            showDLcapture = item.value;
            break;
          case Steps.step8:
            showSurvey = item.value;
            break;
          case Steps.step9:
            showPOS = item.value;
            break;
          default:
        }
        return true;
      });
      this.setState({
        showConsult: showConsult,
        showDLcapture: showDLcapture,
        showSurvey: showSurvey,
        showPOS: showPOS
      });
    }
  };
  handleClose = () => {
    document.querySelector('#' + this.state.SelectedInput).value = '';
    this.setState({ open: false, patientDetails: [], displayMsg: '' });
    setTimeout(() => {
      this.textInput.current.focus();
    }, 650);
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      count++;
      if (count === 1) {
        input = tempBoxId + input;
      }
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  onKeyPress = (button) => {
    let box = '';
    if (button === '{backspace}') {
      box = this.state.boxId;
      box = box.substring(0, box.length - 1);
      this.setState({ boxId: box });
      count = this.state.boxId === '' ? (count = 0) : count - 1;
      if (box === '') {
        this.keyboard.clearInput();
        tempBoxId = '';
      }
    }
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  change = (event) => {
    const boxId = event.target.value;
    this.setState({ boxId: boxId });
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  editBoxId = () => {
    let boxid = this.state.boxId;
    this.setState({ isEnabled: true });
    this.setState({ showKeyboard: true });
    this.keyboard.setInput(this.state.boxId);
    if (
      boxid === 'Enter box id' ||
      boxid === 'Enter box id' ||
      boxid === '' ||
      boxid === null ||
      boxid === undefined
    ) {
      tempBoxId = '';
      this.keyboard.clearInput();
    } else {
      tempBoxId = this.state.boxId;
    }
    localStorage.setItem('brands', JSON.stringify([]));
  };

  saveBoxId = () => {
    this.setState({ showKeyboard: false });
    const boxId = this.state && this.state.boxId;
    if (boxId) {
      FileObje.boxId = boxId;
      FileObje.IsActive = this.state.IsActive;
      FileObje.dynamicDataSource = this.state.isPollingEnabled;
      FileObje.rearStock = this.state.rearStock;
      FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
      FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
      FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
      FileObje.EnableLifeboat = this.state.EnableLifeboat;
      localStorage.setItem('dummy_kiosk_box_id', boxId);
    } else {
      FileObje.boxId = '';
      FileObje.IsActive = this.state.IsActive;
      FileObje.dynamicDataSource = this.state.isPollingEnabled;
      FileObje.rearStock = this.state.rearStock;
      FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
      FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
      FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
      FileObje.EnableLifeboat = this.state.EnableLifeboat;
      let dummyBoxId = localStorage.getItem('dummy_kiosk_box_id');
      if (dummyBoxId) {
        fetch(process.env.REACT_APP_BASEURL + 'updateBoxStatus/' + dummyBoxId, {
          method: 'GET',
          withCredentials: true,
          headers: {
            Authorization:
              'Bearer ' +
              JSON.parse(sessionStorage.getItem('user')).signInUserSession
                .idToken.jwtToken,
            'Content-Type': 'application/json'
          }
        })
          .then((response) => response.json())
          .then((res) => {
            console.log(res);
          });
      }
    }
    this.setState({ isEnabled: false });
    if (window.electron) {
      writeSettings(FileObje);
    } else {
      if (boxId) {
        localStorage.setItem('boxId', boxId.toString().toUpperCase());
      } else {
        localStorage.setItem('boxId', '');
      }
    }
    let obj = {
      IsActive: this.state.IsActive,
      kiosk_box_id: boxId
    };
    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,

      description: `Box id was set to ${boxId}`
    };
    if (boxId) {
      fetch(`${process.env.REACT_APP_BASEURL}boxes/${boxId}/token`, {
        method: 'GET',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then((res) => {
          localStorage.setItem('authTokens', btoa(res.token));
        });
      fetch(process.env.REACT_APP_BASEURL + 'getofflinebrandbybox/' + boxId, {
        method: 'GET',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((brand) => {
          localStorage.setItem('brands', JSON.stringify(brand.theBox));
        });

      fetch(process.env.REACT_APP_BASEURL + 'getofflinevideosbybox/' + boxId, {
        method: 'GET',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((videos) => {
          localStorage.setItem('videos', JSON.stringify(videos.thevideos));
        });
      fetch(process.env.REACT_APP_BASEURL + 'updateBoxIsActive', {
        method: 'post',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' +
            JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
              .jwtToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      }).then((response) => {
        if (this.props.box && this.props.box.is_kiosk_log) {
          writeKioskLog(audit_obj);
        }
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      });
      window.consultSocket &&
        window.consultSocket.emit &&
        window.consultSocket.emit('ConfigureBoxId', { box_id: boxId });
      console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', boxId);
    }
  };

  handleChangeActive = (event) => {
    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,

      description: `Box status was set to ${event.target.checked}`,
      box_id: this.props.box.id ? this.props.box.id : null
    };
    if (this.props.box && this.props.box.is_kiosk_log) {
      writeKioskLog(audit_obj);
    }

    this.setState({ IsActive: event.target.checked });
    FileObje.boxId = this.state.boxId;
    FileObje.IsActive = event.target.checked;
    FileObje.dynamicDataSource = this.state.isPollingEnabled;
    FileObje.rearStock = this.state.rearStock;
    FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
    FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
    FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
    FileObje.EnableLifeboat = this.state.EnableLifeboat;
    localStorage.setItem('IsActive', event.target.checked);
    if (window.electron) {
      writeSettings(FileObje);
    }
  };

  handleChange = (name) => (event) => {
    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,

      description: `Data polling was set to ${event.target.checked}`,
      box_id: this.props.box.id ? this.props.box.id : null
    };
    if (this.props.box && this.props.box.is_kiosk_log) {
      writeKioskLog(audit_obj);
    }

    const dataStatus = event.target.checked;
    this.setState({ isPollingEnabled: dataStatus });
    this.props.onDataSourceChange(event, dataStatus);
    FileObje.boxId = this.state.boxId;
    FileObje.IsActive = this.state.IsActive;
    FileObje.dynamicDataSource = dataStatus;
    FileObje.rearStock = this.state.rearStock;
    FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
    FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
    FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
    FileObje.EnableLifeboat = this.state.EnableLifeboat;
    localStorage.setItem('dynamicDataSource', dataStatus);
    if (window.electron) {
      writeSettings(FileObje);
    }
  };

  onUnitChange = (event) => {
    let selectedUnit = event.target.value;
    this.setState({ selectedAddonUnit: selectedUnit });
  };

  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }

  renderExpansion() {
    const { classes, box } = this.props && this.props;
    if (box && box.box_expansions && box.box_expansions.length > 0) {
      let units = box.box_expansions;
      return units.map((unit) => {
        return (
          <TableRow>
            <TableCell
              className={classNames(classes && classes.tableCell)}
              align="left"
            >
              {unit && unit.boxes_type && unit.boxes_type.name}
            </TableCell>
            <TableCell
              className={classNames(classes && classes.tableCell)}
              align="left"
            >
              {unit.direction === 1 ? (
                <ArrowBackIcon className={classes && classes.tableIcon} />
              ) : (
                <ArrowForward className={classes && classes.tableIcon} />
              )}
            </TableCell>
            <TableCell
              className={classNames(classes && classes.tableCell)}
              align="left"
            >
              {unit && unit.connected_box_type && unit.connected_box_type.name}
            </TableCell>
          </TableRow>
        );
      });
    } else {
      return (
        <TableRow>
          <TableCell>
            {this.props &&
              this.props.intl.formatMessage(messages.NoExpansionConfigured)}
          </TableCell>
        </TableRow>
      );
    }
  }

  handleRearStockStatus = (name) => (event) => {
    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,

      description: `Rear wall screen was set to ${event.target.checked}`,
      box_id: this.props.box.id ? this.props.box.id : null
    };
    if (this.props.box && this.props.box.is_kiosk_log) {
      writeKioskLog(audit_obj);
    }

    const dataStatus = event.target.checked;
    this.setState({ rearStock: dataStatus });
    FileObje.boxId = this.state.boxId;
    FileObje.IsActive = this.state.IsActive;
    FileObje.dynamicDataSource = this.state.isPollingEnabled;
    FileObje.rearStock = JSON.parse(dataStatus);
    FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
    FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
    FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
    FileObje.EnableLifeboat = this.state.EnableLifeboat;
    if (window.electron) {
      writeSettings(FileObje);
    } else {
      localStorage.setItem('rearStock', JSON.parse(dataStatus));
    }
  };
  handleWorkflowtoggle = (event, name, step) => {
    FileObje.boxId = this.state.boxId;
    FileObje.IsActive = this.state.IsActive;
    FileObje.dynamicDataSource = this.state.isPollingEnabled;
    FileObje.rearStock = this.state.rearStock;
    FileObje.IsNDCVideoAllow = this.state.isVucaVideosEnabled;
    FileObje.paymentBaseUrl = this.state.paymentBaseUrl;
    FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
    FileObje.EnableLifeboat = this.state.EnableLifeboat;
    switch (step) {
      case Steps.step4: //showConsult
        this.setState({ showConsult: event.target.checked });
        FileObje.steps.filter((x) => {
          if (x.step === Steps.step4) {
            x.value = event.target.checked;
          }
          return true;
        });
        const audit_obj_consult = {
          user_id: JSON.parse(sessionStorage.getItem('user')).username,

          description: `Consult was set to ${event.target.checked}`,
          box_id: this.props.box.id ? this.props.box.id : null
        };
        if (this.props.box && this.props.box.is_kiosk_log) {
          writeKioskLog(audit_obj_consult);
        }
        break;
      case Steps.step6: //showDLcapture
        this.setState({ showDLcapture: event.target.checked });
        FileObje.steps.filter((x) => {
          if (x.step === Steps.step6) {
            x.value = event.target.checked;
          }
          return true;
        });
        const audit_obj_dlcapture = {
          user_id: JSON.parse(sessionStorage.getItem('user')).username,

          description: `DLcapture was set to ${event.target.checked}`,
          box_id: this.props.box.id ? this.props.box.id : null
        };
        if (this.props.box && this.props.box.is_kiosk_log) {
          writeKioskLog(audit_obj_dlcapture);
        }
        break;
      case Steps.step8: //showSurvey
        this.setState({ showSurvey: event.target.checked });
        FileObje.steps.filter((x) => {
          if (x.step === Steps.step8) {
            x.value = event.target.checked;
          }
          return true;
        });
        const audit_obj_survey = {
          user_id: JSON.parse(sessionStorage.getItem('user')).username,

          description: `Survey was set to ${event.target.checked}`,
          box_id: this.props.box.id ? this.props.box.id : null
        };
        if (this.props.box && this.props.box.is_kiosk_log) {
          writeKioskLog(audit_obj_survey);
        }
        break;
      case Steps.step9: //POS
        this.setState({ showPOS: event.target.checked });
        FileObje.steps.filter((x) => {
          if (x.step === Steps.step9) {
            x.value = event.target.checked;
          }
          return true;
        });
        const audit_obj_pos = {
          user_id: JSON.parse(sessionStorage.getItem('user')).username,

          description: `Point of Sale(POS) was set to ${event.target.checked}`,
          box_id: this.props.box.id ? this.props.box.id : null
        };
        if (this.props.box && this.props.box.is_kiosk_log) {
          writeKioskLog(audit_obj_pos);
        }
        break;
      default:
    }
    FileObje.steps = setStepTitle(FileObje.steps);
    localStorage.setItem('steps', JSON.stringify(FileObje.steps));
    if (window.electron) {
      writeSettings(FileObje);
    }
  };

  handleVucaVideosChange = (name) => (event) => {
    const dataStatus = event.target.checked;
    this.setState({ isVucaVideosEnabled: dataStatus });
    this.props.onDataSourceChange(event, dataStatus);
    FileObje.boxId = this.state.boxId;
    FileObje.IsActive = this.state.IsActive;
    FileObje.dynamicDataSource = this.state.isPollingEnabled;
    FileObje.rearStock = this.state.rearStock;
    FileObje.IsNDCVideoAllow = dataStatus;
    FileObje.isHWCommuniocationEnabled = this.state.isHWCommuniocationEnabled;
    FileObje.EnableLifeboat = this.state.EnableLifeboat;
    localStorage.setItem('IsNDCVideoAllow', dataStatus);
    if (window.electron) {
      writeSettings(FileObje);
    }
  };
  handleExitappChange = (event) => {
    event.preventDefault();
    closeApplication();
  };

  render() {
    const { classes, handleBack } = this.props;
    const { isEnabled, expanded } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.flexContainer)}>
          <ExpansionPanel
            square
            expanded={expanded === 'panel1'}
            onChange={this.handlePanelChange('panel1')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                {this.props &&
                  this.props.intl.formatMessage(messages.BoxManagement)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classNames(classes.expansingDetails)}
            >
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItemText)}>
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          style={{ textAlign: 'center' }}
                          id="boxid"
                          name="boxid"
                          className={classNames(classes.textBoxItem)}
                          value={this.state.boxId}
                          margin="normal"
                          variant="outlined"
                          placeholder={
                            this.props &&
                            this.props.intl.formatMessage(messages.EnterBoxId)
                          }
                          inputProps={{ maxLength: 15 }}
                          autoComplete="off"
                          onChange={(e) => this.change(e)}
                          autoFocus
                          disabled={!isEnabled ? true : false}
                          inputRef={this.textInput}
                          onFocus={this.Focus.bind('boxid')}
                          onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                              this.change(ev);
                            }
                          }}
                        />
                      </MuiThemeProvider>
                      <IconButton
                        onClick={this.editBoxId}
                        className={classes.signOutButton}
                      >
                        <EditIcon className={classes.iconStyle} />
                      </IconButton>
                      <IconButton
                        onClick={this.saveBoxId}
                        className={classes.signOutButton}
                      >
                        <SaveIcon className={classes.iconStyle} />
                      </IconButton>
                    </div>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.BoxStatus)}
                      </Typography>
                      <div>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          checked={this.state.IsActive}
                          onChange={(event) => this.handleChangeActive(event)}
                          value={this.state.IsActive}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(
                            messages.RearWallScreen
                          )}
                      </Typography>
                      <div className={classNames(classes.flexItemToggle)}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          disableRipple
                          checked={this.state.rearStock}
                          onChange={this.handleRearStockStatus('dataSource')}
                          value={this.state.rearStock}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel2'}
            onChange={this.handlePanelChange('panel2')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                {this.props &&
                  this.props.intl.formatMessage(messages.DataPollingSetting)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classNames(classes.expansingDetails)}
            >
              <div className={classNames(classes.flexContainer)}>
                <div className={classNames(classes.flexItemToggle)}>
                  {this.props && this.props.intl.formatMessage(messages.OFF)}
                  <Switch
                    classes={{
                      switchBase: classes.iOSSwitchBase,
                      bar: classes.iOSBar,
                      icon: classes.iOSIcon,
                      iconChecked: classes.iOSIconChecked,
                      checked: classes.iOSChecked
                    }}
                    disableRipple
                    checked={this.state.isPollingEnabled}
                    onChange={this.handleChange('dataSource')}
                    value={this.state.isPollingEnabled}
                    color="primary"
                  />
                  {this.props && this.props.intl.formatMessage(messages.ON)}
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel4'}
            onChange={this.handlePanelChange('panel4')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                {this.props &&
                  this.props.intl.formatMessage(messages.AddOnUnits)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classNames(classes.table)}>
                <div>
                  <Table className={classNames(classes.table)}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classNames(classes.tableCell1)}
                          align="left"
                        >
                          {this.props &&
                            this.props.intl.formatMessage(messages.Type)}
                        </TableCell>
                        <TableCell
                          className={classNames(classes.tableCell2)}
                          align="left"
                        >
                          {this.props &&
                            this.props.intl.formatMessage(messages.Order)}
                        </TableCell>
                        <TableCell
                          className={classNames(classes.tableCell)}
                          align="left"
                        >
                          {this.props &&
                            this.props.intl.formatMessage(messages.Connected)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </div>
                <div className={classNames(classes.expandedTable)}>
                  <Table>
                    <TableBody>{this.renderExpansion()}</TableBody>
                  </Table>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel5'}
            onChange={this.handlePanelChange('panel5')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                {this.props &&
                  this.props.intl.formatMessage(messages.WorkFlowToggle)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={classNames(classes.expansingDetails)}
            >
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.POS)}
                      </Typography>
                      <div className={classNames(classes.flexItemToggle)}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          disableRipple
                          checked={this.state.showPOS}
                          onChange={(event) =>
                            this.handleWorkflowtoggle(
                              event,
                              'showPOS',
                              Steps.step9
                            )
                          }
                          value={this.state.showPOS}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.Consult)}
                      </Typography>
                      <div className={classNames(classes.flexItemToggle)}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          disableRipple
                          checked={this.state.showConsult}
                          onChange={(event) =>
                            this.handleWorkflowtoggle(
                              event,
                              'showConsult',
                              Steps.step4
                            )
                          }
                          value={this.state.showConsult}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.DLCapture)}
                      </Typography>
                      <div className={classNames(classes.flexItemToggle)}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          disableRipple
                          checked={this.state.showDLcapture}
                          onChange={(event) =>
                            this.handleWorkflowtoggle(
                              event,
                              'showDLcapture',
                              Steps.step6
                            )
                          }
                          value={this.state.showDLcapture}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                  <TableRow className={classes.tableRow}>
                    <div className={classNames(classes.flexItem)}>
                      <Typography className={classes.labelHeading}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.Survey)}
                      </Typography>
                      <div className={classNames(classes.flexItemToggle)}>
                        {this.props &&
                          this.props.intl.formatMessage(messages.OFF)}
                        <Switch
                          classes={{
                            switchBase: classes.iOSSwitchBase,
                            bar: classes.iOSBar,
                            icon: classes.iOSIcon,
                            iconChecked: classes.iOSIconChecked,
                            checked: classes.iOSChecked
                          }}
                          disableRipple
                          checked={this.state.showSurvey}
                          onChange={(event) =>
                            this.handleWorkflowtoggle(
                              event,
                              'showSurvey',
                              Steps.step8
                            )
                          }
                          value={this.state.showSurvey}
                          color="primary"
                        />
                        {this.props &&
                          this.props.intl.formatMessage(messages.ON)}
                      </div>
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            square
            expanded={expanded === 'panel6'}
            onChange={this.handlePanelChange('panel6')}
          >
            <ExpansionPanelSummary>
              <Typography className={classes.heading}>
                {this.props &&
                  this.props.intl.formatMessage(messages.ExitApplication)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ padding: '0px', marginLeft: '20px', marginTop: '-20px' }}
            >
              <div className={classNames(classes.flexContainer)}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classNames(classes.primaryButton)}
                  onClick={(event) => this.handleExitappChange(event)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.ExitApplication)}
                </Button>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            {this.props && this.props.intl.formatMessage(messages.Back)}
          </Button>
          <span style={{ fontSize: '0.7em', marginBottom: '-60px' }}>
            v. {packageJson.version}
          </span>
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={
              this.state.isEnabled ? (input) => this.onChange(input) : ''
            }
            onKeyPress={
              this.state.isEnabled ? (button) => this.onKeyPress(button) : ''
            }
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminPanelFormControls))
);
