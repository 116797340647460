// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './AdminPanelStockForm.styles';
import AdminPanelStockFormControls from './AdminPanelStockFormControls';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import axios from 'axios';
import { updateData, initialData } from '../../../api/common/actions';
import { readSettings } from '../../../helpers/board';

export class AdminPanelStockForm extends Component {
  handleBack = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminPanel');
      this.props.history.push('/retail/AdminPanel');
    }, 30);
  };

  onDataSourceChange = () => {
    this.getBoxesData();
  };

  getBoxesData = () => {
    let boxId;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }

    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (![undefined, 'undefined', null, null, ''].includes(boxId)) {
      return axios
        .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
          headers: {
            Authorization: 'Bearer ' + actualToken,
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          this.props.updateData(response.data);
          return response.data;
        });
    }
  };

  render() {
    const { classes, box } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminPanelStockFormControls
            onDataSourceChange={this.onDataSourceChange}
            handleBack={this.handleBack}
            box={box}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateData: (box) => dispatch(updateData(box)),
  initialData: (box) => dispatch(initialData(box))
});

export default connect(
  null,
  mapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(
    injectIntl(withRouter(AdminPanelStockForm))
  )
);
