const syncMapping = (binID) => {
  console.log('binID', binID);
  if (Array.isArray(binID)) {
    let multipleBinsToOpen = [];
    for (let i = 0; i < binID.length; i++) {
      let singleBinID = binID[i];
      let bins = singleBinID.split('');
      if (
        singleBinID &&
        singleBinID.includes('A') &&
        Number.isInteger(parseInt(binID[i][1]))
      ) {
        singleBinID = bins[0];
        multipleBinsToOpen.push(
          bins.length === 2
            ? (singleBinID += parseInt(bins[1]) - 7)
            : (singleBinID += parseInt(bins[1] + bins[2]) - 7)
        );
      } else if (
        singleBinID &&
        singleBinID.includes('B') &&
        Number.isInteger(parseInt(binID[i][1]))
      ) {
        singleBinID = bins[0].replace('B', 'A');
        multipleBinsToOpen.push(
          bins.length === 2
            ? (singleBinID += parseInt(bins[1]) - 2)
            : (singleBinID += parseInt(bins[1] + bins[2]) - 2)
        );
      } else if (
        singleBinID &&
        singleBinID.includes('C') &&
        Number.isInteger(parseInt(binID[i][1]))
      ) {
        if (singleBinID && singleBinID.length === 2 && singleBinID[1] <= 5) {
          singleBinID = bins[0].replace('C', 'B');
          multipleBinsToOpen.push((singleBinID += parseInt(bins[1]) + 7));
        } else {
          singleBinID = bins[0];
          multipleBinsToOpen.push(
            bins.length === 2
              ? (singleBinID += parseInt(bins[1]) + 2)
              : (singleBinID += parseInt(bins[1] + bins[2]) + 2)
          );
        }
      } else if (
        !binID[i].includes('A') ||
        !binID[i].includes('B') ||
        !binID[i].includes('C')
      ) {
        multipleBinsToOpen.push(binID[i]);
      }
    }
    return multipleBinsToOpen;
  } else {
    let bins = binID && binID.split('');
    if (binID && binID.includes('A') && Number.isInteger(parseInt(binID[1]))) {
      binID = bins[0];
      return bins.length === 2
        ? (binID += parseInt(bins[1]) - 7)
        : (binID += parseInt(bins[1] + bins[2]) - 7);
    } else if (
      binID &&
      binID.includes('B') &&
      Number.isInteger(parseInt(binID[1]))
    ) {
      binID = bins[0].replace('B', 'A');
      return bins.length === 2
        ? (binID += parseInt(bins[1]) - 2)
        : (binID += parseInt(bins[1] + bins[2]) - 2);
    } else if (
      binID &&
      binID.includes('C') &&
      Number.isInteger(parseInt(binID[1]))
    ) {
      if (binID && binID.length === 2 && binID[1] <= 5) {
        binID = bins[0].replace('C', 'B');
        return (binID += parseInt(bins[1]) + 7);
      } else {
        binID = bins[0];
        return bins.length === 2
          ? (binID += parseInt(bins[1]) + 2)
          : (binID += parseInt(bins[1] + bins[2]) + 2);
      }
    }
  }
  return binID;
};

export { syncMapping };
