import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import styles from './box-layout.style';
import { openBin } from '../../../helpers/board';

let nums = Array.from(Array(5).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ['A', 'B', 'C', 'D', 'E'];
let deactivatedBin = [];
var selectedBins = [];
let binbackground = [];

export class MainUnitLayout extends Component {
  componentWillReceiveProps() {
    const { deactivatedBins } = this.props;
    if (deactivatedBins && deactivatedBins.length > 0) {
      deactivatedBins.map((item) => {
        return true;
      });
    } else {
      deactivatedBin.map((item) => {
        return true;
      });
      deactivatedBin = [];
    }
  }

  openbin = (binId) => {
    openBin(binId);
    selectedBins.push(binId);
    var items = document.getElementById(binId);
    let obj = {
      Id: binId,
      background: items.style.background
    };
    binbackground.push(obj);
    items.style.background = 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    items.style.color = 'rgb(0, 0, 0)';
    this.props.Selectedbin(binId);
  };

  render() {
    const { classes } = this.props;
    return (
      <div key={this.props.prefix}>
        <div className={classes.ratingContainer}>
          {nums1.map((n1) => {
            let isdisabledDoor = false;
            return (
              <Grid
                key={n1}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.RemoveMargin}
              >
                {nums.map((n) => {
                  if (
                    (elem[n] === 'B' || elem[n] === 'C') &&
                    n1 >= 0 &&
                    n1 <= 6
                  ) {
                    if (elem[n] === 'B') {
                      return (
                        <Grid
                          id={elem[n] + '' + (n1 + 1)}
                          key={n}
                          item
                          xs={3}
                        />
                      );
                    } else {
                      return (
                        <Grid
                          id={elem[n] + '' + (n1 + 1)}
                          key={n}
                          item
                          xs={2}
                        />
                      );
                    }
                  } else {
                    if (elem[n] === 'A' || elem[n] === 'B') {
                      return (
                        <Grid
                          className={
                            isdisabledDoor === false
                              ? classes.kioskDoor
                              : classes.disabledDoor
                          }
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                          key={elem[n] + ' ' + (n1 + 1)}
                          item
                          xs={3}
                        >
                          <Paper
                            id={elem[n] + '' + (n1 + 1)}
                            className={classes.gridback}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: 44
                            }}
                          >
                            {elem[n] + ' ' + (n1 + 1)}
                          </Paper>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          className={
                            isdisabledDoor === false
                              ? classes.kioskDoor
                              : classes.disabledDoor
                          }
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                          key={elem[n] + ' ' + (n1 + 1)}
                          item
                          xs={2}
                        >
                          <Paper
                            id={elem[n] + '' + (n1 + 1)}
                            className={classes.gridback}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: 44
                            }}
                          >
                            {elem[n] + ' ' + (n1 + 1)}
                          </Paper>
                        </Grid>
                      );
                    }
                  }
                })}
              </Grid>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MainUnitLayout);
