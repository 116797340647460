import { connect } from 'react-redux';
import AdminMaintenanceForm from './AdminMaintenanceForm';

const mapStateToProps = (state) => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk = state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const AdminMaintenanceFormContainer =
  connect(mapStateToProps)(AdminMaintenanceForm);

export default AdminMaintenanceFormContainer;
