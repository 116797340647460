const styles = (theme) => {
  return {
    container: {
      maxWidth: '640px',
      minHeight: '680px',
      position: 'relative',
      gridGap: '1rem',
      gridTemplateColumns: '200px 470px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    appLogoContainer: {
      display: 'grid',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    appLogo: {
      width: '130px'
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      height: '20vh'
    },
    titleThankyou: {
      color: ' #2F5597',
      fontSize: '3em'
    },
    titleFeedback: {
      color: ' #2F5597',
      fontSize: '1.5em'
    },
    DoorConfirmation: {
      color: ' #2F5597',
      fontSize: '1em'
    },
    ratingContainer: {
      fontSize: '7.5em',
      fontWeight: '500',
      color: '#488581',
      display: 'grid',
      justifyContent: 'center',
      margin: '15px'
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '25px'
    },
    linkLabel: {
      color: '#b8c3c3'
    },

    primaryButton: {
      marginTop: '1em',
      color: '#fff',
      fontSize: '20px',
      width: '45%',
      background: ' #2F5597',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: ' #2F5597'
      }
    },
    spacer: {
      height: '10vh'
    }
  };
};

export default styles;
