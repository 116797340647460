// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminPanelStockFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import { openBin } from '../../../helpers/board';
import sendAWSEmail from '../../../api/aws/pickup_ses';
import AWSconfig from '../../../api/aws/awsConfig';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import ASSIGN from '../../KioskLayout/assignmentStatus';
import EVENT_TYPES from '../../Common/event-type';
import EVENT_CRUD_TYPES from '../../Common/event-crud-types';
import EventLogHandler from '../../Common/event-log-handler';
import keyboardImg from '../AdminLayout/keyboard.png';
import { readSettings } from '../../../helpers/board';

// let orderItem = {};

// AWS Credentials
AWS.config.update({
  accessKeyId: AWSconfig.accessKeyId,
  secretAccessKey: AWSconfig.secretAccessKey,
  region: AWSconfig.region
});
const s3 = new AWS.S3({ region: AWSconfig.region });

// const orderItem = {};

const customerInfo = {
  id: '',
  name: '',
  rxNo: '',
  patient_id: ''
};

const pickupConfig = {
  firstName: '',
  lastName: '',
  emailId: '',
  companyLogo: '',
  companyName: '',
  companyContact: '',
  adressLine1: '',
  adressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  orderHtml: '',
  orderTotalHtml: '',
  orderLocation: '',
  boxName: '',
  boxAddress: '',
  pharmAddress: {},
  qrCode: '',
  pickupCode: '',
  isDisplay: 'display',
  supportPharmacyEmail: '',
  orders: [
    {
      orderId: '',
      pickupCode: '',
      items: []
    }
  ]
};
let prescriptions_master_id = null;

export class AdminPanelFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patientDetails: [],
      pickupCode: '',
      displayMsg: '',
      maxlength: 6,
      layoutName: 'default',
      SelectedInput: 'pickupCode',
      puCode: '',
      showKeyboard: false
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }

  getOrderDuration = (pickup_date, stocked_date) => {
    let duration = stocked_date.getTime() - pickup_date.getTime() / 1000;
    duration /= 60;
    return Math.abs(Math.round(duration));
  };

  UpdateStatusOfRx = (order_id) => {
    let reminder_date = new Date();
    let pick_up_reminder_day =
      this.props &&
      this.props.box &&
      this.props.box.account &&
      this.props.box.account.pick_up_reminder_day !== null
        ? parseInt(this.props.box.account.pick_up_reminder_day)
        : 1;
    if (pick_up_reminder_day !== -1) {
      reminder_date.setDate(reminder_date.getDate() + pick_up_reminder_day);
    } else {
      reminder_date = null;
    }
    const duration = this.getOrderDuration(
      new Date(this.props.box.customers[0].orders[0].stocked_date),
      new Date()
    );
    const updateStatusOfRxObj = {
      id: order_id,
      type_id: ASSIGN.STOCKED,
      stocked_date: new Date(),
      pick_up_reminder_date: reminder_date,
      order_duration: duration
    };
    fetch(process.env.REACT_APP_BASEURL + 'updateStatusOfRx', {
      method: 'post',
      withCredentials: true,
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem('user')).signInUserSession.idToken
            .jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateStatusOfRxObj)
    }).then((response) => {
      try {
        return response.text();
      } catch (error) {
        return null;
      }
    });
    // .then(RxObj => {
    //   // const RxUpdatedObj = JSON.parse(RxObj);
    // });
  };

  change = (event) => {
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    const pickupcode = event.target.value;
    if (this.props && this.props.box) {
      let customer;
      if (mode === false || mode === null) {
        customer = this.props.box.customers.filter(
          (x) =>
            x &&
            x.orders &&
            x.orders.filter(
              (y) =>
                y &&
                y.stock_code.toString().toLowerCase() ===
                  pickupcode.toString().toLowerCase()
            ).length > 0
        )[0];
      } else {
        customer = this.props.box.customers.filter(
          (x) =>
            x &&
            x.orders &&
            x.orders.filter(
              (y) =>
                y &&
                y.stock_code.toString().toLowerCase() ===
                  pickupcode.toString().toLowerCase() &&
                y.type_id !== ASSIGN.STOCKED
            ).length > 0
        )[0];
      }

      let filteredArray = [];
      let order;
      if (customer) {
        if (mode === false || mode === null) {
          order = customer.orders.filter(
            (x) =>
              x &&
              x.stock_code.toString().toLowerCase() ===
                pickupcode.toString().toLowerCase()
          );
        } else {
          order = customer.orders.filter(
            (x) =>
              x &&
              x.stock_code.toString().toLowerCase() ===
                pickupcode.toString().toLowerCase() &&
              x.type_id !== ASSIGN.STOCKED
          );
        }
        customer.orders = order;
        filteredArray = [customer];
        if (order.length > 0) {
          prescriptions_master_id = order[0].id;
          this.setState({ showKeyboard: false });
        }
        this.setState({ puCode: order[0].pucode.toString().toUpperCase() });
      }

      this.setState({
        patientDetails: filteredArray,
        pickupCode: pickupcode,
        displayMsg: 'Please enter valid stock code.'
      });
    } else {
      this.setState({
        patientDetails: [],
        pickupCode: '',
        displayMsg: 'Please enter valid stock code.'
      });
    }
  };

  handleClickOpen = () => {
    pickupConfig.orders = [
      {
        orderId: '',
        pickupCode: '',
        items: []
      }
    ];
    this.state.patientDetails.forEach((x) => {
      pickupConfig.firstName = x.first_name;
      pickupConfig.lastName = x.last_name;
      // pickupConfig.emailId = x.username;
      pickupConfig.emailId = x.contacts && x.contacts[0].email;

      for (let i = 0; i < x.orders.length; i++) {
        openBin(x.orders[i].bin_id);
        pickupConfig.orderId = x.orders[i].id;
        for (let j = 0; j < x.orders[i].items.length; j++) {
          let orderItemItems = {};
          orderItemItems.rxNo = x.orders[i].items[j].rx_no;
          customerInfo.rxNo = x.orders[i].items[j].rx_no;
          orderItemItems.qty = x.orders[i].items[j].qty;
          orderItemItems.CoPayAmount = x.orders[i].items[j].price;
          pickupConfig.orders[0].items.push(orderItemItems);
          orderItemItems = null;
        }
        this.UpdateStatusOfRx(x.orders[0].id);
        setTimeout(() => {
          this.props.onDataSourceChange();
        }, 2000);
      }
      customerInfo.id = x.id;
      customerInfo.name = x.first_name + ' ' + x.last_name;
      customerInfo.patient_id = x.patient_id;
    });
    this.setState({ open: true });
  };

  // With async/await
  generateQR = async (text) => {
    let s3response = { Location: '' };

    try {
      pickupConfig.pickupcode = text;
      pickupConfig.qrCode = await QRCode.toDataURL(text);
      const type = pickupConfig.qrCode.split(';')[0].split('/')[1];
      const base64Data = new Buffer(
        pickupConfig.qrCode.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      );
      var params = {
        Bucket: AWSconfig.bucket, // Bucket name
        Key: uuidv4(), // type is not required
        Body: base64Data,
        ACL: 'public-read',
        ContentEncoding: 'base64', // required
        ContentType: `image/${type}` // required. Notice the back ticks
      };
      s3response = await s3.upload(params).promise();
      pickupConfig.qrCode = s3response.Location;
      pickupConfig.orderHtml = ReactDOMServer.renderToStaticMarkup(
        this.orderItemList()
      );
      pickupConfig.orderLocation = ReactDOMServer.renderToStaticMarkup(
        this.orderLocation()
      );
      pickupConfig.orderTotalHtml = ReactDOMServer.renderToStaticMarkup(
        this.orderItemListTotal()
      );

      sendAWSEmail(pickupConfig);
      const event_params = {
        event_name: EVENT_CRUD_TYPES.UPDATE,
        event_type_name: EVENT_TYPES.STOCKED,
        description:
          'Prescription is stocked with stock code ' +
          this.state.pickupCode +
          ' with Rx No ' +
          customerInfo.rxNo,
        object_id: ASSIGN.STOCKED,
        tofor_id: prescriptions_master_id,
        box_id: this.props.box.id
      };
      new EventLogHandler().handleEventLog(event_params);
    } catch (err) {
      console.error(err);
    }
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  handleClose = () => {
    if (this.props && this.props.box && this.props.box.customers) {
      document.querySelector('#' + this.state.SelectedInput).value = '';
      this.setState({ open: false, patientDetails: [], displayMsg: '' });
      setTimeout(() => {
        this.textInput.current.focus();
      }, 650);
      pickupConfig.boxName = this.props.box.name;
      pickupConfig.boxAddress =
        this.props.box.address !== null
          ? this.props.box.address.address_line_1 +
            ', ' +
            this.props.box.address.address_line_2 +
            ', ' +
            this.props.box.address.city +
            ', ' +
            this.props.box.address.state +
            ', ' +
            this.props.box.address.zip
          : '';
      if (this.props.box.account.picture_url) {
        pickupConfig.pharmAddress.picture_url =
          this.props.box.account.picture_url;
      } else {
        pickupConfig.pharmAddress.picture_url =
          'https://s3.amazonaws.com/rpx-dev-ext-images-qr/NoImageFound.png';
      }

      pickupConfig.pharmAddress.companyName = this.props.box.account.name;

      let mode;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        mode = fileData ? fileData.dynamicDataSource : false;
      } else {
        mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
      }

      if (mode === true || mode === 'true') {
        pickupConfig.supportPharmacyEmail =
          this.props &&
          this.props.box &&
          this.props.box.account &&
          this.props.box.account.brand &&
          this.props.box.account.brand.email;
      } else {
        pickupConfig.supportPharmacyEmail =
          this.props &&
          this.props.box &&
          this.props.box.account &&
          this.props.box.account.contact &&
          this.props.box.account.contact.email;
      }

      if (this.props.box.account.contact) {
        if (this.props.box.account.contact.address !== null) {
          if (
            this.props.box.account.contact.address.address_line_1 !== null &&
            this.props.box.account.contact.address.address_line_1 !==
              undefined &&
            this.props.box.account.contact.address.address_line_1 !== ''
          ) {
            pickupConfig.pharmAddress.adressLine1 =
              this.props.box.account.contact.address.address_line_1;
          }
          if (
            this.props.box.account.contact.address.address_line_2 !== null &&
            this.props.box.account.contact.address.address_line_2 !==
              undefined &&
            this.props.box.account.contact.address.address_line_2 !== ''
          ) {
            pickupConfig.pharmAddress.adressLine2 =
              this.props.box.account.contact.address.address_line_2;
          }
          if (
            this.props.box.account.contact.address.city !== null &&
            this.props.box.account.contact.address.city !== undefined &&
            this.props.box.account.contact.address.city !== ''
          ) {
            pickupConfig.pharmAddress.city =
              this.props.box.account.contact.address.city;
          }
          if (
            this.props.box.account.contact.address.state !== null &&
            this.props.box.account.contact.address.state !== undefined &&
            this.props.box.account.contact.address.state !== ''
          ) {
            pickupConfig.pharmAddress.state =
              this.props.box.account.contact.address.state;
          }
          if (
            this.props.box.account.contact.address.zip !== null &&
            this.props.box.account.contact.address.zip !== undefined &&
            this.props.box.account.contact.address.zip !== ''
          ) {
            pickupConfig.pharmAddress.zipcode =
              this.props.box.account.contact.address.zip;
          }
        }

        if (
          this.props.box.account.contact.phone !== null &&
          this.props.box.account.contact.phone !== undefined &&
          this.props.box.account.contact.phone !== ''
        ) {
          pickupConfig.pharmAddress.companyContact =
            this.props.box.account.contact.phone;
        }
      } else {
        pickupConfig.pharmAddress.adressLine1 = '';
        pickupConfig.pharmAddress.adressLine2 = '';
        pickupConfig.pharmAddress.city = '';
        pickupConfig.pharmAddress.state = '';
        pickupConfig.pharmAddress.zipcode = '';
        pickupConfig.pharmAddress.companyContact = '';
      }
      this.generateQR(this.state.puCode);
      this.keyboard.clearInput();
    }
  };

  // Added By Aya Belim
  // EPIC : DI-86 Pickup Email Notification
  // Purpose : Send Email wia AWS SES
  orderItemList() {
    return pickupConfig.orders[0].items.map(function (item) {
      //var ttlAmount = item.qty * item.CoPayAmount;
      return (
        <tr>
          <td
            valign="top"
            style={{
              borderBottom: '1px solid #E5E0DE',
              paddingRight: '0',
              paddingLeft: '0',
              paddingTop: '0',
              paddingBottom: '0',
              textAlign: 'center',
              msoLineHeightRule: 'exactly',
              msTextSizeAdjust: '100%',
              WebkitTextSizeAdjust: '100%'
            }}
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              width="100%"
              style={{
                maxWidth: '100%',
                minWidth: '100%',
                borderCollapse: 'collapse',
                msoTableLspace: '0pt',
                msoTableRspace: '0pt',
                msTextSizeAdjust: '100%',
                webkitTextSizeAdjust: '100%'
              }}
            >
              <tr>
                <td
                  valign="top"
                  style={{
                    paddingRight: '10px',
                    width: '60%',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    textAlign: 'center',
                    msoLineHeightRule: 'exactly',
                    msTextSizeAdjust: '100%',
                    webkitTextSizeAdjust: '100%'
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 'normal',
                      textAlign: 'left',
                      color: '#819695',
                      margin: '0'
                    }}
                  >
                    {item.rxNo}
                  </p>
                </td>
                <td
                  valign="top"
                  width="160"
                  style={{
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    textAlign: 'center',
                    msoLineHeightRule: 'exactly',
                    msTextSizeAdjust: '100%',
                    webkitTextSizeAdjust: '100%'
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: ' normal',
                      textAlign: 'left',
                      color: '#819695',
                      margin: '0'
                    }}
                  >
                    {item.qty}
                  </p>
                </td>
                <td
                  valign="top"
                  width="70"
                  style={{
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    textAlign: 'center',
                    msoLineHeightRule: 'exactly',
                    msTextSizeAdjust: '100%',
                    webkitTextSizeAdjust: '100%'
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 'normal',
                      textAlign: 'left',
                      color: '#819695',
                      margin: '0'
                    }}
                  >
                    {'$'}
                    {parseFloat(item.CoPayAmount).toFixed(2)}
                  </p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      );
    });
  }

  // Added By Dhaval PAndya
  // EPIC : DI-86 Pickup Email Notification
  // Purpose : Send Email wia AWS SES
  orderItemListTotal() {
    let totalAmount = 0;

    pickupConfig.orders[0].items.map(function (item) {
      //var RxAmount = item.CoPayAmount;
      return (totalAmount += item.CoPayAmount);
    });

    return (
      <tr>
        <td
          valign="top"
          style={{
            borderBottom: '1px solid #E5E0DE',
            paddingRight: '0',
            paddingLeft: '0',
            paddingTop: '0',
            paddingBottom: '0',
            textAlign: 'center',
            msoLineHeightRule: 'exactly',
            msTextSizeAdjust: '100%',
            webkitTextSizeAdjust: '100%'
          }}
        >
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            width="100%"
            style={{
              maxWidth: '100%',
              minWidth: '100%',
              borderCollapse: 'collapse',
              msoTableLspace: '0pt',
              msoTableRspace: '0pt',
              msTextSizeAdjust: '100%',
              webkitTextSizeAdjust: '100%'
            }}
          >
            <tr>
              <td
                valign="top"
                style={{
                  paddingRight: '10px',
                  width: '60%',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  textAlign: 'center',
                  msoLineHeightRule: 'exactly',
                  msTextSizeAdjust: '100%',
                  webkitTextSizeAdjust: '100%'
                }}
              >
                <p
                  style={{
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    textAlign: 'left',
                    color: '#819695',
                    margin: '0'
                  }}
                >
                  Total
                </p>
              </td>
              <td
                valign="top"
                width="160"
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  textAlign: 'center',
                  msoLineHeightRule: 'exactly',
                  msTextSizeAdjust: '100%',
                  webkitTextSizeAdjust: '100%'
                }}
              >
                <p
                  style={{
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: ' normal',
                    textAlign: 'left',
                    color: '#819695',
                    margin: '0'
                  }}
                />
              </td>
              <td
                valign="top"
                width="70"
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  textAlign: 'center',
                  msoLineHeightRule: 'exactly',
                  msTextSizeAdjust: '100%',
                  webkitTextSizeAdjust: '100%'
                }}
              >
                <p
                  style={{
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 'normal',
                    textAlign: 'left',
                    color: '#819695',
                    margin: '0'
                  }}
                >
                  {'$'}
                  {totalAmount}
                  {'.00'}
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    );
  }

  // Added By Aya Belim
  // EPIC : DI-86 Pickup Email Notification
  // Purpose : Send Email wia AWS SES
  orderLocation() {
    return (
      <td style={{ width: '60%', textAlign: 'left', lineHeight: '2px' }}>
        <p
          style={{
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '16px',
            lineHeight: '25px',
            fontWeight: 'normal',
            textAlign: 'left',
            color: '#4CA585',
            margin: '0'
          }}
        >
          Your items are located in <b>{pickupConfig.boxName}</b>
        </p>
        <p
          style={{
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: '16px',
            lineHeight: '25px',
            fontWeight: 'normal',
            textAlign: 'left',
            color: '#4CA585',
            margin: '0'
          }}
        >
          {pickupConfig.boxAddress}
        </p>
      </td>
    );
  }

  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  renderPatientName() {
    const patient = this.state && this.state.patientDetails[0];
    return patient.name;
  }

  renderCount() {
    const patient = this.state && this.state.patientDetails[0];
    const lengthCount = patient && patient.orders[0].items.length;
    return lengthCount;
  }
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }
  render() {
    const { classes, handleBack } = this.props;
    const { patientDetails, pickupCode, displayMsg } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div className={classNames(classes.container)}>
        <MuiThemeProvider theme={theme}>
          <TextField
            style={{ textAlign: 'center' }}
            id="pickupCode"
            name="pickupCode"
            className={classes.textField}
            defaultValue=""
            margin="normal"
            variant="outlined"
            placeholder="Scan or Enter your code here"
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            onChange={(e) => this.change(e)}
            autoFocus
            inputRef={this.textInput}
            onFocus={this.Focus.bind('pickupCode')}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.change(ev);
              }
            }}
          />
        </MuiThemeProvider>
        {this.state && this.state.patientDetails.length > 0 ? (
          <div>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableBody>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableEmptyCell}>
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    Patient
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {this.renderPatientName()}
                  </TableCell>
                </TableRow>
                {/* <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableEmptyCell}>
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    Code
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {this.state.puCode.toUpperCase()}
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableHead className={classNames(classes.TableHead)}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.RemoveIcon,
                      classes.cellBorder
                    )}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PatientClass,
                      classes.cellBorder
                    )}
                  >
                    NDC Code
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.CodeClass,
                      classes.cellBorder
                    )}
                  >
                    Drug Name
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    Rx No.
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    Qty
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(patientDetails && patientDetails).map(
                  (pres, i) => {
                    return patientDetails[pres].orders.map((order, idx) => {
                      return patientDetails[pres].orders[idx].items.map(
                        (task, idx) => {
                          return (
                            <TableRow
                              key={idx}
                              className={classes.PatientInfoTableRow}
                            >
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                &nbsp;
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.ndc_code}
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.drug_name}
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.rx_no}
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.qty}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      );
                    });
                  }
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classNames(classes.Instructiontitle)}>
            {displayMsg && displayMsg}
          </div>
        )}
        {this.state && this.state.patientDetails.length > 0 && (
          <Table className={classNames(classes.PatientInfoTable)}>
            <TableBody>
              <TableRow className={classes.PatientInfoTableRow}>
                <TableCell
                  colSpan={5}
                  className={classNames(
                    classes.PatientInfoTableEmptyCell,
                    classes.cellBorderInfo
                  )}
                >
                  Total RX in this code ({this.renderCount()})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            Back
          </Button>

          <img
            src={keyboardImg}
            style={{ height: '55px', marginTop: '1em', cursor: 'pointer' }}
            alt=""
            onClick={this.keyboardHandler}
          />

          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            Load
          </Button>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  Stock Confirmation
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                You have loaded code {pickupCode.toUpperCase()}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleClose}
                  className={classNames(classes.buttonColor)}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminPanelFormControls)
);
