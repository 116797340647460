import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskFeedbackMain.styles';
import { Button } from '@material-ui/core';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import StarRatingComponent from 'react-star-rating-component';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const messages = defineMessages({
  FeedbackTextUnHappy: {
    id: 'Feedback.FeedbackTextUnHappy',
    defineMessages: 'Unhappy '
  },
  FeedbackTextSomewhatUnhappy: {
    id: 'Feedback.FeedbackTextSomewhatUnhappy',
    defineMessages: 'Somewhat Unhappy'
  },
  FeedbackTextGood: {
    id: 'Feedback.FeedbackTextGood',
    defineMessages: 'Good'
  },
  FeedbackTextPrettyGood: {
    id: 'Feedback.FeedbackTextPrettyGood',
    defineMessages: 'Pretty Good'
  },
  FeedbackTextWow: {
    id: 'Feedback.FeedbackTextWow',
    defineMessages: 'Wow'
  }
});

export class KioskFeedbackMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      isEditable: false
    };
  }

  handleBack = () => {
    this.props.history.replace('/retail/verification');
  };

  onStarClick(nextValue, prevValue) {
    prevValue = nextValue - prevValue;
    if (prevValue === 0 && nextValue === 1) {
      nextValue--;
    }
    if (prevValue === 0 && nextValue === 5) {
      nextValue--;
    }
    this.capture();
    this.setState({ rating: nextValue, isEditable: true });
  }

  capture() {
    setTimeout(() => {
      this.handleBack();
    }, 1200);
  }

  static propTypes = {
    test: PropTypes.string
  };

  showRatingText = (rating) => {
    if (rating === 1) return messages.FeedbackTextUnHappy;
    if (rating === 2) return messages.FeedbackTextSomewhatUnhappy;
    if (rating === 3) return messages.FeedbackTextGood;
    if (rating === 4) return messages.FeedbackTextPrettyGood;
    if (rating === 5) return messages.FeedbackTextWow;
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classNames(classes.appLogoContainer)}>
          <img
            className={classNames(classes.appLogo)}
            alt="app-logo"
            src="/images/brands/Steps/Thank-You-Line.png"
          />
        </div>
        <div className={classNames(classes.title)}>
          <span className={classNames(classes.titleThankyou)}>
            <FormattedMessage
              id="Feedback.ThanksTitle"
              defaultMessage="Thank you!"
            />
          </span>
          <span className={classNames(classes.titleFeedback)}>
            <FormattedMessage
              id="Feedback.FeedbackTitle"
              defaultMessage="We are happy you chose us. Tell us how we did."
            />
          </span>
        </div>
        <div className={classNames(classes.ratingContainer)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              paddingBottom: '1%'
            }}
          >
            {Array.from(Array(5).keys()).map((idx) => (
              <React.Fragment key={idx}>
                <p
                  style={{
                    padding: '0',
                    margin: '0',
                    fontSize: '15px',
                    color: '#2F5597',
                    width: '15%',
                    textAlign: 'center'
                  }}
                >
                  {this.props.intl.formatMessage(this.showRatingText(idx + 1))}
                </p>
              </React.Fragment>
            ))}
          </div>
          <StarRatingComponent
            name="rate1"
            starColor=" #2F5597"
            emptyStarColor="#a0c1fd"
            starCount={5}
            value={this.state.rating}
            onStarClick={this.onStarClick.bind(this)}
            editing={!this.state.isEditable}
          />
        </div>
        <div className={classNames(classes.spacer)} />
        <div style={{ textAlign: 'center', height: '5vh' }}>
          <span className={classNames(classes.DoorConfirmation)}>
            <FormattedMessage
              id="Feedback.DoorConfirmation"
              defaultMessage="Make sure you have all your items and please close the door"
            />
          </span>
        </div>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            disabled={this.state.isEditable}
            onClick={this.handleBack}
          >
            <FormattedMessage id="Feedback.HomeAction" defaultMessage="Home" />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return {
    ...kiosk
  };
};

const KioskFeedbackFormC = connect(mapStateToProps)(KioskFeedbackMain);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(KioskFeedbackFormC))
);
