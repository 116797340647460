// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminLoginFormControls.styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import Keyboard from 'react-simple-keyboard';

const messages = defineMessages({
  NewPassword: {
    id: 'Patient.ChangePassword.NewPassword',
    defaultMessage: 'New Password'
  },
  ConfirmPassword: {
    id: 'Patient.ChangePassword.ConfirmPassword',
    defaultMessage: 'Confirm Password'
  },
  ResetPassword: {
    id: 'Patient.ChangePassword.ResetPassword',
    defaultMessage: 'Reset Password'
  },
  PasswordEightCharacters: {
    id: 'Patient.ChangePassword.PasswordEightCharacters',
    defaultMessage: '8 characters'
  },
  PasswordUpperCase: {
    id: 'Patient.ChangePassword.PasswordUpperCase',
    defaultMessage: 'Upper Case'
  },
  PasswordLowerCase: {
    id: 'Patient.ChangePassword.PasswordLowerCase',
    defaultMessage: 'Lower Case'
  },
  PasswordNumeric: {
    id: 'Patient.ChangePassword.PasswordNumeric',
    defaultMessage: 'Number'
  },
  PasswordSymbol: {
    id: 'Patient.ChangePassword.PasswordSymbol',
    defaultMessage: 'Symbol'
  },
  VerificationCode: {
    id: 'Patient.ChangePassword.VerificationCode',
    defaultMessage: 'Verification Code'
  },
  Cancel: {
    id: 'Patient.ForgotPassword.Cancel',
    defaultMessage: 'Cancel'
  }
});

export class AdminLoginForgotPassword extends Component {
  state = {
    maxlength: 100,
    SelectedInput: 'newpassword',
    layoutName: 'default',
    newpassword: '',
    confirmpassword: '',
    lowercase: false,
    uppercase: false,
    number: false,
    symbol: false,
    pwdlength: false,
    clowercase: false,
    cuppercase: false,
    cnumber: false,
    csymbol: false,
    cpwdlength: false,
    smsCode: ''
  };

  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(this.state.SelectedInput, event);
    }
  };
  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };
  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };
  change = (name, e) => {
    let input = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.keyboard.setInput(input);
    });
    this.props.handleChange(e);
    this.props.setFieldTouched(name, true, false);
    if (name === 'newpassword') {
      this.validationMsg(name);
    }
    if (name === 'confirmpassword') {
      this.cvalidationMsg(name);
    }
  };
  Focus = (obj) => {
    let ref = obj.target.name;
    if (this.keyboard) {
      this.setState({ SelectedInput: ref });
      this.setState({ maxlength: 100 });
      this.keyboard.setInput(document.querySelector('#' + ref).value);
      if (ref === 'newpassword') {
        let items = document.getElementById('validationMsg');
        items.style.display = 'block';
      }
      if (ref === 'confirmpassword') {
        let items = document.getElementById('cvalidationMsg');
        items.style.display = 'block';
      }
    }
  };
  validationMsg = (name) => {
    // Validate lowercase letters
    let lowerCaseLetters = /[a-z]/g;
    if (document.querySelector('#' + name).value.match(lowerCaseLetters)) {
      this.setState({ lowercase: true });
    } else {
      this.setState({ lowercase: false });
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (document.querySelector('#' + name).value.match(upperCaseLetters)) {
      this.setState({ uppercase: true });
    } else {
      this.setState({ uppercase: false });
    }
    // Validate numbers
    let numbers = /[0-9]/g;
    if (document.querySelector('#' + name).value.match(numbers)) {
      this.setState({ number: true });
    } else {
      this.setState({ number: false });
    }

    // Validate length
    if (document.querySelector('#' + name).value.length >= 8) {
      this.setState({ pwdlength: true });
    } else {
      this.setState({ pwdlength: false });
    }
    // At least one special character
    let symbols = /[!@#\\$%\\^&*()]/g;
    if (document.querySelector('#' + name).value.match(symbols)) {
      this.setState({ symbol: true });
    } else {
      this.setState({ symbol: false });
    }
  };
  cvalidationMsg = (name) => {
    // Validate lowercase letters
    let lowerCaseLetters = /[a-z]/g;
    if (document.querySelector('#' + name).value.match(lowerCaseLetters)) {
      this.setState({ clowercase: true });
    } else {
      this.setState({ clowercase: false });
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (document.querySelector('#' + name).value.match(upperCaseLetters)) {
      this.setState({ cuppercase: true });
    } else {
      this.setState({ cuppercase: false });
    }
    // Validate numbers
    let numbers = /[0-9]/g;
    if (document.querySelector('#' + name).value.match(numbers)) {
      this.setState({ cnumber: true });
    } else {
      this.setState({ cnumber: false });
    }

    // Validate length
    if (document.querySelector('#' + name).value.length >= 8) {
      this.setState({ cpwdlength: true });
    } else {
      this.setState({ cpwdlength: false });
    }
    // At least one special character
    let symbols = /[!@#\\$%\\^&*()]/g;
    if (document.querySelector('#' + name).value.match(symbols)) {
      this.setState({ csymbol: true });
    } else {
      this.setState({ csymbol: false });
    }
  };
  Blur = (obj) => {
    let ref = obj.target.name;
    if (ref === 'newpassword') {
      let items = document.getElementById('validationMsg');
      items.style.display = 'none';
    }
    if (ref === 'confirmpassword') {
      let items = document.getElementById('cvalidationMsg');
      items.style.display = 'none';
    }
    //this.setState({ SelectedInput: '' });
  };

  updateSMSCode = (e) => {
    e.preventDefault();
    this.setState({ smsCode: e.target.value });
  };

  render() {
    const { classes, intl } = this.props;
    const {
      values: { newpassword, confirmpassword },
      errors,
      touched,
      handleSubmit,
      isValid,
      onCancelClick
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit} className={classNames(classes.container)}>
          <TextField
            id="smsCode"
            name="smsCode"
            label={intl.formatMessage(messages.VerificationCode)}
            fullWidth
            autoFocus
            autoComplete="off"
            value={this.state.smsCode}
            onChange={this.change.bind(null, 'smsCode')}
            onFocus={this.Focus.bind('smsCode')}
          />
          <TextField
            id="newpassword"
            name="newpassword"
            label={intl.formatMessage(messages.NewPassword)}
            fullWidth
            type="password"
            autoComplete="new-password"
            autoCapitalize="false"
            defaultValue={newpassword}
            helperText={touched.newpassword ? errors.newpassword : ''}
            error={touched.newpassword && Boolean(errors.newpassword)}
            onChange={this.change.bind(null, 'newpassword')}
            onFocus={this.Focus.bind('newpassword')}
            onBlur={this.Blur.bind('newpassword')}
            value={this.state.newpassword}
          />
          <div id="validationMsg" style={{ display: 'none' }}>
            <span
              id="pwdlength"
              className={
                this.state.pwdlength
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.pwdlength ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              <b>
                {' '}
                {this.props &&
                  this.props.intl.formatMessage(
                    messages.PasswordEightCharacters
                  )}{' '}
              </b>
              &nbsp;
            </span>
            <span
              id="letter"
              className={
                this.state.lowercase
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.lowercase ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordLowerCase)}
              </b>
              &nbsp;
            </span>
            <span
              id="capital"
              className={
                this.state.uppercase
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.uppercase ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordUpperCase)}
              </b>
              &nbsp;
            </span>
            <span
              id="number"
              className={
                this.state.number
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.number ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordNumeric)}
              </b>
              &nbsp;
            </span>
            <span
              id="Symbol"
              className={
                this.state.symbol
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.symbol ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordSymbol)}
              </b>
            </span>
          </div>
          <TextField
            id="confirmpassword"
            name="confirmpassword"
            label={intl.formatMessage(messages.ConfirmPassword)}
            fullWidth
            type="password"
            autoComplete="new-password"
            autoCapitalize="false"
            defaultValue={confirmpassword}
            helperText={touched.confirmpassword ? errors.confirmpassword : ''}
            error={touched.confirmpassword && Boolean(errors.confirmpassword)}
            onChange={this.change.bind(null, 'confirmpassword')}
            onFocus={this.Focus.bind('confirmpassword')}
            onBlur={this.Blur.bind('confirmpassword')}
            value={this.state.confirmpassword}
          />
          <div id="cvalidationMsg" style={{ display: 'none' }}>
            <span
              id="cpwdlength"
              className={
                this.state.cpwdlength
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.cpwdlength ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              &nbsp;
              <b>
                {this.props &&
                  this.props.intl.formatMessage(
                    messages.PasswordEightCharacters
                  )}
              </b>
              &nbsp;
            </span>
            <span
              id="cletter"
              className={
                this.state.clowercase
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.clowercase ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              &nbsp;
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordLowerCase)}
              </b>
              &nbsp;
            </span>
            <span
              id="ccapital"
              className={
                this.state.cuppercase
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.cuppercase ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              &nbsp;
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordUpperCase)}
              </b>
              &nbsp;
            </span>
            <span
              id="cnumber"
              className={
                this.state.cnumber
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.cnumber ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              &nbsp;
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordNumeric)}
              </b>
              &nbsp;
            </span>
            <span
              id="cSymbol"
              className={
                this.state.csymbol
                  ? classNames(classes.valid)
                  : classNames(classes.invalid)
              }
            >
              {this.state.csymbol ? (
                <img src="/images/brands/check-circle-outline.png" alt="" />
              ) : (
                <img src="/images/brands/close-circle-outline.png" alt="" />
              )}
              &nbsp;
              <b>
                {this.props &&
                  this.props.intl.formatMessage(messages.PasswordSymbol)}
              </b>
            </span>
          </div>
          <div className={classNames(classes.buttonContainer)}>
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={
                !isValid
                  ? classNames(classes.backButton)
                  : classNames(classes.backButton)
              }
              disabled={!isValid}
            >
              {intl.formatMessage(messages.ResetPassword)}
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              className={classNames(classes.backButton)}
              onClick={onCancelClick}
            >
              {intl.formatMessage(messages.Cancel)}
            </Button>
          </div>
        </form>
        <div className={classNames(classes.simpleKeyboard)}>
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminLoginForgotPassword)
);
