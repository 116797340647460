// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminKioskVerifyFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl, defineMessages } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import { openBin, openMiniBins } from '../../../helpers/board';
import ASSIGN from '../../KioskLayout/assignmentStatus';
import keyboardImg from '../../AdminLayout/keyboard.png';
import EVENT_TYPES from '../../Common/event-type';
import EVENT_CRUD_TYPES from '../../Common/event-crud-types';
import EventLogHandler from '../../Common/event-log-handler';
import { readSettings, forceBinStatusCheck } from '../../../helpers/board';
import BOX_TYPE from '../../Common/box_type_enum';
import { syncMapping } from '../../Common/mini22_bins_mapping';

const messages = defineMessages({
  Patient: {
    id: 'Admin.Stock.Patient',
    defineMessages: 'Patient'
  },
  OK: {
    id: 'Admin.Stock.OK',
    defineMessages: 'OK'
  },
  OnHold: {
    id: 'Admin.Verify.OnHold',
    defineMessages: 'This Prescription is already put on Hold.'
  },
  Code: {
    id: 'Admin.Verify.Code',
    defineMessages: 'Code'
  },
  VerifyAction: {
    id: 'Admin.Verify.VerifyAction',
    defineMessages: 'Verify'
  },
  ScanConfirmation: {
    id: 'Admin.Verify.ScanConfirmation',
    defineMessages: 'Scan confirmation'
  },
  DoItemMatch: {
    id: 'Admin.Verify.DoItemMatch',
    defineMessages: 'Do the items match?'
  },
  OnHoldWarning: {
    id: 'Admin.Verify.OnHoldWarning',
    defineMessages:
      'This code is on hold, please remove it and return it to the pharmacy.'
  },
  YesAction: {
    id: 'Admin.Verify.YesAction',
    defineMessages: 'Yes'
  },
  NoAction: {
    id: 'Admin.Verify.NoAction',
    defineMessages: 'No'
  },
  ValidStockCode: {
    id: 'Admin.Stock.ValidStockCode',
    defineMessages: 'Please enter valid stock code.'
  },
  ScanOrEnterStockCode: {
    id: 'Admin.Stock.ScanOrEnterStockCode',
    defineMessages: 'Scan or Enter your code here'
  },
  Back: {
    id: 'Admin.Stock.Back',
    defineMessages: 'Back'
  },
  InvoiceNumber: {
    id: 'Retail.Stock.InvoiceNumber',
    defineMessages: 'Invoice Number'
  },
  ItemName: {
    id: 'Retail.Stock.ItemName',
    defineMessages: 'Item Name'
  },
  TotalItem: {
    id: 'Retail.Stock.TotalItem',
    defineMessages: 'Total Item'
  }
});
export class AdminKioskFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      patientDetails: [],
      stockCode: '',
      displayMsg: '',
      maxlength: 6,
      openOnReject: false,
      layoutName: 'default',
      SelectedInput: 'stockCode',
      showKeyboard: false,
      pickupCode: '',
      box_id: '',
      binIds: '',
      openDoorDialog: false,
      isStatusCheckFailed: false,
      progressMessage: '',
      boxTypeId: '',
      isFastoInProcess: false
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleYes = (e) => {
    const { boxTypeId } = this.state;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    document.querySelector('#' + this.state.SelectedInput).value = '';
    if (mode === true || mode === 'true') {
      const event_params = {
        event_name: EVENT_CRUD_TYPES.UPDATE,
        event_type_name: EVENT_TYPES.VERFIY_RX,
        description:
          'Prescription is verified with stock code ' + this.state.stockCode,
        object_id: ASSIGN.STOCKED,
        tofor_id: this.state.pm_id,
        box_id: this.props.box.id
      };
      new EventLogHandler().handleEventLog(event_params);
      if (boxTypeId === BOX_TYPE.FASTLANE) {
        this.fnHandleSlidingDoorOpenCloseEvent(e, false);
      }
    }
    if (boxTypeId === BOX_TYPE.FASTLANE) {
      this.setState({
        stockCode: '',
        displayMsg: '',
        open: false
      });
    } else {
      this.setState({
        patientDetails: [],
        stockCode: '',
        displayMsg: '',
        open: false
      });
    }
    setTimeout(() => {
      this.textInput.current.focus();
    }, 650);
    this.keyboard.clearInput();
  };

  handleNo = (e) => {
    const { boxTypeId, patientDetails } = this.state;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    // Set Bin stataus of bin to 'Hold'  code will be here
    if (this.props && this.props.box && this.props.box.customers) {
      const filteredArray = this.props.box.customers.filter((element) =>
        element.orders.some(
          (subElement) =>
            subElement.stock_code.toString().toLowerCase() ===
              this.state.stockCode.toString().toLowerCase() &&
            subElement.type_id === ASSIGN.STOCKED
        )
      );
      let token = '';
      if (
        ![undefined, 'undefined', null, 'null', ''].includes(
          sessionStorage.getItem('jwtToken')
        )
      ) {
        token = JSON.parse(sessionStorage.getItem('jwtToken'));
      } else {
        token = atob(localStorage.getItem('authTokens'));
      }
      const misMatchedEventParams = {
        event_name: EVENT_CRUD_TYPES.UPDATE,
        event_type_name: EVENT_TYPES.VERFIY_RX,
        description:
          'Prescription verification mismatched for stock code ' +
          this.state.stockCode,
        object_id: ASSIGN.HOLD,
        tofor_id:
          patientDetails &&
          patientDetails[0] &&
          patientDetails[0].orders &&
          patientDetails[0].orders[0] &&
          patientDetails[0].orders[0].id,
        box_id: this.props.box.id,
        authorization: token,
        isMisMatched: true
      };

      const event_params = {
        event_name: EVENT_CRUD_TYPES.UPDATE,
        event_type_name: EVENT_TYPES.REMOVE_RX,
        description:
          'Prescription verification is failed with stock code ' +
          this.state.stockCode,
        object_id: ASSIGN.REMOVE_RX,
        tofor_id:
          patientDetails &&
          patientDetails[0] &&
          patientDetails[0].orders &&
          patientDetails[0].orders[0] &&
          patientDetails[0].orders[0].id,
        box_id: this.props.box.id,
        authorization: token,
        isMisMatched: false,
        misMatchEventParams: misMatchedEventParams
      };
      let reqDetails = {};
      if (this.props && this.props.box) {
        reqDetails = {
          box_id: this.state.box_id,
          pucode: this.state.pickupCode,
          onHold: true
        };
      }
      if (filteredArray.length > 0) {
        const updateStatusOfRxObj = {
          id:
            patientDetails &&
            patientDetails[0] &&
            patientDetails[0].orders &&
            patientDetails[0].orders[0] &&
            patientDetails[0].orders[0].id,
          type_id: ASSIGN.REMOVE_RX,
          event_params: event_params,
          email_data: reqDetails
        };
        document.querySelector('#' + this.state.SelectedInput).value = '';
        setTimeout(() => {
          this.textInput.current.focus();
        }, 650);

        if (mode === true || mode === 'true') {
          fetch(process.env.REACT_APP_BASEURL + 'updateStatusOfRx', {
            method: 'post',
            withCredentials: true,
            headers: {
              Authorization:
                'Bearer ' +
                JSON.parse(sessionStorage.getItem('user')).signInUserSession
                  .idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateStatusOfRxObj)
          })
            .then((response) => {
              try {
                return response.text();
              } catch (error) {
                return null;
              }
            })
            .then((RxObj) => {
              // const RxUpdatedObj = JSON.parse(RxObj);
              this.setState({ openOnReject: true });
            });
        }
      }

      document.querySelector('#' + this.state.SelectedInput).value = '';
      if (boxTypeId === BOX_TYPE.FASTLANE) {
        this.setState({
          stockCode: '',
          displayMsg: '',
          open: false
        });
      } else {
        this.setState({
          patientDetails: [],
          stockCode: '',
          displayMsg: '',
          open: false
        });
      }
      setTimeout(() => {
        this.textInput.current.focus();
      }, 650);
    }
    this.keyboard.clearInput();
  };

  handleRejectOk = (e) => {
    const { boxTypeId } = this.state;
    if (boxTypeId === BOX_TYPE.FASTLANE) {
      this.fnHandleSlidingDoorOpenCloseEvent(e, false);
      this.setState({
        stockCode: '',
        displayMsg: '',
        open: false,
        openOnReject: false
      });
    } else {
      this.setState({
        patientDetails: [],
        stockCode: '',
        displayMsg: '',
        open: false,
        openOnReject: false
      });
    }
  };

  onChange = (input) => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };

  onKeyPress = (button) => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };

  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };

  change = (event) => {
    const { boxTypeId } = this.state;
    let mode;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      mode = fileData ? fileData.dynamicDataSource : false;
      let isHWCommuniocationEnabled = fileData
        ? fileData.isHWCommuniocationEnabled
        : true;
      if (isHWCommuniocationEnabled) {
        forceBinStatusCheck();
      }
    } else {
      mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    const stockCode = event.target.value;
    if (this.props && this.props.box) {
      let customer;
      customer = this.props.box.customers.filter(
        (x) =>
          x &&
          x.orders &&
          x.orders.filter(
            (y) =>
              y &&
              y.stock_code.toString().toLowerCase() ===
                stockCode.toString().toLowerCase()
          ).length > 0
      )[0];

      let filteredArray = [];

      if (customer) {
        const order = customer.orders.filter(
          (x) =>
            x &&
            x.stock_code.toString().toLowerCase() ===
              stockCode.toString().toLowerCase()
        );
        customer.orders = order;
        filteredArray = [customer];
        if (order.length > 0) {
          this.setState({ showKeyboard: false });
        }
        if (boxTypeId !== BOX_TYPE.FASTLANE) {
          if (filteredArray[0].orders[0].type_id === ASSIGN.HOLD) {
            console.log('Hold');
            this.setState({
              box_id: '',
              pickupCode: '',
              patientDetails: '',
              stockCode: '',
              displayMsg:
                this.props && this.props.intl.formatMessage(messages.OnHold)
            });
          } else {
            if (
              this.props &&
              this.props.box &&
              this.props.box.type_id === BOX_TYPE.MAIN_46
            ) {
              openBin(order[0].bin_id);
            } else {
              let eleBin = syncMapping(order[0].bin_id);
              openMiniBins(eleBin);
            }
            if (order.length < 1) {
              console.log('Empty');
              this.setState({
                box_id: '',
                pickupCode: '',
                patientDetails: '',
                stockCode: '',
                displayMsg:
                  this.props &&
                  this.props.intl.formatMessage(messages.ValidStockCode)
              });
            }
            this.setState({
              box_id: this.props && this.props.box ? this.props.box.id : '',
              pickupCode: filteredArray[0].orders[0].pucode,
              patientDetails: filteredArray,
              stockCode: stockCode,
              pm_id: filteredArray[0].orders[0].id,
              displayMsg:
                this.props &&
                this.props.intl.formatMessage(messages.ValidStockCode)
            });
          }
        } else {
          this.setState(
            {
              box_id: this.props && this.props.box ? this.props.box.id : '',
              pickupCode: filteredArray[0].orders[0].pucode,
              patientDetails: filteredArray,
              stockCode: stockCode,
              pm_id: filteredArray[0].orders[0].id,
              displayMsg:
                this.props &&
                this.props.intl.formatMessage(messages.ValidStockCode)
            },
            () => {
              if (stockCode.length === 6 && [true, 'true'].includes(mode)) {
                this.handleProcessClick();
              }
            }
          );
        }
      }
    }
  };

  Focus = (obj) => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  renderPatientName() {
    const patient = this.state && this.state.patientDetails[0];
    return patient.email;
  }

  renderCount() {
    const patient = this.state && this.state.patientDetails[0];
    const lengthCount = patient && patient.orders.length;
    return lengthCount;
  }
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }

  async componentDidMount() {
    const _boxTypeId = this.props && this.props.box && this.props.box.type_id;
    this.setState({ boxTypeId: _boxTypeId });
  }

  render() {
    const { classes, handleBack } = this.props;
    const { patientDetails, stockCode, displayMsg } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div className={classNames(classes.container)}>
        <MuiThemeProvider theme={theme}>
          <TextField
            style={{ textAlign: 'center' }}
            id="stockCode"
            name="stockCode"
            className={classes.textField}
            defaultValue=""
            margin="normal"
            variant="outlined"
            placeholder={
              this.props &&
              this.props.intl.formatMessage(messages.ScanOrEnterStockCode)
            }
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            onChange={(e) => this.change(e)}
            autoFocus
            inputRef={this.textInput}
            onFocus={this.Focus.bind('stockCode')}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.change(ev);
              }
            }}
          />
        </MuiThemeProvider>
        {this.state && this.state.patientDetails.length > 0 ? (
          <div>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableBody>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    {this.props &&
                      this.props.intl.formatMessage(messages.Patient)}
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {this.renderPatientName()}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    {this.props && this.props.intl.formatMessage(messages.Code)}
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {stockCode.toUpperCase()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableHead className={classNames(classes.TableHead)}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.CodeClass,
                      classes.cellBorder
                    )}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(messages.InvoiceNumber)}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    {this.props &&
                      this.props.intl.formatMessage(messages.ItemName)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientDetails &&
                  patientDetails[0].orders.map((item) => {
                    return (
                      <TableRow
                        className={classes.PatientInfoTableRow}
                        key={item.invoice_id}
                      >
                        <TableCell className={classNames(classes.fontSizes)}>
                          {item.invoice_id}
                        </TableCell>
                        <TableCell className={classNames(classes.fontSizes)}>
                          {item.item}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classNames(classes.Instructiontitle)}>
            {displayMsg && displayMsg}
          </div>
        )}
        {this.state && this.state.patientDetails.length > 0 && (
          <Table className={classNames(classes.PatientInfoTable)}>
            <TableBody>
              <TableRow className={classes.PatientInfoTableRow}>
                <TableCell
                  colSpan={5}
                  className={classNames(
                    classes.PatientInfoTableEmptyCell,
                    classes.cellBorderInfo
                  )}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.TotalItem)}
                  ({this.renderCount()})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            {this.props && this.props.intl.formatMessage(messages.Back)}
          </Button>

          <img
            src={keyboardImg}
            className={classNames(classes.keyboardImg)}
            alt=""
            onClick={this.keyboardHandler}
          />
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            {this.props && this.props.intl.formatMessage(messages.VerifyAction)}
          </Button>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.ScanConfirmation)}
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                {this.props &&
                  this.props.intl.formatMessage(messages.DoItemMatch)}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleYes}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.YesAction)}
                </Button>
                <Button
                  onClick={this.handleNo}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props &&
                    this.props.intl.formatMessage(messages.NoAction)}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.openOnReject}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  {this.props &&
                    this.props.intl.formatMessage(messages.ScanConfirmation)}
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                {this.props &&
                  this.props.intl.formatMessage(messages.OnHoldWarning)}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleRejectOk}
                  className={classNames(classes.buttonColor)}
                >
                  {this.props && this.props.intl.formatMessage(messages.OK)}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={(input) => this.onChange(input)}
            onKeyPress={(button) => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminKioskFormControls)
);
