import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import AdminMaintenanceFormContainer from './AdminMaintenanceFormContainer';
import AdminSideSlides from '../../AdminLayout/AdminSideSlides';
import { connect } from 'react-redux';
import KioskIdelScreen from '../../KioskLayout/KioskIdelScreen';

const messages = defineMessages({
  WhatToDo: {
    id: 'Admin.Panel.WhatToDo',
    defineMessages: 'What are you here to do?'
  }
});

export class AdminMaintenanceLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: this.props && this.props.intl.formatMessage(messages.WhatToDo),
    height: 300
  };

  render() {
    return (
      <>
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminMaintenanceFormContainer />
        <KioskIdelScreen />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const kiosk = state.get('kiosk');
  return { ...kiosk };
};

export default connect(mapStateToProps)(injectIntl(AdminMaintenanceLayout));
