const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center !important'
    },
    appLogoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      height: '100px'
    },
    appLogo: {
      width: '140px'
    },
    appName: {
      fontSize: '2em',
      margin: '9px',
      display: 'none'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    Instructiontitle: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center'
    },
    form: {
      width: '100%'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    leftFormContent: {
      width: '50%'
    }
  };
};

export default styles;
