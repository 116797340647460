// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import styles from './AdminPanelFormControls.styles';
import Image1 from './AdminPanelImages/1.png';
import Image2 from './AdminPanelImages/2.png';
import Image3 from './AdminPanelImages/3.png';
import Image4 from './AdminPanelImages/4.png';
import tools from './AdminPanelImages/maintenance.png';
import exit from './AdminPanelImages/exit.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import { writeKioskLog } from '../../Common/SyncAPIs';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('iLocalBox');
const permissionEnum = require('../../Common/permissions').default;

export class AdminPanelFormControls extends Component {
  constructor(props) {
    super(props);
    this.authClient = window.oktaAuthClient;
  }

  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };
  state = {
    DialogOpen: false,
    rolepermissionmenu: false,
    stockpermission: false,
    removepermission: false,
    verifypermission: false,
    maintenancepermission: false
  };

  componentDidMount() {
    let role = localStorage.getItem('permissionUser');
    if (role === 'true') {
      this.setState({ rolepermissionmenu: true });
    }
    let permissionsenc = localStorage.getItem('permissions');
    if (
      permissionsenc !== 'null' &&
      permissionsenc !== null &&
      permissionsenc !== '' &&
      permissionsenc !== undefined &&
      permissionsenc !== 'undefined' &&
      permissionsenc !== '[]'
    ) {
      const decryptedString = cryptr.decrypt(permissionsenc);
      const permdecrypted = JSON.parse(decryptedString);
      permdecrypted.forEach((permissionObject) => {
        switch (permissionObject.permission_id) {
          case permissionEnum.Stock:
            this.setState({ stockpermission: true });
            break;
          case permissionEnum.DeStock:
            this.setState({ removepermission: true });
            break;
          case permissionEnum.ValidateRx:
            this.setState({ verifypermission: true });
            break;
          case permissionEnum.DeviceMaintenance:
            this.setState({ maintenancepermission: true });
            break;
          default:
            break;
        }
      });
    }
    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,
      box_id: this.props.box.id,
      description: `User logged In.`
    };
    if (this.props.box && this.props.box.is_kiosk_log) {
      writeKioskLog(audit_obj);
    }
  }

  handleClose = (event) => {
    event.preventDefault();
    this.setState({
      DialogOpen: false
    });
  };

  handleOpen = (event) => {
    //event.preventDefault();
    this.setState({
      DialogOpen: true
    });
  };
  handleStock = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminStock');
      this.props.history.push('/retail/AdminStock');
    }, 30);
  };
  handleRemove = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminRemove');
      this.props.history.push('/retail/AdminRemove');
    }, 30);
  };

  handleVerify = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminKioskVerify');
      this.props.history.push('/retail/AdminKioskVerify');
    }, 30);
  };
  handleSetting = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminSettings');
      this.props.history.push('/retail/AdminSettings');
    }, 30);
  };
  handleMaintenance = () => {
    setTimeout(() => {
      this.props.history.push('/retail/AdminMaintenance');
      this.props.history.push('/retail/AdminMaintenance');
    }, 30);
  };
  handleExit = () => {
    if (this.authClient) {
      this.authClient.signOut({
        postLogoutRedirectUri: `${window.location.origin}/`
      });
    } else {
      setTimeout(() => {
        this.props.history.push('/');
        this.props.history.push('/');
      }, 30);
    }

    const audit_obj = {
      user_id: JSON.parse(sessionStorage.getItem('user')).username,
      box_id: this.props.box.id,
      description: `User logged out.`
    };
    if (this.props.box && this.props.box.is_kiosk_log) {
      writeKioskLog(audit_obj);
    }
  };

  renderStock = (classes) => {
    if (this.state.rolepermissionmenu === false) {
      if (this.state.stockpermission === true) {
        return (
          <div onClick={this.handleStock}>
            {/* to="/AdminStock"> */}
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image1}
                alt="Stock"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image1}
              onClick={(event) => this.handleOpen(event)}
              alt="Stock"
            />
          </div>
        );
      }
    } else {
      return (
        // <Link to="/AdminStock">
        <div onClick={this.handleStock}>
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image1}
              alt="Stock"
            />
          </div>
        </div>
      );
    }
  };

  renderRemove = (classes) => {
    if (this.state.rolepermissionmenu === false) {
      if (this.state.removepermission === true) {
        return (
          // <Link to="/AdminRemove">
          <div onClick={this.handleRemove}>
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image4}
                alt="Remove"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image4}
              onClick={(event) => this.handleOpen(event)}
              alt="Remove"
            />
          </div>
        );
      }
    } else {
      return (
        // <Link to="/AdminRemove">
        <div onClick={this.handleRemove}>
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image4}
              alt="Remove"
            />
          </div>
        </div>
      );
    }
  };

  renderVerify = (classes) => {
    if (this.state.rolepermissionmenu === false) {
      if (this.state.verifypermission === true) {
        return (
          // <Link to="/AdminKioskVerify">
          <div onClick={this.handleVerify}>
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={Image2}
                alt="Verify"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image2}
              onClick={(event) => this.handleOpen(event)}
              alt="Verify"
            />
          </div>
        );
      }
    } else {
      return (
        // <Link to="/AdminKioskVerify">
        <div onClick={this.handleVerify}>
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={Image2}
              alt="Verify"
            />
          </div>
        </div>
      );
    }
  };

  renderSetting = (classes) => {
    return (
      // <Link to="/AdminSettings">
      <div onClick={this.handleSetting}>
        <div className={classNames(classes.flexItem)}>
          <img
            className={classNames(classes.imgProperty)}
            src={Image3}
            alt="Settings"
          />
        </div>
      </div>
    );
  };

  renderMaintenance = (classes) => {
    if (this.state.rolepermissionmenu === false) {
      if (this.state.maintenancepermission === true) {
        return (
          // <Link to="/AdminMaintenance">
          <div onClick={this.handleMaintenance}>
            <div className={classNames(classes.flexItem)}>
              <img
                className={classNames(classes.imgProperty)}
                src={tools}
                alt="Maintenance"
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={tools}
              onClick={(event) => this.handleOpen(event)}
              alt="Maintenance"
            />
          </div>
        );
      }
    } else {
      return (
        // <Link to="/AdminMaintenance">
        <div onClick={this.handleMaintenance}>
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={tools}
              alt="Maintenance"
            />
          </div>
        </div>
      );
    }
  };

  renderExit = (classes) => {
    return (
      <div
        className={classNames(classes.flexItem)}
        //  onClick={closeApplication}
        onClick={this.handleExit}
      >
        <img
          className={classNames(classes.imgProperty)}
          src={exit}
          alt="Exit"
        />
      </div>
    );
  };

  render() {
    const { DialogOpen } = this.state;
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        {/*  =========================   For Rear Stock Screen   ========================= */}
        <div className={classNames(classes.navContainer)}>
          <div className={classNames(classes.navItem)}>
            {/* <Link to="/AdminStock"> */}
            <div onClick={this.handleStock}>
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image1}
                  alt="Stock"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage id="Admin.Panel.Stock" defineMessage="Stock" />
            </p>
          </div>

          <div className={classNames(classes.navItem)}>
            {/* <Link to="/AdminKioskVerify"> */}
            <div onClick={this.handleVerify}>
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image2}
                  alt="Verify"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage
                id="Admin.Panel.Verify"
                defineMessage="Verify"
              />
            </p>
          </div>

          <div className={classNames(classes.navItem)}>
            {/* <Link to="/AdminMaintenance"> */}
            <div onClick={this.handleMaintenance}>
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={tools}
                  alt="Maintenance"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage
                id="Admin.Panel.Maintenance"
                defineMessage="Maintenance"
              />
            </p>
          </div>

          <div className={classNames(classes.navItem)}>
            {/* <Link to="/AdminRemove"> */}
            <div onClick={this.handleRemove}>
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image4}
                  alt="Remove"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage
                id="Admin.Panel.Remove"
                defineMessage="Remove"
              />
            </p>
          </div>

          <div className={classNames(classes.navItem)}>
            {/* <Link to="/AdminSettings"> */}
            <div onClick={this.handleSetting}>
              <div className={classNames(classes.navItemTop)}>
                <img
                  src={Image3}
                  alt="Settings"
                  className={classNames(classes.navItemTopImg)}
                />
              </div>
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage
                id="Admin.Panel.Settings"
                defineMessage="Settings"
              />
            </p>
          </div>

          <div className={classNames(classes.navItem)}>
            <div
              className={classNames(classes.navItemTop)}
              // onClick={closeApplication}
              onClick={this.handleExit}
            >
              <img
                src={exit}
                alt="Exit"
                className={classNames(classes.navItemTopImg)}
              />
            </div>
            <p className={classNames(classes.navItemText)}>
              <FormattedMessage id="Admin.Panel.Exit" defineMessage="Exit" />
            </p>
          </div>
        </div>

        {/*  =========================   For Kiosk Screen   ========================= */}
        <div className={classNames(classes.flexContainer)}>
          {this.renderStock(classes)}
          {this.renderRemove(classes)}
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage id="Admin.Panel.Stock" defineMessage="Stock" />
          </div>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage id="Admin.Panel.Remove" defineMessage="Remove" />
          </div>
        </div>

        <div className={classNames(classes.flexContainer)}>
          {this.renderVerify(classes)}
          {this.renderSetting(classes)}
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage id="Admin.Panel.Verify" defineMessage="Verify" />
          </div>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage
              id="Admin.Panel.Settings"
              defineMessage="Settings"
            />
          </div>
        </div>

        <div className={classNames(classes.flexContainer)}>
          {this.renderMaintenance(classes)}
          {this.renderExit(classes)}
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage
              id="Admin.Panel.Maintenance"
              defineMessage="Maintenance"
            />
          </div>
          <div className={classNames(classes.flexTemText)}>
            <FormattedMessage id="Admin.Panel.Exit" defineMessage="Exit" />
          </div>
        </div>
        <div>
          <Dialog
            fullWidth={true}
            width={'lg'}
            open={DialogOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div style={{ background: 'transperent' }}>
              <DialogContent>
                <DialogContentText
                  style={{
                    color: '#77818D',
                    textAlign: 'center',
                    fontSize: '1.9em',
                    fontWeight: '500'
                  }}
                  id="alert-dialog-description"
                >
                  <p style={{ fontSize: '30px' }}>
                    {' '}
                    <FormattedMessage
                      id="Admin.Panel.NoPermission"
                      defineMessage="You do not have sufficient permission. Please contact system administrator."
                    />
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={(event) => this.handleClose(event)}
                  color="primary"
                  style={{ color: 'black', fontSize: '1.9em' }}
                >
                  <FormattedMessage id="Admin.Panel.OK" defineMessage="OK" />
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminPanelFormControls))
);
