export default {
  Stock: '4e6023e0-1b73-475b-8e6d-43d9a937c699',
  DeStock: 'be2e8bd3-ddb3-461e-b208-0c3e2e677753',
  ValidateRx: '74836d29-dfe7-4084-857b-9a3d5a6899a2',
  DeviceMaintenance: '0202050d-ecf1-4ab5-84b0-4b5cd6707fbe',
  OpenDoors: 'a0a79c78-0b49-4508-bdce-f86e24b89c43',
  DeactivateBin: 'ac2e8edd-d901-4bb8-ad8b-611f9a308b82',
  ActivateBin: '3a11688d-868e-4fae-8bcb-1cd7bc297f9a',
  FrontDoorAccess: 'b3e8fba2-7170-4214-a6e9-6e2f3932f197',
  RightDoorAccess: '335cc9af-5c5d-4269-9e0e-e84133f191b0',
  LeftDoorAccess: '0f8fc54a-833a-47fc-be21-3725e3c28898'
};
