import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import styles from './box-layout.style';
import { openBin, openMiniBins } from '../../../helpers/board';
import BOX_TYPE from '../../Common/box_type_enum';

let nums1 = Array.from(Array(12).keys());
let nums3 = Array.from(Array(3).keys());
let deactivatedBin = [];
var selectedBins = [];
let binbackground = [];

export class Hd36Layout extends Component {
  handleChangeIndex = (index) => {
    this.setState({
      selectedUnit: index
    });
    this.props.getSelectedUnitName(index);
  };

  componentWillReceiveProps() {
    const { deactivatedBins } = this.props;
    if (deactivatedBins && deactivatedBins.length > 0) {
      deactivatedBins.map((item) => {
        if (item) {
          deactivatedBin.push(item);
          var parentNode = document.getElementById(item).parentNode;
          var x = parentNode.classList && parentNode.classList[2];
          parentNode.classList.remove(x);
          parentNode.style.pointerEvents = 'none';
          var items = document.getElementById(item);
          items.style.background =
            'linear-gradient(155deg, rgb(253, 253, 252) 0%, rgb(249, 27, 27) 90%)';
          items.style.color = '#fff';
        }
        return true;
      });
    } else {
      deactivatedBin.map((item) => {
        if (item) {
          var items = document.getElementById(item);
          items.style.background =
            'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
          items.style.color = '#000';
          items.style.cursor = 'pointer';
          items.style.pointerEvents = 'all';
        }
        return true;
      });
      deactivatedBin = [];
    }
  }

  openbin = (binId) => {
    const { boxTypeId } = this.props;
    if (boxTypeId === BOX_TYPE.M_22) {
      openMiniBins(binId);
    } else {
      openBin(binId);
    }
    selectedBins.push(binId);
    var items = document.getElementById(binId);
    let obj = {
      Id: binId,
      background: items.style.background
    };
    binbackground.push(obj);
    items.style.background = 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    this.props.Selectedbin(binId);
  };

  getColumns = (
    currentIndex,
    currentColumnCount,
    previousColumnCount,
    side
  ) => {
    // getColumns(1, 2, 0, ‘left’) should return columns
    let columns = [];
    if (side === 'L') {
      for (
        let i = previousColumnCount + currentColumnCount;
        i > previousColumnCount;
        i--
      ) {
        columns.push(
          String.fromCharCode(65 + (i - 1)) + String.fromCharCode(65 + (i - 1))
        );
      }
    } else {
      for (
        let i = previousColumnCount;
        i < previousColumnCount + currentColumnCount;
        i++
      ) {
        columns.push(String.fromCharCode(65 + (i - 1)));
      }
    }
    return columns;
  };

  getCurrentAndPreviousPosition = (side) => {
    const { expansion, ExpansionsCounts, boxTypeId } = this.props;
    let response = {};
    response.currentCount = expansion.bin_column_count;
    if (side === 'L') {
      let _expansionsCount =
        ExpansionsCounts && ExpansionsCounts.leftExpansions;
      if (expansion.box_position === -1 || expansion.box_position === '-1') {
        response.previousCount = 0;
      } else {
        response.previousCount = this.excludeCurrentExpansion(
          expansion,
          _expansionsCount
        );
      }
    } else {
      let _expansionsCount = ExpansionsCounts.rightExpansions;
      if (expansion.box_position === 1 || expansion.box_position === '1') {
        if (boxTypeId === BOX_TYPE.MAIN_46) {
          response.previousCount = 6;
        } else {
          response.previousCount = 4;
        }
      } else {
        if (boxTypeId === BOX_TYPE.MAIN_46) {
          response.previousCount =
            this.excludeCurrentExpansion(expansion, _expansionsCount) + 6;
        } else {
          response.previousCount =
            this.excludeCurrentExpansion(expansion, _expansionsCount) + 4;
        }
      }
    }
    return response;
  };

  excludeCurrentExpansion = (expansion, expansionArray) => {
    let currentUnitIndex = null;
    let sumOfColumns = 0;
    for (let index = 0; index < expansionArray.length; index++) {
      if (expansionArray[index].expansionId === expansion.id) {
        currentUnitIndex = index;
        break;
      }
    }
    for (let i = 0; i < currentUnitIndex; i++) {
      if (expansionArray[i - 1] !== undefined) {
        sumOfColumns =
          expansionArray[i].bin_column_count +
          expansionArray[i - 1].bin_column_count;
      } else {
        sumOfColumns = expansionArray[i].bin_column_count;
      }
    }
    return sumOfColumns;
  };

  render() {
    // const BoxStatus = {
    //   ASSIGNED: '8f9c8e02-b9ee-4f29-aeea-670cef022663',
    //   STOCKED: '4ee90c0f-d4c3-4d53-8014-d7eac4ea2894',
    //   HOLD: '45593c2c-fc8c-4bf0-854e-e2bf759b7788',
    //   PICKED_UP: '00fe15e3-80b9-4e6f-9bf3-ad0c09a58c3e',
    //   CANCELLED: '8a994741-402e-4b18-a883-60da7fc94c7b',
    //   RETURNED: '198473ec-2e3f-42c8-a3f9-a0a3f8b4ae55',
    //   DEACTIVATED: '4f666da5-bfe0-4fb5-ad77-b92103881220'
    // };
    // eslint-disable-next-line
    const {
      classes,
      // previousExpansion,
      // expansion,
      box_position
    } = this.props;
    let elem3 = [];
    let side = box_position < 0 ? 'L' : 'R';
    if (side === 'L') {
      let prevNextCount = this.getCurrentAndPreviousPosition(side);
      let previousExpansionCount =
        prevNextCount && prevNextCount.previousCount
          ? prevNextCount.previousCount
          : 0;
      elem3 = this.getColumns(
        box_position,
        prevNextCount && prevNextCount.currentCount,
        previousExpansionCount,
        side
      );
    } else {
      let prevNextCount = this.getCurrentAndPreviousPosition(side);
      let previousExpansionCount =
        prevNextCount && prevNextCount.previousCount
          ? prevNextCount.previousCount
          : 0;
      elem3 = this.getColumns(
        box_position,
        prevNextCount && prevNextCount.currentCount,
        previousExpansionCount,
        side
      );
    }

    return (
      <div key={this.props.prefix}>
        <div className={classes.ratingContainer}>
          {nums1.map((n1) => {
            return (
              <Grid
                key={n1}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className={classes.RemoveMargin}
              >
                {nums3.map((n) => {
                  return (
                    <Grid
                      className={classes.kioskDoor}
                      onClick={() => this.openbin(elem3[n] + '' + (n1 + 1))}
                      key={elem3[n] + ' ' + (n1 + 1)}
                      item
                      xs={2}
                    >
                      <Paper
                        id={elem3[n] + '' + (n1 + 1)}
                        className={classes.gridback}
                      >
                        {elem3[n] + ' ' + (n1 + 1)}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Hd36Layout);
